import styled from 'styled-components';

const StyledEquipmentRow = styled.div`
  padding: 16px 0;
  display: inline-block;
  width: 100%;
  position: relative;

  .image {
    float: left;
    width: 64px;
  }

  .copy {
    display: block;
    margin-left: 76px;
    margin-right: 30px;
  }

  .quantity {
    font-size: 13px;
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colorGrayDarker};
  }

  .loan-info {
    font-size: 12px;
    color: ${props => props.theme.colorGrayLight};
  }

  .description {
    font-size: 15px;

    strong {
      font-family: ${props => props.theme.fonts.medium};
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;

      &:empty {
        display: none;
      }
    }
  }

  .variant {
    color: ${props => props.theme.colorGrayDark};
    font-size: 13px;
  }

  .quantity-row {
    &.mob {
      font-size: 13px;
    }

    &.sm {
      display: none;
    }
  }

  ${props => props.$isBasket &&
    `
      .remove-row {
        display: none;
        right: 1000px;
      }
    `};

  ${props => props.$isCheckout && `
    justify-content: space-between;

    .image-copy-holder {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .image {
      margin-top: unset;
    }

    .copy {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
    }

    .quantity-selector {
      margin: 12px 0 0 76px;
      max-width: 60px;
    }

    .remove-row {
      position: absolute;
      top: 13px;
      right: 0;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
        transition: 0.2s opacity ease-out;
      }

      svg {
        width: 10px;
        height: 10px;
        margin: 10px;
      }
    }
  `};

  ${props => props.theme.media.sm`
      padding: 24px 0;
      .description strong {
        display: initial;
      }

      ${props.$isCheckout &&
        `
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .image-copy-holder {
          align-items: center;
        }

        .image {
          margin-top: 0;
        }

        .copy {
          display: block;
          flex: 1;
          min-height: unset;
          margin: 24px;
        }

        .description {
          font-size: 17px;
        }

        .variant {
          font-size: 15px;
        }

        .quantity-selector {
          margin: 0 50px 0 16px;
        }

        .remove-row,
        .quantity-row {
          display: flex;
          position: static;
        }

        .quantity-row {
          &.mob {
            display: none;
          }
          &.sm {
            display: flex;
            font-size: 15px;
            color: ${props.theme.colorGrayDarker};
          }
        }
      `};

      ${props.$isBasket &&
        `
        .remove-row {
          opacity: 0;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 20px;
          right: 0;
          z-index: 2;
          cursor: pointer;

          &:hover {
            opacity; 0.8;
            transition: 0.2s opacity ease-out;
          }

          svg {
            width: 10px;
            height: 10px;
          }
        }

        &:hover {
          .remove-row {
            opacity: 1;
            transition: 0.2s opacity ease-out;
          }
        }
      `};

      ${props.$isReceipt &&
        `
        display: flex;
        justify-content: space-between;
        align-items: center;

        .image-copy-holder {
          flex: 1;
        }

        .image {
          width: 80px;
          min-width: 80px;
          margin-right: 24px;
          margin-top: 0;
        }

        .copy {
          display: block;
          flex: 1;
          min-height: unset;
          margin: unset;
        }

        .description {
          font-size: 17px;
        }

        .variant {
          font-size: 15px;
        }

        .quantity-selector {
          margin: 0 50px 0 16px;
        }

        .remove-row,
        .quantity-row {
          display: flex;
          position: initial;
        }

        .quantity-row {
          &.mob {
            display: none;
          }
          &.sm {
            min-width: 60px;
            display: flex;
            font-size: 15px;
            color: ${props.theme.colorGrayDarker};
          }
        }
      `};

    `};

  ${props => props.theme.media.md`
      ${props.$isCheckout &&
        `
        .copy {
          margin-left: 28px;
        }

        .quantity-selector {
          margin-right: 60px;
        }
      `}
    `};

  ${props =>
    props.$loading &&
    `
      .remove-row {
        opacity: 0.2;

        &:hover {
          opacity: 0.2;
          cursor: default;
        }
      }
    `};
`;

export default StyledEquipmentRow;
