import moment from 'moment';

export const required = value => {
  return value ? undefined : 'validation.noBlank';
};

export const minLength = min => value => {
  return value && value.replace(/\s/g, '').length < min
    ? 'validation.tooShort'
    : undefined;
};

export const minLength2 = minLength(2);

export const email = value => {
  return value &&
    // eslint-disable-next-line no-useless-escape
    !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value)
    ? 'validation.notValidEmail'
    : undefined;
};

export const password = value => {
  return value && value.length >= 8 ? undefined : 'validation.min8Chars';
};

export const phone = value => {
  if (!value) return undefined;

  const strippedValue = String(value).replace(' ', '');
  return strippedValue && !/^((\+|00)(\d{1,3}))?\d{8}$/i.test(strippedValue)
    ? 'validation.notValidPhone'
    : undefined;
};

export const zip = value => {
  return value && !/^\d{4}$/i.test(value)
    ? 'validation.notValidZip'
    : undefined;
};

export const requiredCheckbox = value => {
  return !value ? 'validation.requiredCheckbox' : undefined;
};

export const isDateInThePast = value => {
  return moment().isBefore(moment(value))
    ? 'validation.birthdayInThePast'
    : undefined;
};
