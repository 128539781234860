import React from 'react';
import PropTypes from 'prop-types';

import BasketIcon from '../../../../assets/icons/basket';

import StyledHeaderBasket from './HeaderBasket.styled';

const HeaderBasket = ({ toggleBasket, numItems, light, open }) => (
  <StyledHeaderBasket
    onClick={toggleBasket}
    $numItems={numItems}
    $light={light}
    $open={open}
  >
    <BasketIcon />
    <span className="counter">{numItems}</span>
  </StyledHeaderBasket>
);

HeaderBasket.propTypes = {
  toggleBasket: PropTypes.func,
  numItems: PropTypes.number,
  open: PropTypes.bool,
  light: PropTypes.bool
};

HeaderBasket.defaultProps = {
  numItems: 0
};

export default HeaderBasket;
