import gql from 'graphql-tag';

export const FETCH_MENU_LINKS = gql`
  query MenuEntriesQuery {
    entries(section: "mainNavigation") {
      title
      ... on mainNavigation_mainNavigation_Entry {
        linkUrl
      }
    }
  }
`;
