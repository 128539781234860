import styled from 'styled-components';

const StyledLoginBtn = styled.div`
  display: none;
  font-family: '${props => props.theme.fonts.medium}';
  color: ${props => !props.$transparent ? props.theme.colorGrayDarker : props.theme.colorWhite};
  font-size: 22px;
  line-height: 28px;
  padding: 0 10px;
  cursor: pointer;
  transition: 0.2s opacity ease-out;
  vertical-align: middle;
  white-space: nowrap;
  position: absolute;
  right: 28px;
  top: 7px;

  &:hover {
    opacity: 0.6;
  }

  ::selection {
    background: transparent;
  }

  svg {
    width: 10px;
    margin-left: 8px;
  }

  ul {
    display: none;
    margin: 6px 0;
    z-index: 1;
    position: absolute;
    right: 0;
    top: 22px;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.08);
    background: ${props => props.theme.colorWhite};
    color: ${props => props.theme.colorGrayDarker};
    list-style: none;
    padding: 6px 0;
    min-width: 128px;

    li {
      font-family: '${props => props.theme.fonts.regular}';
      padding-left: 12px;
      line-height: 30px;
      font-size: 16px;

      span {
        pointer-events: none;
      }

      &:hover {
        background: ${props => props.theme.colorPurple};
        color: ${props => props.theme.colorWhite};
      }
    }
  }

  &.active {
    &:hover {
      opacity: 1;
    }

    ul {
      display: block;
    }
  }

  ${props => props.theme.media.lg`
    top: 5px;
  `};

  ${props => props.theme.media.md`
    display: inline-block;
  `};
`;

export default StyledLoginBtn;
