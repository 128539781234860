import { createLogicMiddleware } from 'redux-logic';

import Reservations from '../pages/User/Archive/Reservations/Reservations.logic';
import ResetPassword from '../pages/User/ResetPassword/ResetPassword.logic';
import Verifisering from '../pages/Verifisering/Verifisering.logic';
import Categories from '../common/Categories/Categories.logic';
import Loans from '../pages/User/Archive/Loans/Loans.logic';
import Locations from '../pages/Locations/Locations.logic';
import Equipment from '../pages/Equipment/Equipment.logic';
import Basket from '../common/Basket/Basket.logic';
import Toast from '../common/Toast/Toast.logic';
import Login from '../common/Login/Login.logic';
import User from '../pages/User/User.logic';

const logics = [
  ...Locations,
  ...Equipment,
  ...Categories,
  ...User,
  ...Login,
  ...ResetPassword,
  ...Loans,
  ...Reservations,
  ...Basket,
  ...Toast,
  ...Verifisering
];

export default createLogicMiddleware(logics);
