import { connect } from 'react-redux';

import LocationSelector from './LocationSelector.component';
import { toggleModal } from './LocationSelector.actions';

const mapStateToProps = ({ locationSelector }) => ({
  ...locationSelector
});

const mapDispatchToProps = dispatch => ({
  clickClose: () => dispatch(toggleModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationSelector);
