import React from 'react';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { formatIntMobilePhone } from '../../../../../../shared/components/common/Form/Form.formatting';
import InputFieldsWrapper from '../../../../common/Form/InputFieldsWrapper/InputFieldsWrapper.styled';
import InputField from '../../../../common/Form/InputField/InputField.container';
import { dates } from '../../../../../config/register';
import {
  required,
  minLength2,
  zip,
  email,
  isDateInThePast
} from '../../../../../config/validations';

const RegisterGuestForm = ({ intl, allFieldsDisabled, currentDate }) => {
  return (
    <InputFieldsWrapper>
      <Field
        component={InputField}
        name="firstName"
        type="text"
        label={intl.formatMessage({ id: 'forms.firstName' })}
        required
        validate={[required, minLength2]}
        autoComplete="given-name"
        split
        splitLeft
        disabled={allFieldsDisabled}
        maxLength={50}
      />

      <Field
        component={InputField}
        name="lastName"
        type="text"
        label={intl.formatMessage({ id: 'forms.lastName' })}
        required
        validate={[required, minLength2]}
        autoComplete="family-name"
        split
        splitRight
        disabled={allFieldsDisabled}
        maxLength={50}
      />

      <Field
        component={InputField}
        name="birthday"
        type="date"
        label={intl.formatMessage({ id: 'forms.birthday' })}
        placeholder={'dd.mm.yyyy'}
        validate={[isDateInThePast]}
        openToDate={dates.startFrom}
        max={currentDate}
        autoComplete="birthday"
        isBelow
        withPortal
        disabled={allFieldsDisabled}
        onFocus={e => {
          e.target.blur();
        }}
      />

      <Field
        component={InputField}
        name="email"
        type="email"
        label={intl.formatMessage({ id: 'forms.email' })}
        required
        validate={[required, email]}
        autoComplete="email"
        isBelow
        disabled={allFieldsDisabled}
      />

      <Field
        component={InputField}
        name="address"
        type="text"
        label={intl.formatMessage({ id: 'forms.address' })}
        validate={[minLength2]}
        autoComplete="street-address"
        isBelow
        disabled={allFieldsDisabled}
      />

      <Field
        component={InputField}
        name="zipcode"
        type="number"
        label={intl.formatMessage({ id: 'forms.zipCode' })}
        validate={[zip]}
        autoComplete="postal-code"
        split
        splitOneThird
        splitLeft
        isBelow
        disabled={allFieldsDisabled}
        removeNativeUi
      />

      <Field
        component={InputField}
        name="city"
        type="text"
        label={intl.formatMessage({ id: 'forms.city' })}
        autoComplete="administrative-area"
        split
        splitRight
        splitTwoThirds
        isBelow
        disabled={allFieldsDisabled}
      />

      <Field
        component={InputField}
        name="mobilePhone"
        type="tel"
        label={intl.formatMessage({ id: 'forms.mobilePhone' })}
        autoComplete="tel-national"
        format={formatIntMobilePhone}
        parse={value => value && value.replace(/\s/g, '').slice(0, 15)}
        isBelow
        disabled={allFieldsDisabled}
      />

      <Field
        component={InputField}
        name="organization"
        type="text"
        label={intl.formatMessage({ id: 'forms.organization' })}
        autoComplete="organization"
        isBelow
        disabled={allFieldsDisabled}
      />

      <Field
        component={InputField}
        type="textarea"
        name="userNote"
        label={intl.formatMessage({ id: 'forms.note' })}
        autoComplete="userNote"
        isBelow
        disabled={allFieldsDisabled}
      />
    </InputFieldsWrapper>
  );
};

RegisterGuestForm.defaultProps = {
  formValues: {}
};

RegisterGuestForm.propTypes = {
  intl: PropTypes.object,
  formValues: PropTypes.object,
  allFieldsDisabled: PropTypes.bool,
  currentDate: PropTypes.string
};

export default injectIntl(RegisterGuestForm);
