import styled from 'styled-components';

const StyledSidebar = styled.div`
  font-size: 14px;
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  display: ${props => (props.$loading || !props.id ? 'flex' : 'block')};
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  transition: 0.3s all ease-out;
  background-color: ${props => props.theme.colorWhite};
  width: 100%;
  height: 100%;

  .unset-marker-location {
    display: inline-block;
    position: absolute;
    z-index: 2;
    width: 20px;
    height: 20px;
    top: 0;
    right: 0;
    margin: 20px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      float: left;
      pointer-events: none;
      filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.6));
    }
  }

  &.content-loading > * {
    opacity: 0.6;
  }

  &.content-loaded > *{
    opacity: 1;
  }

  .loading-text {
    padding: 2px 10px;
    border-radius: 2px;
    opacity: 0.8;
  }

  .missing-info {
    font-size: 14px;
    opacity: 0.4;
  }

  h3 {
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.6px;
    margin-bottom: 16px;
    font-family: '${props => props.theme.fonts.medium}';
  }

  p {
    line-height: 18px;
    width: 100%;
    margin-bottom: 16px;
  }

  ${props => props.theme.media.sm`
    width: ${props.theme.locationSidebarWidth}px;

    .unset-marker-location {
      display: none;
    }
  `};

`;

export default StyledSidebar;
