export const VIPPS_GET_DATA = '@@vipps/VIPPS_GET_DATA';
export const VIPPS_GET_DATA_SUCCESS = '@@vipps/VIPPS_GET_DATA_SUCCESS';
export const VIPPS_GET_DATA_ERROR = '@@vipps/VIPPS_GET_DATA_ERROR';
export const CANCEL_VIPPS_GET_DATA = '@@vipps/CANCEL_VIPPS_GET_DATA';

export const VIPPS_VERIFY = '@@vipps/VIPPS_VERIFY';
export const VIPPS_VERIFY_SUCCESS = '@@vipps/VIPPS_VERIFY_SUCCESS';
export const VIPPS_VERIFY_ERROR = '@@vipps/VIPPS_VERIFY_ERROR';
export const CANCEL_VIPPS_VERIFY = '@@vipps/CANCEL_VIPPS_VERIFY';

export const VIPPS_CALLBACK = '@@vipps/VIPPS_CALLBACK';
export const CANCEL_VIPPS_CALLBACK = '@@vipps/CANCEL_VIPPS_CALLBACK';
export const VIPPS_CALLBACK_SUCCESS = '@@vipps/VIPPS_CALLBACK_SUCCESS';
export const VIPPS_CALLBACK_ERROR = '@@vipps/VIPPS_CALLBACK_ERROR';

export const VIPPS_SEND_CODE = '@@vipps/VIPPS_SEND_CODE';
export const VIPPS_SEND_CODE_LOADING = '@@vipps/VIPPS_SEND_CODE_LOADING';
export const CANCEL_VIPPS_SEND_CODE = '@@vipps/CANCEL_VIPPS_SEND_CODE';
export const VIPPS_SEND_CODE_SUCCESS = '@@vipps/VIPPS_SEND_CODE_SUCCESS';
export const VIPPS_SEND_CODE_ERROR = '@@vipps/VIPPS_SEND_CODE_ERROR';

export const VIPPS_VERIFY_CODE = '@@vipps/VIPPS_VERIFY_CODE';
export const CANCEL_VIPPS_VERIFY_CODE = '@@vipps/CANCEL_VIPPS_VERIFY_CODE';
export const VIPPS_VERIFY_CODE_SUCCESS = '@@vipps/VIPPS_VERIFY_CODE_SUCCESS';
export const VIPPS_VERIFY_CODE_ERROR = '@@vipps/VIPPS_VERIFY_CODE_ERROR';

export const vippsGetData = payload => ({
  type: VIPPS_GET_DATA,
  payload
});

export const vippsGetDataSuccess = payload => ({
  type: VIPPS_GET_DATA_SUCCESS,
  payload
});

export const vippsGetDataError = err => ({
  type: VIPPS_GET_DATA_ERROR,
  payload: err
});

export const vippsVerify = payload => ({
  type: VIPPS_VERIFY,
  payload
});

export const vippsVerifySuccess = payload => ({
  type: VIPPS_VERIFY_SUCCESS,
  payload
});

export const vippsVerifyError = err => ({
  type: VIPPS_VERIFY_ERROR,
  payload: err
});

export const vippsCallback = payload => ({
  type: VIPPS_CALLBACK,
  payload
});

export const vippsCallbackSuccess = payload => ({
  type: VIPPS_CALLBACK_SUCCESS,
  payload
});

export const vippsCallbackError = payload => ({
  type: VIPPS_CALLBACK_ERROR,
  payload
});

export const vippsVerifySendCode = payload => ({
  type: VIPPS_SEND_CODE,
  payload
});

export const vippsVerifySendCodeLoading = payload => ({
  type: VIPPS_SEND_CODE_LOADING,
  payload
});

export const vippsVerifySendCodeSuccess = payload => ({
  type: VIPPS_SEND_CODE_SUCCESS,
  payload
});

export const vippsVerifySendCodeError = payload => ({
  type: VIPPS_SEND_CODE_ERROR,
  payload
});

export const vippsVerifyCode = payload => ({
  type: VIPPS_VERIFY_CODE,
  payload
});

export const vippsVerifyCodeSuccess = payload => ({
  type: VIPPS_VERIFY_CODE_SUCCESS,
  payload
});

export const vippsVerifyCodeError = payload => ({
  type: VIPPS_VERIFY_CODE_ERROR,
  payload
});
