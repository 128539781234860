export const url =
  typeof window !== 'undefined' && window.API_PATH && window.API_PATH !== ''
    ? window.API_PATH
    : '/api/bua/p/v1';

export const login = {
  path: '/token/auth',
  check: '/users',
  logout: '/users/logout',
  refresh: '/token/refresh'
};

export const equipment = {
  path: '/equipment',
  searchPath: '/equipment-search',
  slugPath: '/slug',
  variantPath: '/variants'
};

export const categories = {
  path: '/categories'
};

export const equipmentAttributes = {
  path: '/equipment-attributes'
};

export const variantAttributes = {
  path: '/variant-attributes'
};

export const basket = {
  path: '/baskets',
  itemsPath: '/items',
  convertPath: '/convert'
};

export const locations = {
  path: '/locations',
  slugPath: '/slug'
};

export const settings = {
  path: '/settings'
};

export const user = {
  path: '/users',
  resetPasswordPath: '/resetpassword',
  userLinksPath: '/links'
};

export const subscribe = {
  subscribe: {
    path: '/newsletter/subscribe'
  },
  unsubscribe: {
    path: '/newsletter/unsubscribe'
  }
};

export const loans = {
  path: '/loans',
  itemsPath: '/items',
  pickupPath: '/pickup',
  returnPath: '/return'
};

export const reservations = {
  path: '/reservations',
  itemsPath: '/items',
  convertPath: '/convert'
};

export const images = {
  path: '/images'
};

export const blog = {
  path: '/content/blog',
  slugPath: '/slug'
};

export const article = {
  path: '/content/articles',
  slugPath: '/slug'
};

export const guides = {
  path: '/content/guides',
  slugPath: '/slug'
};
