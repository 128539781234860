import styled from 'styled-components';

export default styled.div`
  padding: 16px 24px;
  display: inline-block;
  border-top: 1px solid ${props => props.theme.colorGrayLight};
  width: 100%;

  p:last-child {
    margin-bottom: 0;
  }
`;
