import { styled } from 'styled-components';

const StyledUserSelectOption = styled.div`

  .user-option-row {
    display: flex;
    line-height: 18px;
    font-size: 14px;
  }

  .user-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  .user-organization {
    color: ${props => props.theme.colorGrayDark };
  }

  .user-icon {
    width: 30px;
    min-width: 30px;
    margin-right: 8px;
  }

  .user-icon-organization svg {
    width: 24px;
    height: 24px;
  }
`;

export default StyledUserSelectOption;