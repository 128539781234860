import React from 'react';
import scrollTo from 'scroll-to';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Alert from '../Form/Notifications/Alert.styled';

class SubmitErrors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.errors) {
      this.scanErrors(this.props.errors);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.errors && nextProps.errors) {
      scrollTo(0, 0, {
        ease: 'out-cube',
        duration: 500
      });
    }

    this.scanErrors(nextProps.errors);
  }

  scanErrors(errors) {
    this.setState({ errors: null }, () => {
      if (!errors) {
        return;
      }

      const { intl } = this.props;
      const foundErrors = [];

      // handle multiple errors
      if (errors.errorData) {
        const keys = Object.keys(errors.errorData);

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const value = errors.errorData[key];

          if (key === 'userLinks') {
            for (const userLinkKey in value[0]) {
              foundErrors.push(intl.$t({ 
                id: `error.${userLinkKey}`, 
                defaultMessage: value[0][userLinkKey][0]
              }));
            }

          } else {
            foundErrors.push(intl.$t({
              id: `error.${key}`,
              defaultMessage: value[0]
            }));
          }
        }

      // handle status errors
      } else if (errors.status) {
        foundErrors.push(intl.$t({
          id: `error.${errors.status}`,
          defaultMessage: errors.response.statusText
        }));

      // handle code errors
      } else if (errors.code) {
        foundErrors.push(intl.$t({
          id: `error.${errors.code}`,
          defaultMessage: errors.message
        }));
      }

      this.setState({
        errors: foundErrors
      });
    });
  }

  render() {
    if (!this.state.errors) {
      return null;
    }

    return (
      <Alert>
        { this.state.errors.map((message, index) => (
          <li key={`key-${index}-${message}`}>{message}</li>
        )) }
      </Alert>
    );
  }
}

SubmitErrors.propTypes = {
  errors: PropTypes.object,
  intl: PropTypes.object
};

export default injectIntl(SubmitErrors);
