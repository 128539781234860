import styled, { css } from 'styled-components';

const offset = css`
  width: ${props => 51 * props.$scale}px;
  height: ${props => 52 * props.$scale}px;
  left: ${props => (51 / 2) * props.$scale * -1}px;
  bottom: ${props => (52 / 2) * props.$scale * -1}px;
`;

const StyledClusterMarker = styled.div`
  position: absolute;
  cursor: pointer;
  transition: all .2s ease-in-out;
  transform: scale(1);
  opacity: 0.8;

  & > * {
    position: absolute;
    ${offset}
  }

  .cluster-count {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.colorWhite};
    font-family: '${props => props.theme.fonts.medium}', sans-serif;
    font-size: 1.4em;
  }

  &:hover,
  &:focus {
    opacity: 1;
    transform: scale(1.2);
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export default StyledClusterMarker;
