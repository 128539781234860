import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';
import withBounds from '../../../shared/hoc/withBounds';

const MarkerCluster = ({ fill }) => {
  const _fill = fill ? fill : 'none';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 52 51`}
      preserveAspectRatio="xMidYMid meet"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={_fill}
          fillOpacity="1"
          d="M25.9,13.4c6.6,0,12,5.4,12,12c0,6.6-5.4,12-12,12s-12-5.4-12-12C13.9,18.8,19.3,13.4,25.9,13.4"
        />
        <path
          fill={_fill}
          fillOpacity="0.6"
          d="M25.9,8.9c-4.4,0-8.6,1.7-11.9,4.7l2.1,2.1c2.7-2.4,6.1-3.8,9.7-3.8c3.6,0,7.1,1.3,9.8,3.8l2.1-2.1
          C34.5,10.6,30.3,8.9,25.9,8.9"
        />
        <path
          fill={_fill}
          fillOpacity="0.4"
          d="M25.9,4.4c-5.6,0-10.9,2.2-15,6.1l2.1,2.1c3.5-3.4,8-5.2,12.9-5.2c4.8,0,9.4,1.9,12.9,5.2l2.1-2.1
          C36.8,6.5,31.5,4.4,25.9,4.4"
        />
        <path
          fill={_fill}
          fillOpacity="0.2"
          d="M25.9,0C19.1,0,12.6,2.6,7.7,7.2l2.1,2.1C14.2,5.3,19.9,3,25.9,3c6,0,11.7,2.3,16.1,6.4l2.1-2.1
          C39.1,2.6,32.7,0,25.9,0"
        />
        <path
          fill={_fill}
          fillOpacity="0.6"
          d="M11.2,34c2.2,3.9,5.6,6.7,9.8,8.1l0.8-2.9c-3.4-1.1-6.3-3.5-8-6.7c-1.8-3.2-2.3-6.8-1.5-10.4l-2.9-0.8
          C8.4,25.6,9,30.1,11.2,34"
        />
        <path
          fill={_fill}
          fillOpacity="0.4"
          d="M7.2,36.2c2.8,4.9,7.2,8.5,12.6,10.1l0.8-2.9C16,42,12.1,39,9.8,34.7s-3-9.1-1.7-13.8l-2.9-0.8
          C3.7,25.6,4.4,31.3,7.2,36.2"
        />
        <path
          fill={_fill}
          fillOpacity="0.2"
          d="M3.4,38.3c3.3,6,8.7,10.3,15.2,12.4l0.8-2.9C13.7,46,8.9,42.1,6,36.9C3,31.6,2.3,25.5,3.7,19.7l-2.9-0.8
          C-0.8,25.5,0,32.4,3.4,38.3"
        />
        <path
          fill={_fill}
          fillOpacity="0.6"
          d="M40.6,34c-2.2,3.9-5.7,6.7-9.8,8.1L30,39.2c3.4-1.1,6.3-3.5,8-6.7c1.8-3.2,2.3-6.8,1.5-10.4l2.9-0.8
          C43.4,25.6,42.8,30.1,40.6,34"
        />
        <path
          fill={_fill}
          fillOpacity="0.4"
          d="M44.6,36.2c-2.8,4.9-7.2,8.5-12.6,10.1l-0.8-2.9C35.8,42,39.6,39,42,34.7s3-9.1,1.7-13.8l2.9-0.8
          C48.1,25.6,47.4,31.3,44.6,36.2"
        />
        <path
          fill={_fill}
          fillOpacity="0.2"
          d="M48.4,38.3c-3.3,6-8.7,10.3-15.2,12.4l-0.8-2.9c5.7-1.8,10.4-5.7,13.4-10.9c2.9-5.3,3.7-11.3,2.3-17.1l2.9-0.8
          C52.6,25.5,51.7,32.4,48.4,38.3"
        />
      </g>
    </svg>
  );
};

MarkerCluster.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withBounds(withSvg(MarkerCluster));
