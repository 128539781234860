import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import Checkbox from '../../../../common/Form/CheckboxInput/CheckboxInput.component';
import { requiredCheckbox } from '../../../../../config/validations';

import StyledApprovals from './Approvals.styled';

class Approvals extends React.Component {
  termsLabel() {
    const { intl } = this.props;
    return (
      <FormattedMessage
        id="forms.termsCheckbox"
        values={{
          termsLink: (
            <Link
              className="black-link"
              to={intl.formatMessage({ id: 'forms.termsLink' })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="forms.termsLinkText" />
            </Link>
          ),
          privacyLink: (
            <Link
              className="black-link"
              to={intl.formatMessage({ id: 'forms.privacyLink' })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="forms.privacyLinkText" />
            </Link>
          )
        }}
      />
    );
  }

  render() {
    const {
      restrictNotifications,
      intl,
      allFieldsDisabled,
      onlyTos
    } = this.props;

    return (
      <StyledApprovals>
        <FormattedMessage
          tagName="h3"
          id={`${
            restrictNotifications
              ? 'forms.notificationsTitle'
              : 'forms.notificationsTermsTitle'
          }`}
        />

        {!onlyTos && (
          <Field
            component={Checkbox}
            type="checkbox"
            id="notificationEmail"
            name="allowNotificationEmail"
            normalize={val => !!val}
            label={intl.formatMessage({
              id: 'forms.allowNotificationEmailCheckbox'
            })}
            disabled={allFieldsDisabled}
          />
        )}

        {!onlyTos && (
          <Field
            component={Checkbox}
            type="checkbox"
            id="notificationMobile"
            name="allowNotificationMobile"
            normalize={val => !!val}
            label={intl.formatMessage({
              id: 'forms.allowNotificationMobileCheckbox'
            })}
            disabled={allFieldsDisabled}
          />
        )}

        {!restrictNotifications && (
          <Field
            component={Checkbox}
            type="checkbox"
            id="terms"
            name="tosVerified"
            normalize={val => !!val}
            label={this.termsLabel()}
            validate={[requiredCheckbox]}
            disabled={allFieldsDisabled}
          />
        )}
      </StyledApprovals>
    );
  }
}

Approvals.defaultProps = {};

Approvals.propTypes = {
  intl: PropTypes.object,
  allFieldsDisabled: PropTypes.bool,
  restrictNotifications: PropTypes.bool,
  onlyTos: PropTypes.bool
};

export default injectIntl(Approvals);
