import React, { createRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import HeaderBtn from '../../Header/HeaderBtn/HeaderBtn.component';
import { Container } from '../../../../../shared/components/Grid';
import CloseBig from '../../../../assets/icons/close-big';

import HeaderMenuLinkMobile from './HeaderMenuLinkMobile/HeaderMenuLinkMobile.component';
import HeaderBottomLink from './HeaderBottomLink/HeaderBottomLink.styled';
import StyledHeaderMenuMobile from './HeaderMenuMobile.styled';

class HeaderMenuMobile extends React.Component {
  
  static propTypes = {
    intl: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    open: PropTypes.bool,
    toggleMenu: PropTypes.func,
    clickLogout: PropTypes.func,
    links: PropTypes.array.isRequired,
    social: PropTypes.array.isRequired,
    loggedIn: PropTypes.bool,
    extraLinks: PropTypes.array,
    showCloseButton: PropTypes.bool
  };

  open = false;

  wrapperRef = createRef();

  lastActiveElement = null;

  closeByEscape = event => {
    if (event.key === 'Escape') {
      this.props.toggleMenu();
    }
  };

  onOpen() {
    document.addEventListener('keydown', this.closeByEscape);
    this.lastActiveElement = document.activeElement;
    this.wrapperRef.current.focus();
  }

  onClose() {
    document.removeEventListener('keydown', this.closeByEscape);

    if (this.lastActiveElement) {
      this.lastActiveElement.focus();
    }
  }

  render() {
    const {
      theme,
      intl,
      open,
      toggleMenu,
      links,
      social,
      loggedIn,
      clickLogout,
      showCloseButton
    } = this.props;

    if (open !== this.open) {
      open ? this.onOpen() : this.onClose();
      this.open = open;
    }

    return (
      <div ref={this.wrapperRef} tabIndex="0">
        <StyledHeaderMenuMobile $open={open} $forseMobile={showCloseButton}>
          <div className="top">
            <Container $full className="mobileHeader__container">
              <HeaderBtn
                onClick={toggleMenu}
                className="header-menu__close"
                visible={showCloseButton}
              >
                <CloseBig fill={theme.colorPurple} />
              </HeaderBtn>
            </Container>
          </div>

          <div className="content">
            <div className="links">
              <ul>
                {links.map(link => (
                  <li key={link.key}>
                    <HeaderMenuLinkMobile {...link}>
                      {link.name}
                    </HeaderMenuLinkMobile>
                  </li>
                ))}

                {loggedIn && (
                  <li>
                    <HeaderMenuLinkMobile className="logout-link" to="#" onClick={clickLogout}>
                      {intl.formatMessage({ id: 'forms.logoutCta' })}
                    </HeaderMenuLinkMobile>
                  </li>
                )}
              </ul>
            </div>

            <div className="bottom">
              <Container $full>
                <ul>
                  {social.map(link => {
                    const url = intl.formatMessage({ id: `general.social.${link}.url` });

                    return (
                      <li key={link}>
                        <HeaderBottomLink
                          eventLabel={url}
                          to={url}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <FormattedMessage id={`general.social.${link}.name`} />
                        </HeaderBottomLink>
                      </li>
                    );
                  })}
                </ul>
              </Container>
            </div>
          </div>

          <div
            tabIndex="0"
            onFocus={() => this.wrapperRef.current.focus()}
          />
        </StyledHeaderMenuMobile>
      </div>
    );
  }
}

export default injectIntl(withTheme(HeaderMenuMobile));
