import { connect } from 'react-redux';

import { getVariantAvailability } from '../../../../../../shared/components/Equipment/Equipment.selectors';

import EquipmentRowComponent from './EquipmentRow.component';

const mapStateToProps = (
  { basket: { selected, loading } },
  { item: { variant } }
) => ({
  loading,
  availability:
    variant && variant.attributes && selected.pickupAt && selected.returnAt
      ? getVariantAvailability(
          variant.attributes,
          selected.pickupAt,
          selected.returnAt
        ) - variant.attributes.instanceBuffer
      : null
});

export default connect(mapStateToProps)(EquipmentRowComponent);
