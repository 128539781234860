import React, { createRef } from 'react';
import { withTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Wrapper from 'react-click-outside';
import PropTypes from 'prop-types';

import EquipmentList from '../../pages/Checkout/EquipmentList/EquipmentList.container';
import CloseBig from '../../../assets/icons/close-big';
import StyledButton from '../Button/Button.styled';

import StyledBasket from './Basket.styled';

class Basket extends React.Component {
  open = false;

  contentRef = createRef();

  lastActiveElement = null;

  closeByEscape = event => {
    if (event.key === 'Escape') {
      this.props.closeBasket();
    }
  };

  onOpen() {
    document.addEventListener('keydown', this.closeByEscape);
    this.lastActiveElement = document.activeElement;
    setTimeout(() => this.contentRef.current.focus(), 1);
  }

  onClose() {
    document.removeEventListener('keydown', this.closeByEscape);

    if (this.lastActiveElement) {
      this.lastActiveElement.focus();
    }
  }

  UNSAFE_componentWillMount() {
    this.props.checkBasketInfo();
  }

  render() {
    const {
      theme,
      basket,
      closeBasket,
      clickCheckout,
      visibleHeader
    } = this.props;

    if (basket.open !== this.open) {
      basket.open ? this.onOpen() : this.onClose();
      this.open = basket.open;
    }

    return !basket.open ? null : (
      <Wrapper onClickOutside={closeBasket}>
        <StyledBasket $top={visibleHeader}>
          <div tabIndex={0} className="content" ref={this.contentRef}>
            <div className="top">
              <FormattedMessage tagName="h4" id="basket.title" />

              <button className="close" onClick={closeBasket}>
                <CloseBig fill={theme.colorWhite} />
              </button>
            </div>

            {!basket.items.length ? (
              <div className="basket-empty-msg">
                <FormattedMessage tagName="small" id="basket.noItems" />
              </div>
              
            ) : (
              <EquipmentList isBasket />
            )}

            <div className="button">
              <StyledButton
                $green
                $fullWidth
                onClick={clickCheckout}
                disabled={!basket.selected.id || !basket.items.length}
              >
                <FormattedMessage id="basket.goToCheckout" />
              </StyledButton>

              <a href="#" onClick={closeBasket} className="continue">
                <FormattedMessage id="basket.keepBrowsing" />
              </a>
            </div>

            <div
              tabIndex={0}
              onFocus={() => this.contentRef.current.focus()}
            />
          </div>
        </StyledBasket>
      </Wrapper>
    );
  }
}

Basket.propTypes = {
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  basket: PropTypes.object.isRequired,
  closeBasket: PropTypes.func.isRequired,
  clickCheckout: PropTypes.func.isRequired,
  checkBasketInfo: PropTypes.func.isRequired,
  visibleHeader: PropTypes.bool
};

export default withTheme(Basket);
