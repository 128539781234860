import styled, { css } from 'styled-components';

const dimension = 18;
const labelBuffer = 12;

export const checkboxBase = css`
  position: relative;
  display: ${props => (props.$block ? 'block' : 'inline-block')};
  margin-top: 6px;
  margin-right: 16px;

  input {
    float: left;
    margin: 0;
    opacity: 0;
    z-index: 1;
    height: ${dimension}px;
    width: ${dimension}px;
    cursor: pointer;
  }

  input + label {
    display: block;
    padding-left: ${dimension + labelBuffer}px;
    font-size: 15px;
    position: relative;

    &::before,
    &::after {
      content: '';
      margin-top: 3px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: ${dimension}px;
      height: ${dimension}px;
    }

    &::before {
      display: block;
      border-radius: ${props => (props.$type === 'radio' ? '50%' : '2px')};
      border: ${props => props.theme.colorGray} solid 1px;
      background-color: ${props => props.theme.colorWhite};
    }

    &::after {
      display: none;
      margin: 0;
      padding: 1px 4px;
    }
  }

  input:checked + label {
    &::before {
      border: 0;
      background-color: ${props => props.theme.colorPurple};
      transition: 0.2s all ease-out;
    }

    &::after {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-top: 8px;
      margin-left: 5px;
      background: ${props => props.theme.colorWhite};
      border-radius: ${props => (props.$type === 'radio' ? '50%' : '2px')};
    }
  }

  input:disabled + label {
    &::before {
      background: ${props => props.theme.colorGrayLighter};
    }
  }

  .error {
    color: ${props => props.theme.colorRed};
    font-weight: 400;
    font-size: 12px;
    pointer-events: none;
  }
  
  input:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
`;

const StyledRadioInput = styled.div`
  ${checkboxBase};
`;

export default StyledRadioInput;
