import styled from 'styled-components';

const StyledRegisterGuest = styled.div`
  text-align: center;

  hr {
    float: left;
    width: 100%;
    margin: 40px 0;
    border: none;
    border-top: 1px solid ${props => props.theme.colorGrayLight};
  }

  h1 {
    text-align: center;
    margin-bottom: 16px;
  }

  .intro {
    margin-bottom: 40px;

    p {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  small {
    font-size: 13px;
    line-height: 1.38;
    margin-top: 12px;
    display: inline-block;

    a {
      color: ${props => props.theme.colorPurple};
    }
  }

  .login-link {
    color: ${props => props.theme.colorPurple};
    cursor: pointer;
  }

  form {
    width: 100%;
    display: inline-block;
  }

  .registrationType {
    margin-bottom: 24px;
  }

  .vipps-button {
    display: inline-flex;
    align-items: center;
    font-size: 18px;

    &.disable {
      cursor: default;
    }

    svg {
      height: 19px;
      margin-left: 8px;
      margin-top: 2px;
    }
  }

  .send-me-code {
    &__wrapper {
      position: relative;
    }

    &__button {
      position: absolute;
      z-index: 10;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export default StyledRegisterGuest;
