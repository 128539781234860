import { createLogicWithApi } from '../../../../shared/logicCreators';
import {
  equipment as equipmentApiConfig,
  locations as locationsApiConfig
} from '../../../config/api';
import {
  getEquipmentAvailability,
  getEquipmentNextAvailable,
  getVariantAvailability,
  getVariantNextAvailable
} from '../../../../shared/components/Equipment/Equipment.selectors';
import {
  FETCH_EQUIPMENT,
  CANCEL_FETCH_EQUIPMENT,
  fetchEquipmentSuccess,
  fetchEquipmentError,
  FETCH_EQUIPMENT_BY_SLUG,
  CANCEL_FETCH_BY_ID_EQUIPMENT,
  fetchEquipmentByIdSuccess,
  fetchEquipmentByIdError,
  FETCH_EQUIPMENT_VARIANTS,
  CANCEL_FETCH_EQUIPMENT_VARIANTS,
  fetchEquipmentVariantsSuccess,
  fetchEquipmentVariantsError
} from '../../../../shared/components/Equipment/Equipment.actions';

const fetchEquipmentLogic = createLogicWithApi({
  type: FETCH_EQUIPMENT,
  cancelType: CANCEL_FETCH_EQUIPMENT,
  latest: true,
  process: ({ Api, getState, action }, dispatch, done) => {
    const state = getState();
    const location = action.location
      ? action.location
      : state.locations.selected.id;

    return Api({
      method: 'get',
      path: `${locationsApiConfig.path}/${location}${equipmentApiConfig.searchPath}`,
      requireLogin: false,
      cache: true,
      handle: r => {
        if (typeof action.sort !== 'undefined') {
          r.query({ 'order[field]': action.sort });
        }

        if (typeof action.filters !== 'undefined') {
          r.query({ filters: action.filters });
        }

        if (typeof action.limit !== 'undefined') {
          r.query({ limit: action.limit });
        }

        if (typeof action.offset !== 'undefined') {
          r.query({ offset: action.offset });
        }

        if (typeof action.search !== 'undefined') {
          r.query({ query: action.search });
        }

        if (typeof action.queryFields !== 'undefined') {
          r.query({ queryFields: action.queryFields });
        }

        if (typeof action.desc !== 'undefined') {
          r.query({ 'order[dir]': action.desc ? 'desc' : 'asc' });
        }

        return r;
      }
    })
      .then(resp => {
        getEquipmentAvailability.clearCache();
        getEquipmentNextAvailable.clearCache();
        
        dispatch(fetchEquipmentSuccess(resp));

        done();
      })
      .catch(err => {
        dispatch(fetchEquipmentError(err));

        done();
      });
  }
});

const fetchEquipmentBySlugLogic = createLogicWithApi({
  type: FETCH_EQUIPMENT_BY_SLUG,
  cancelType: CANCEL_FETCH_BY_ID_EQUIPMENT,
  latest: true,
  process: ({ action, getState, Api }, dispatch, done) => {
    const state = getState();
    const location = action.location
      ? action.location
      : state.locations.selected.id;

    return Api({
      method: 'get',
      requireLogin: false,
      cache: true,
      path: `${locationsApiConfig.path}/${location}${equipmentApiConfig.path}${equipmentApiConfig.slugPath}${action.slug}`
    })
      .then(resp => {
        dispatch(fetchEquipmentByIdSuccess(resp));

        done();
      })
      .catch(err => {
        dispatch(fetchEquipmentByIdError(err));
        
        done();
      });
  }
});

const fetchEquipmentVariantsLogic = createLogicWithApi({
  type: FETCH_EQUIPMENT_VARIANTS,
  cancelType: CANCEL_FETCH_EQUIPMENT_VARIANTS,
  latest: true,
  process: ({ Api, getState, action }, dispatch, done) => {
    const state = getState();
    const equipmentId = action.id ? action.id : state.equipment.selected.id || null;

    return Api({
      method: 'GET',
      requireLogin: false,
      path: `${equipmentApiConfig.path}/${equipmentId}${equipmentApiConfig.variantPath}`
    })
      .then(resp => {
        getVariantAvailability.clearCache();
        getVariantNextAvailable.clearCache();

        dispatch(fetchEquipmentVariantsSuccess(resp));

        done();
      })
      .catch(err => {
        dispatch(fetchEquipmentVariantsError(err));

        done();
      });
  }
});

const logics = [
  fetchEquipmentLogic,
  fetchEquipmentBySlugLogic,
  fetchEquipmentVariantsLogic
];

export default logics;