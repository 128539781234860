import { categories as categoryApiConfig } from '../../../config/api';
import { createLogicWithApi } from '../../../../shared/logicCreators';
import {
  FETCH_CATEGORIES,
  CANCEL_FETCH_CATEGORIES,
  fetchCategoriesSuccess,
  fetchCategoriesError
} from '../../../../shared/components/Categories/Categories.actions';

const fetchCategoriesLogic = createLogicWithApi({
  type: FETCH_CATEGORIES,
  cancelType: CANCEL_FETCH_CATEGORIES,
  latest: true,
  process: ({ Api, action }, dispatch, done) => {
    return Api({
      method: 'get',
      requireLogin: false,
      cache: true,
      path: categoryApiConfig.path,
      params: {
        ...action,
        limit: 0
      }
    })
      .then(resp => {
        dispatch(fetchCategoriesSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchCategoriesError(err));
        done();
      });
  }
});

const logics = [fetchCategoriesLogic];

export default logics;