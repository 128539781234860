import styled from 'styled-components';

const StyledView = styled.div`
  width: 100%;
  margin: auto;
  padding: ${props => props.theme.headerMobileHeight}px 0;

  ${props => props.theme.media.sm`
    padding: ${props =>
      props.$paddingTop !== undefined
        ? props.$paddingTop
        : props.theme.headerHeight + 40
    }px 0 ${props =>
      props.$paddingBottom !== undefined
        ? props.$paddingBottom
        : props.theme.headerHeight
    }px;
  `};
`;

export default StyledView;
