import React from 'react';
import ElementPropTypes from 'react-element-proptypes';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Col from './Col.styled';

const StyledRow = styled.div`
  ${props =>
    props.theme.gutter
      ? css`
          margin: 0 -${props.theme.gutter}px;
        `
      : css`
          margin: 0 -8px;

          ${props.theme.media.sm`
      margin: 0 -12px;
    `};

          ${props.theme.media.md`
      margin: 0 -16px;
    `};
        `}

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  & > * {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const Row = ({ children, ...props }) => (
  <StyledRow {...props}>
    <div>{children}</div>
  </StyledRow>
);

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(ElementPropTypes.elementOfType(Col)),
    ElementPropTypes.elementOfType(Col)
  ])
};

export default Row;
