import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';

import DetailBlockLine from '../../common/DetailSection/DetailBlock/DetailBlockLine/DetailBlockLine.component';
import Translator from '../../Translator/Translator.container';

const LocationsOpeningHours = ({ item, showClosedDays, intl }) => (
  <div>
    <h3>
      <FormattedMessage id="locations.details.openingHours" />
    </h3>

    {item.openingHours &&
    !item.openingHours.filter(day => day.attributes.isActive).length &&
    !item.specialOpeningHours.filter(day => day.attributes.isActive).length ? (
      <p className="missing-info">
        <FormattedMessage id="locations.details.missingOpeningHours" />
      </p>
    ) : null}

    {item.openingHours &&
      item.openingHours
        .filter(day => day.attributes.isActive || showClosedDays)
        .map(day => (
          <DetailBlockLine
            key={day.id}
            title={
              <Translator path="locations.openingHours.days">
                {day.attributes.dayOfWeek.toString()}
              </Translator>
            }
          >
            {day.attributes.isActive ? (
              `${day.attributes.openingTime} - ${day.attributes.closingTime}`
            ) : (
              <FormattedMessage id={'general.closed'} />
            )}
          </DetailBlockLine>
        ))}

    {!item.specialOpeningHours || !item.specialOpeningHours.length ? null : (
      <DetailBlockLine
        title={intl.formatMessage({ id: 'locations.details.deviation' })}
      >
        <ul className="basic-list">
          {item.specialOpeningHours.map(day => (
            <li key={day.id}>
              {`${day.attributes.openingTime} - ${
                day.attributes.closingTime
              } - ${moment(day.attributes.date).format('D. MMM')}`}
            </li>
          ))}
        </ul>
      </DetailBlockLine>
    )}

    {!item.closedDays || !item.closedDays.length ? null : (
      <DetailBlockLine
        title={intl.formatMessage({ id: 'locations.details.closed' })}
      >
        <ul className="comma-list">
          {item.closedDays.map(day => (
            <li key={day.id}>{moment(day.attributes.date).format('D. MMM')}</li>
          ))}
        </ul>
      </DetailBlockLine>
    )}

    {!item.openingHoursInfo ? null : (
      <div dangerouslySetInnerHTML={{ __html: item.openingHoursInfo }} />
    )}
  </div>
);

LocationsOpeningHours.propTypes = {
  item: PropTypes.object.isRequired,
  showClosedDays: PropTypes.bool,
  intl: PropTypes.object
};

export default injectIntl(LocationsOpeningHours);
