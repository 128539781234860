import React from 'react';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { locationTypesColorHelper } from '../../../../config/locations';
import BuaIcon from '../../../../assets/icons/a-large';

import StyledLocationMarker from './LocationMarker.styled';
import Tooltip from './Tooltip/Tooltip.component';

const LocationMarkerComponent = ({
  type,
  scale,
  name,
  reservationEnabled,
  isSelected,
  onKeyDown
}) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <div>
      <StyledLocationMarker
        $scale={scale}
        $isSelected={isSelected}
        tabIndex="0"
        onKeyDown={onKeyDown}
      >
        <Tooltip
          name={name}
          text={intl.formatMessage({
            id: type === 'mapEntry'
              ? 'locations.details.mapEntry'
              : reservationEnabled === 'enabled'
                ? 'locations.details.reservationAvailable'
                : 'locations.details.noReservationAvailable'
          })}
          reservationEnabled={reservationEnabled}
        />

        <BuaIcon
          scale={scale}
          color={locationTypesColorHelper(type, reservationEnabled, theme)}
        />
      </StyledLocationMarker>
    </div>
  );
};

LocationMarkerComponent.propTypes = {
  type: PropTypes.string.isRequired,
  scale: PropTypes.number,
  name: PropTypes.string,
  reservationEnabled: PropTypes.string,
  isSelected: PropTypes.bool,
  onKeyDown: PropTypes.func
};

export default LocationMarkerComponent;
