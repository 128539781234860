import { combineReducers } from 'redux';
import moment from 'moment';

import { FETCH_LOCATION } from '../../../../shared/components/Locations/Locations.actions';
import { URL_CHANGE } from '../../../../shared/components/App/App.actions';

import {
  TOGGLE_MODAL,
  SET_LOCATION,
  UNSET_LOCATION,
  FETCH_LOCATION_SUCCESS
} from './LocationSelector.actions';

const open = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return !state;
    case URL_CHANGE:
      return false;
    default:
      return state;
  }
};

const options = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return action.options || {};
    case URL_CHANGE:
      return {};
    default:
      return state;
  }
};

const sortByDate = (a, b) =>
  moment(a.attributes.date).format('YYYYMMDD') -
  moment(b.attributes.date).format('YYYYMMDD');

const markerLocation = (state = {}, action) => {
  switch (action.type) {
    case UNSET_LOCATION:
      return {};
    case SET_LOCATION: {
      return action.data;
    }
    case FETCH_LOCATION:
      return {
        ...state,
        loading: true
      };
    case FETCH_LOCATION_SUCCESS:
      return Object.assign({}, action.payload.data.attributes, {
        id: action.payload.data.id,
        loading: false,
        images:
          action.payload.data.relationships &&
          action.payload.data.relationships.images
            ? action.payload.data.relationships.images.data
            : [],
        openingHours:
          action.payload.data.relationships &&
          action.payload.data.relationships.openingHours
            ? action.payload.data.relationships.openingHours.data.filter(
                ({ attributes: { date } }) => !date
              )
            : [],
        closedDays:
          action.payload.data.relationships &&
          action.payload.data.relationships.openingHours
            ? action.payload.data.relationships.openingHours.data
                .filter(
                  ({ attributes: { date, openingTime, closingTime } }) =>
                    date && openingTime === '00:00' && closingTime === '00:00'
                )
                .map(item => ({
                  ...item,
                  attributes: {
                    ...item.attributes,
                    date: item.attributes.date.split('T')[0]
                  }
                }))
                .sort(sortByDate)
            : [],
        specialOpeningHours:
          action.payload.data.relationships &&
          action.payload.data.relationships.openingHours
            ? action.payload.data.relationships.openingHours.data
                .filter(
                  ({ attributes: { date, openingTime, closingTime } }) =>
                    date &&
                    !(openingTime === '00:00' && closingTime === '00:00')
                )
                .sort(sortByDate)
            : []
      });
    default:
      return state;
  }
};

const reducer = combineReducers({
  open,
  options,
  markerLocation
});

export default reducer;
