import React from 'react';
import PropTypes from 'prop-types';

import StyledDetailBlockLine from './DetailBlockLine.styled';

const DetailBlockLine = ({ icon, title, children }) => (
  <StyledDetailBlockLine $hasIcon={!!icon} $hasTitle={!!title}>
    {icon}
    {!title ? null : <h4>{title}</h4>}
    {children}
  </StyledDetailBlockLine>
);

DetailBlockLine.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ])
};

export default DetailBlockLine;
