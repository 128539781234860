import styled, { css } from 'styled-components';

import ButtonToggle from './ButtonToggle/ButtonToggle.styled';

const visible = css`
  display: block;
`;

const inModal = css`
  top: 58px !important;
  left: auto !important;
  right: 0 !important;
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  ${ButtonToggle} {
    display: block !important;
    margin-left: 4px;
  }
`;

const StyledLegend = styled.div`
  position: absolute;
  z-index: 1;
  padding: 8px;
  top: 56px;
  right: 0;
  font-size: 0.8em;

  ${props => props.theme.media.sm`
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    font-size: .9em;
  `};

  ${props => props.$inModal && inModal};

  .legends-container {
    display: none;

    ${props => props.$visible && visible};

    ${props =>
      !props.$inModal &&
      css`
        ${props => props.theme.media.sm`
        display: block !important;
      `};
      `}

    ${props => props.theme.media.xs`
      display: ${props => (props.$visible ? 'flex' : 'none')};
      position: fixed;
      justify-content: center;
      align-items: center;
      padding: 0 24px;
      border-radius: 4px;
      z-index: 10;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(48, 50, 54, 0.4);
    `};
  }

  .legends-content {
    width: 231px;

    ${props => props.theme.media.xs`
      background: white;
      width: 100%;
      max-width: 327px;
      border-radius: 4px;
      padding-bottom: 12px;
      box-shadow: 
        0 7px 16px 0 rgba(0, 0, 0, 0.16), 
        0 3px 7px 1px rgba(0, 0, 0, 0.08);
    `}
  }

  .legends-header {
    display: none;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 0 24px;
    margin-bottom: -4px;

    ${props => props.theme.media.xs`
      display: flex;
    `}
  }

  .legends-title {
    font-size: 20px;
    font-family: ${props => props.theme.fonts.medium};
  }

  .legends-close {
    cursor: pointer;
  }

  .legends-list {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 2px;
    padding: 8px 0;
    margin: 0;

    ${props => props.theme.media.xs`
      padding: 0;
      background: none;
      border-radius: 0;
    `}
  }
`;

export default StyledLegend;
