import track from './ga.tracker';

import {
  FORGOT_PASSWORD,
  SAVE_USER,
  SAVE_USER_WITH_VERIFY,
  SAVE_USER_SUCCESS,
  SAVE_USER_ERROR
} from '../../components/pages/User/User.actions';

const userTracking = {
  [FORGOT_PASSWORD]: () => {
    track.event({
      category: 'form',
      action: 'login',
      label: 'forgot_password_submit'
    });
  },

  [SAVE_USER]: () => {
    track.event({
      category: 'form',
      action: 'register',
      label: 'register_submit'
    });
  },

  [SAVE_USER_WITH_VERIFY]: () => {
    track.event({
      category: 'form',
      action: 'register',
      label: 'register_submit'
    });
  },

  [SAVE_USER_SUCCESS]: () => {
    track.event({
      category: 'form',
      action: 'register',
      label: 'register_success'
    });
  },

  [SAVE_USER_ERROR]: () => {
    track.event({
      category: 'form',
      action: 'register',
      label: 'register_error'
    });
  }
};

export default userTracking;
