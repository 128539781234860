import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import StyledHeaderMenuLinkMobile from './HeaderMenuLinkMobile.styled';

const HeaderMenuLinkMobile = ({ mobileExclusive, ...props }) => (
  <StyledHeaderMenuLinkMobile $mobileExclusive={ mobileExclusive }>
    <NavLink { ...props } />
  </StyledHeaderMenuLinkMobile>
);

HeaderMenuLinkMobile.propTypes = {
  mobileExclusive: PropTypes.string,
};

export default HeaderMenuLinkMobile;
