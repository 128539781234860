export const dates = {
  default: '1970-01-01',
  startFrom: '2000-01-01'
};

export const localeOptions = [
  {
    label: 'Norsk',
    value: 'no'
  }
];

export const localeDefault = 'no';
