import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ListCoBorrowers from './ListCoBorrowers/ListCoBorrowers.container';
import AddCoBorrower from './AddCoBorrower/AddCoBorrower.container';
import { StyledCoBorrower } from './CoBorrower.styled';

const CoBorrowers = ({ allFieldsDisabled, intl, ...props }) => (
  <StyledCoBorrower>
    <FormattedMessage tagName="h3" id="profile.details.coBorrower" />
    <ListCoBorrowers {...props} allFieldsDisabled={allFieldsDisabled} />
    <FieldArray
      name="userLinks"
      component={AddCoBorrower}
      allFieldsDisabled={allFieldsDisabled}
    />
    <FormattedMessage
      tagName="p"
      id="profile.details.coBorrower_hint"
      values={{
        link: (
          <Link
            className="black-link"
            to={intl.formatMessage({
              id: 'profile.details.coBorrower_hintLinkPath'
            })}
          >
            <FormattedMessage
              id="profile.details.coBorrower_hintLinkText"
              tagName="span"
            />
          </Link>
        )
      }}
    />
  </StyledCoBorrower>
);

CoBorrowers.propTypes = {
  intl: PropTypes.object.isRequired,
  initialize: PropTypes.func,
  allFieldsDisabled: PropTypes.bool,
  profile: PropTypes.object
};

export default CoBorrowers;
