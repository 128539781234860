export const RESET_PASSWORD = '@@user/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = '@@user/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = '@@user/RESET_PASSWORD_ERROR';
export const CANCEL_RESET_PASSWORD = '@@user/CANCEL_RESET_PASSWORD';

export const resetPassword = payload => ({
  type: RESET_PASSWORD,
  payload
});

export const resetPasswordSuccess = payload => ({
  type: RESET_PASSWORD_SUCCESS,
  payload
});

export const resetPasswordError = err => ({
  type: RESET_PASSWORD_ERROR,
  payload: err
});
