export const TOGGLE_HEADER_MENU = '@@header/TOGGLE_HEADER_MENU';
export const TOGGLE_DATE_PICKER = '@@header/TOGGLE_DATE_PICKER';
export const TOGGLE_LOCATION_SELECTOR = '@@header/TOGGLE_LOCATION_SELECTOR';
export const TOGGLE_BASKET = '@@header/TOGGLE_BASKET';
export const TOGGLE_HEADER_VISIBILITY = '@@header/TOGGLE_HEADER_VISIBILITY';
export const TOGGLE_HEADER_FORCE_MOBILE = '@@header/TOGGLE_HEADER_FORCE_MOBILE';

export const toggleHeaderMenu = open => ({
  type: TOGGLE_HEADER_MENU,
  open
});

export const toggleDatePicker = visible => ({
  type: TOGGLE_DATE_PICKER,
  visible
});

export const toggleLocationSelector = visible => ({
  type: TOGGLE_LOCATION_SELECTOR,
  visible
});

export const toggleBasket = visible => ({
  type: TOGGLE_BASKET,
  visible
});

export const toggleHeaderVisibility = visible => ({
  type: TOGGLE_HEADER_VISIBILITY,
  visible
});

export const toggleHeaderForceMobile = visible => ({
  type: TOGGLE_HEADER_FORCE_MOBILE,
  visible
});
