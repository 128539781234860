import styled from 'styled-components';

import { Geosuggest } from '../../../../../shared/libs/react-geosuggest';

const StyledMapSearchInput = styled(Geosuggest)`
  flex-grow: 1;
  position: relative;
  top: 1px;
  left: 1px;
  z-index: 2;

  .geosuggest__input {
    width: 100%;
    line-height: 20px;
    padding: 13px 16px;
    outline: none;
    border-radius: 2px;
    border: 0;
  }

  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: calc(100% + 101px);
    max-height: 25em;
    padding: 0;
    margin-top: 0;
    background: #fff;
    border: ${props => props.theme.colorGrayLight} solid 1px;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;

    &:first-child {
      border-top: 1px solid ${props => props.theme.colorGrayLight};
    }
  }
  
  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  /**
  * A geosuggest item
  */
  .geosuggest__item {
    font-size: 15px;
    padding: 0.5em 16px;
    cursor: pointer;
  }

  .geosuggest__item:hover,
  .geosuggest__item:focus {
    background: #f5f5f5;
  }

  .geosuggest__item--active {
    background: ${props => props.theme.colorPurple};
    color: #fff;
  }

  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    background: #ccc;
  }

  .geosuggest__item__matched-text {
    font-weight: bold;
  }
`;

export default StyledMapSearchInput;
