import { connect } from 'react-redux';

import { getAccumulatedItemsQuantity } from '../../../../../shared/components/common/Selectors/Items.selectors';
import { toggleBasket } from '../../Basket/Basket.actions';

import HeaderBasket from './HeaderBasket.component';

const mapStateToProps = ({ basket }) => ({
  open: basket.open,
  numItems: getAccumulatedItemsQuantity(basket.items)
});

const mapDispatchToProps = dispatch => ({
  toggleBasket() {
    dispatch(toggleBasket());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBasket);
