import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import LoadingAnimation from '../../../../../shared/components/common/LoadingAnimation/LoadingAnimation.component';
import { Container, Col, Row, offset } from '../../../../../shared/components/Grid';

import StyledEquipmentList from './EquipmentList.styled';
import EquipmentRow from './EquipmentRow/EquipmentRow.container';

class EquipmentList extends React.Component {
  renderRows() {
    const { basket } = this.props;

    const items =
      basket.items && basket.items.length
        ? basket.items
        : (
          basket.converted 
          && basket.converted.items 
          && basket.converted.items.length
        )
          ? basket.converted.items
          : [];

    return items.map(item => (
      <EquipmentRow 
        key={item.id} 
        item={item} 
        {...this.props} 
      />
    ));
  }

  render() {
    const { 
      basket, 
      isReceipt, 
      isCheckout, 
      isBasket, 
      removeItem, // eslint-disable-line
      updateQuantity, // eslint-disable-line
      ...listProps 
    } = this.props;

    return (
      <StyledEquipmentList 
        {...listProps} 
        $isCheckout={ isCheckout } 
        $isBasket={ isBasket }
      >
        <Container>
          <Row>
            <Col w={[6 / 6, 8 / 12]} ml={offset([0, 2 / 12])}>
              {isReceipt && (
                <span>
                  <div className="items-header">
                    <div className="items-header__product">
                      <FormattedMessage
                        tagName="div"
                        id="receipt.details.product"
                      />
                    </div>
                    <div className="items-header__quantity">
                      <FormattedMessage
                        tagName="div"
                        id="receipt.details.quantity"
                      />
                    </div>
                  </div>
                  <hr />
                </span>
              )}

              {basket.loading && !basket.items.length ? (
                <LoadingAnimation info="basket.loading" margin="20px 0" />
              ) : (
                this.renderRows()
              )}

              {isReceipt && <hr />}
            </Col>
          </Row>
        </Container>
      </StyledEquipmentList>
    );
  }
}

EquipmentList.propTypes = {
  basket: PropTypes.object,
  isReceipt: PropTypes.bool,
  isCheckout: PropTypes.bool,
  isBasket: PropTypes.bool,
  removeItem: PropTypes.func,
  updateQuantity: PropTypes.func
};

export default EquipmentList;
