import styled from 'styled-components';

const ImageBounds = styled.span`
  display: block;
  height: 0;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: ${props => props.$height
    ? props.$height
    : props.$heightRatio
      ? `${props.$heightRatio * 100}%`
      : 0};
  position: relative;

  > span {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      width: auto !important;
      height: auto !important;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      opacity: ${props => (props.$faded ? 0.4 : 1)};
    }
  }
`;

export default ImageBounds;
