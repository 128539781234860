import track from './ga.tracker';

import { TOGGLE_LOGIN_MODAL } from '../../components/common/Login/LoginModal/LoginModal.actions';
import {
  LOGOUT,
  POST_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR
} from '../../../shared/components/Login/Login.actions';

const loginTracking = {
  [TOGGLE_LOGIN_MODAL]: ({ store }) => {
    const {
      loginModal: { open }
    } = store.getState();
    if (!open) track.modal('login');
  },

  [LOGOUT]: () => {
    track.currentPage = '/logout';
    track.page(track.currentPage);
  },

  [POST_LOGIN]: () => {
    track.event({
      category: 'form',
      action: 'login',
      label: 'login_submit'
    });
  },

  [LOGIN_SUCCESS]: () => {
    track.event({
      category: 'form',
      action: 'login',
      label: 'login_success'
    });
  },

  [LOGIN_ERROR]: () => {
    track.event({
      category: 'form',
      action: 'login',
      label: 'login_error'
    });
  }
};

export default loginTracking;
