import styled from 'styled-components';

export const StyledListCoBorrowers = styled.div`
  margin-bottom: 24px;
  border: solid 1px ${props => props.theme.colorGrayLight};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  padding: 16px;
  text-align: left;

  table {
    width: 100%;
    
    thead {
      font-size: 12px;
      color: ${props => props.theme.colorGrayDark};
    }

    tbody {
      color: ${props => props.theme.colorDarker};
      font-size: 14px;
    }

    button {
      padding: 1px 4px;
      margin: 0;
      border-radius: 0;
      border: none;
      opacity: 0.3;
      outline: none;

      svg {
        width: 10px;
        height: 10px;
        pointer-events: none;
      }

      &:active,
      &:focus {
        outline: none;
        box-shadow: inset 0 0 0 1px ${props => props.theme.colorPurple};
        border-radius: 2px;
        opacity: 0.7;
        transition: 0.4s opacity ease-out;
      }

      &:hover {
        opacity: 0.7;
        cursor: pointer;
        transition: 0.4s opacity ease-out;
      }
    }
  }

  ${props => props.theme.media.sm`

  `};
`;

export default StyledListCoBorrowers;
