import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import DetailBlockLine from '../../../../common/DetailSection/DetailBlock/DetailBlockLine/DetailBlockLine.component';
import PhoneFormatter from '../../../../../../shared/components/PhoneFormatter/PhoneFormatter.component';

import StyledDetailsContact from './DetailsContact.styled';

const DetailsContact = ({ selected, intl }) => {
  return (
    <StyledDetailsContact>
      <h3>
        <FormattedMessage id="locations.details.contact" />
      </h3>

      {!selected.phone && !selected.email && !selected.visitAddress ? (
        <span className="missing-info">
          <FormattedMessage id="locations.details.missingContact" />
        </span>
      ) : null}

      {selected.phone ? (
        <DetailBlockLine
          title={intl.formatMessage({ id: `locations.details.phone` })}
        >
          <a href={`tel:${selected.phone}`}>
            <PhoneFormatter phoneNumber={selected.phone} />
          </a>
        </DetailBlockLine>
      ) : null}

      {selected.email ? (
        <DetailBlockLine
          title={intl.formatMessage({ id: `locations.details.email` })}
        >
          <a href={`mailto:${selected.email}`}>{`${selected.email}`}</a>
        </DetailBlockLine>
      ) : null}

      {selected.visitAddress ? (
        <DetailBlockLine
          title={intl.formatMessage({ id: `locations.details.visitAddress` })}
        >
          {`${selected.visitAddress},`}
          <br />
          {`${selected.visitZipCode} ${selected.visitCity}`}
        </DetailBlockLine>
      ) : null}

      {!selected.visitSameAsPostal && selected.postalAddress ? (
        <DetailBlockLine
          title={intl.formatMessage({ id: `locations.details.postalAddress` })}
        >
          {`${selected.postalAddress},`}
          <br />
          {`${selected.postalZipCode} ${selected.postalCity}`}
        </DetailBlockLine>
      ) : null}

      {selected.urlExternal ? (
        <DetailBlockLine
          title={intl.formatMessage({ id: `locations.details.urlExternal` })}
        >
          <a
            href={`http://${selected.urlExternal}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="general.clickHere" />
          </a>
        </DetailBlockLine>
      ) : null}

      {selected.urlFacebook ? (
        <DetailBlockLine
          title={intl.formatMessage({ id: `locations.details.urlFacebook` })}
        >
          <a
            href={`https://www.facebook.com/${selected.urlFacebook}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="general.clickHere" />
          </a>
        </DetailBlockLine>
      ) : null}

      {selected.urlInstagram ? (
        <DetailBlockLine
          title={intl.formatMessage({ id: `locations.details.urlInstagram` })}
        >
          <a
            href={`https://www.instagram.com/${selected.urlInstagram}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="general.clickHere" />
          </a>
        </DetailBlockLine>
      ) : null}
    </StyledDetailsContact>
  );
};

DetailsContact.propTypes = {
  intl: PropTypes.object.isRequired,
  selected: PropTypes.object
};

export default injectIntl(DetailsContact);
