import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import StyledHeaderMenuLink from './HeaderMenuLink.styled';

const HeaderMenuLink = ({ mobileExclusive, transparent, ...props }) => (
  <StyledHeaderMenuLink 
    $mobileExclusive={mobileExclusive} 
    $transparent={transparent}
  >
    <NavLink {...props} />
  </StyledHeaderMenuLink>
);

HeaderMenuLink.propTypes = {
  mobileExclusive: PropTypes.string,
  transparent: PropTypes.bool
};

export default HeaderMenuLink;
