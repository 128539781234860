import React from 'react';
import PropTypes from 'prop-types';

import ImageBounds from '../../../../../../shared/components/common/ImageBounds/ImageBounds.component';
import StyledEquipmentRow from '../../../../common/EquipmentRow/EquipmentRow.styled';
import { url, images as imagesPath } from '../../../../../config/api';
import Select from '../../../../common/Form/Select/Select.component';
import CloseBig from '../../../../../assets/icons/close-big';
import NoImage from '../../../../../assets/no-image.jpg';

class EquipmentRow extends React.Component {
  constructor(props) {
    super(props);

    this.remove = this.remove.bind(this);
    this.updateQuantity = this.updateQuantity.bind(this);
    this.getQuantityOptions = this.getQuantityOptions.bind(this);
  }

  remove() {
    const {
      loading,
      removeItem,
      item: { id }
    } = this.props;

    if (loading) {
      return;
    }

    removeItem(id);
  }

  updateQuantity(newQuantity) {
    const {
      updateQuantity,
      item: { variant }
    } = this.props;

    updateQuantity(variant.id, newQuantity);
  }

  getQuantityOptions() {
    const {
      availability,
      item: { quantity }
    } = this.props;
    let options = [];

    for (var i = 1; i <= Math.max(availability, quantity); i++) {
      if (i === quantity || i <= availability) {
        options.push(1);
      } else {
        options.push(0);
      }
    }
    return options;
  }

  render() {
    const {
      loading,
      isBasket,
      isCheckout,
      isReceipt,
      item: { image, quantity, equipment, brand, variantAttributeValues }
    } = this.props;

    const _image =
      image && image.attributes
        ? image.attributes.image
        : equipment.attributes.images.length
        ? equipment.attributes.images[0].attributes.image
        : null;

    const options = this.getQuantityOptions().map((o, i) => {
      let value = i + 1,
        opt = {
          value,
          label: value
        };

      if (!o) {
        opt.disabled = true;
      }

      return opt;
    });

    return (
      <StyledEquipmentRow
        $isCheckout={isCheckout}
        $isBasket={isBasket}
        $isReceipt={isReceipt}
        $loading={loading}
      >
        <div className="image-copy-holder">
          <ImageBounds height="64px" width="64px" className="image">
            {_image ? (
              <img
                src={`${url}${imagesPath.path}/${_image.id}/${_image.originalFilename}?format=medium`}
                alt={_image.alternativeText}
              />
            ) : (
              <img src={NoImage} alt={`-`} />
            )}
          </ImageBounds>
          
          <div className="copy">
            {isReceipt && (
              <span className="quantity-row mob">{quantity} stk.</span>
            )}

            <div className="description">
              <strong>
                {brand && brand.attributes && brand.attributes.value}
              </strong>{' '}
              {equipment && equipment.attributes && equipment.attributes.name}
            </div>

            <div className="variant">
              {isBasket && (
                <span>{quantity} stk. / </span>
              )}

              { variantAttributeValues && (
                variantAttributeValues.map((variantAttr, index) => (
                  <span key={index}>
                    {`${variantAttr.attributes.attributeLabel}: ${
                      variantAttr.attributes.value
                    }${variantAttributeValues.length - 1 > index ? ', ' : ''}`}
                  </span>
                ))
              )}
            </div>
          </div>
        </div>

        {!isBasket && !isReceipt && (
          <div className="quantity-selector">
            <Select
              options={options}
              value={quantity}
              disabled={loading}
              input={{ onChange: this.updateQuantity }}
            />
          </div>
        )}

        {!isReceipt && (
          <div className="remove-row" onClick={this.remove}>
            <CloseBig />
          </div>
        )}

        {isReceipt && (
          <div className="quantity-row sm">{quantity} stk.</div>
        )}
      </StyledEquipmentRow>
    );
  }
}

EquipmentRow.propTypes = {
  id: PropTypes.number,
  item: PropTypes.object,
  loading: PropTypes.bool,
  isCheckout: PropTypes.bool,
  isBasket: PropTypes.bool,
  isReceipt: PropTypes.bool,
  availability: PropTypes.number,
  quantity: PropTypes.number,
  updateQuantity: PropTypes.func,
  removeItem: PropTypes.func
};

export default EquipmentRow;
