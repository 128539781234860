import { combineReducers } from 'redux';

import { FETCH_LOCATION_SUCCESS } from '../../../../../shared/components/Locations/Locations.actions';

import { TOGGLE_EQUIPMENTINFOBAR } from './EquipmentInfoBar.actions';

const visible = (state = false, action) => {
  switch (action.type) {
    case FETCH_LOCATION_SUCCESS:
      return true;
    case TOGGLE_EQUIPMENTINFOBAR:
      return typeof action.visible === 'undefined' ? !state : !!action.visible;
    default:
      return state;
  }
};

const reducer = combineReducers({
  visible
});

reducer.reducer = 'equipmentinfobar';

export default reducer;
