import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { addUrlProps, UrlQueryParamTypes } from '../../../../../shared/libs/react-url-query';
import { forgotPassword, resetForgotPassword } from '../../../pages/User/User.actions';
import { postLogin } from '../../../../../shared/components/Login/Login.actions';

import LoginFormResetPasswordPart from './LoginFormResetPasswordPart/LoginFormResetPasswordPart.component';
import LoginFormLoginPart from './LoginFormLoginPart/LoginFormLoginPart.component';

const LoginFormComponent = ({
  redirect,
  loginForm,
  loginMode,
  setLoginMode,
  ...formProps
}) => {
  const dispatch = useDispatch();
  const { values } = loginForm;

  const showToggleForgot = useCallback((e) => {
    e.preventDefault();
    setLoginMode(!loginMode);
  }, [ setLoginMode, loginMode ]);

  const resetResetPasswordSuccsess = useCallback(() => {
    dispatch(resetForgotPassword());
  }, [ dispatch ]);

  const resetContinue = useCallback(() => {
    setLoginMode(true);
    resetResetPasswordSuccsess();
  }, [ setLoginMode, resetResetPasswordSuccsess ]);

  const submitLogin = useCallback(() => {
    dispatch(postLogin(values.email, values.password));
  }, [ values, dispatch ]);

  const submitForgotPassword = useCallback(() => {
    dispatch(forgotPassword(values.username));
  }, [ values, dispatch ]);

  useEffect(() => () => {
    setLoginMode(true);
  }, [ setLoginMode ]);

  return loginMode ? (
    <LoginFormLoginPart
      {...formProps}
      redirect={redirect}
      loginForm={loginForm}
      submitLogin={submitLogin}
      showToggleForgot={showToggleForgot}
    />
  ) : (
    <LoginFormResetPasswordPart
      {...formProps}
      loginForm={loginForm}
      submitForgotPassword={submitForgotPassword}
      showToggleForgot={showToggleForgot}
      resetContinue={resetContinue}
    />
  );
};

LoginFormComponent.propTypes = {
  redirect: PropTypes.string,
  loginForm: PropTypes.object,
  loginMode: PropTypes.bool,
  setLoginMode: PropTypes.func,
  resetResetPasswordSuccsess: PropTypes.func,
};

const urlPropsQueryConfig = {
  redirect: { type: UrlQueryParamTypes.string },
};

export default addUrlProps({ urlPropsQueryConfig })(LoginFormComponent);
