import styled from 'styled-components';

const StyledDetailsAbout = styled.div`
  padding: 16px 24px;
  display: inline-block;
  border-top: 1px solid ${props => props.theme.colorGrayLight};
  width: 100%;

  h3 {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.6px;
    margin-bottom: 16px;
    font-family: '${props => props.theme.fonts.medium}';
  }

  span {
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export default StyledDetailsAbout;
