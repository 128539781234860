import React from 'react';
import PropTypes from 'prop-types';

import withBounds from '../../../shared/hoc/withBounds';
import withSvg from '../../../shared/hoc/withSvg';

const iconAlarge = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="29"
    viewBox="0 0 28 29"
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#FFF"
        d="M27.116 26.39L17.979 1.45 17.684.435h-6.926L3.095 21.46l-1.769 4.785-.737 2.03H28l-.884-1.885zm-24.463.435l1.62-4.35h15.032L14.147 8.7l-4.126 11.455H5.158l6.631-18.27h4.864l9.136 24.94H2.653zM11.2 21.17l2.947-8.12 2.948 8.12H11.2z"
      />
      <path
        fill={color}
        d="M25.642 26.825l-8.99-24.94H11.79l-6.631 18.27h4.863L14.147 8.7l5.158 13.92H4.274l-1.621 4.205z"
      />
    </g>
  </svg>
);

iconAlarge.propTypes = {
  theme: PropTypes.object,
  color: PropTypes.string
};

export default withBounds(withSvg(iconAlarge));
