import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '../../../../../assets/icons/expand-more';
import Icon from '../../../../../assets/icons/a-small';

import StyledLegend from './Legend.styled';

export const Legend = ({ data }) => (
  <StyledLegend>
    <label htmlFor={data.type} className={'switcher'}>
      <span className={'header'}>
        <Icon color={data.color} />

        <FormattedMessage id={`legends.${data.type}`}>
          {title => <span className={'title'}>{title}</span>}
        </FormattedMessage>
      </span>

      <input type={'checkbox'} id={data.type} />

      <FormattedMessage id={`legends.${data.type}Description`}>
        {text => <span className={'text'}>{text}</span>}
      </FormattedMessage>

      <span className={'expand-icon'}>
        <ExpandMoreIcon />
      </span>
    </label>
  </StyledLegend>
);

Legend.propTypes = {
  data: PropTypes.object
};

export default Legend;
