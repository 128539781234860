import { combineReducers } from 'redux';

import { ROUTE_CHANGE } from './Route.actions';

const config = (state = {}, action) => {
  switch (action.type) {
    case ROUTE_CHANGE:
      return action.data;
    default:
      return state;
  }
};

// MAIN
const reducer = combineReducers({
  config
});

export default reducer;
