import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import EyeOff from '../../../../assets/icons/eye-off';
import Done from '../../../../assets/icons/done';
import Eye from '../../../../assets/icons/eye';

import DatePickerWrapper from './DatePickerWrapper/DatePickerWrapper.component';
import StyledInputField, { StyledPasswordReveal } from './InputField.styled';

const InputField = ({
  input,
  label,
  type,
  meta: { touched, error, active },
  revealPassword,
  toggleRevealPassword,
  autoComplete,
  disabled,
  placeholder,
  autoFocus,
  tabIndex,
  maxLength,
  max,
  required,
  intl,
  withPortal,
  openToDate,
  showSuccessIcon,
  split,
  splitLeft,
  splitRight,
  splitOneThird,
  splitTwoThirds,
  isBelow,
  isSingle,
  topNoRadius,
  bordered,
  removeNativeUi,
}) => {
  let bigLabel = false;

  if (touched && error) {
    bigLabel = false;

  } else if (input.value.length < 1 && !active) {
    bigLabel = true;
  }

  return (
    <StyledInputField
      $split={ split }
      $splitLeft={ splitLeft }
      $splitRight={ splitRight }
      $splitOneThird={ splitOneThird }
      $splitTwoThirds={ splitTwoThirds }
      $isBelow={ isBelow }
      $isSingle={ isSingle }
      $topNoRadius={ topNoRadius }
      $bordered={ bordered }
      $removeNativeUi={ removeNativeUi }
      className={`${bigLabel && type !== 'date' && !placeholder && 'big-label'}`}
    >
      {type === 'date' ? (
        <DatePickerWrapper
          input={input}
          placeholder={placeholder}
          disabled={disabled}
          withPortal={withPortal}
          openToDate={openToDate}
        />

      ) : type === 'textarea' ? (
        <textarea
          {...input}
          id={input.name}
          placeholder={placeholder}
          disabled={disabled}
        ></textarea>

      ) : (
        // eslint-disable-next-line jsx-a11y/no-redundant-roles
        <input
          {...input}
          type={revealPassword ? 'text' : type}
          id={input.name}
          autoComplete={autoComplete}
          disabled={disabled}
          placeholder={placeholder}
          autoFocus={autoFocus}
          tabIndex={tabIndex}
          maxLength={maxLength}
          max={max}
          aria-required={required}
          aria-invalid={touched && error ? true : false}
          aria-multiline={false}
          role={`textbox`}
          aria-disabled={disabled}
          aria-labelledby={`label_${input.name}`}
        />
      )}

      <label
        id={`label_${input.name}`}
        htmlFor={input.name}
        className={touched && error ? 'error' : undefined}
      >
        {label}
        {required && `*`}
        {!!touched && !!error && `: ${intl.formatMessage({ id: error })}`}
      </label>

      {showSuccessIcon !== undefined && (
        <span className="icon-container" data-tip={showSuccessIcon}>
          <Done />
        </span>
      )}

      {type === 'password' && (
        <StyledPasswordReveal onClick={toggleRevealPassword}>
          {revealPassword ? <Eye /> : <EyeOff />}
        </StyledPasswordReveal>
      )}
    </StyledInputField>
  );
};

InputField.defaultProps = {
  label: '',
  tabIndex: 0,
  type: 'text',
  required: false,
  disabled: false,
  autoFocus: false,
  autoComplete: '',
  placeholder: '',
  maxLength: null,
  max: null,
  withPortal: false
};

InputField.propTypes = {
  withPortal: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  props: PropTypes.object,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  toggleRevealPassword: PropTypes.func,
  revealPassword: PropTypes.bool,
  placeholder: PropTypes.string,
  intl: PropTypes.object,
  max: PropTypes.string,
  openToDate: PropTypes.string,
  showSuccessIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  split: PropTypes.bool,
  splitLeft: PropTypes.bool,
  splitRight: PropTypes.bool,
  splitOneThird: PropTypes.bool,
  splitTwoThirds: PropTypes.bool,
  isBelow: PropTypes.bool,
  isSingle: PropTypes.bool,
  topNoRadius: PropTypes.bool,
  bordered: PropTypes.bool,
  removeNativeUi: PropTypes.bool,
};

export default injectIntl(InputField);
