import styled from 'styled-components';

export const StyledCoBorrower = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;

  .black-link {
    font-family: ${props => props.theme.fonts.medium}
  }

  small {
    text-align: left;
    color: ${props => props.theme.colorGrayDark};
    font-size: 12px;
    font-family: '${props => props.theme.fonts.medium}';
    display: block;
    margin-bottom: 16px;
    margin-top: 0;
  }

  > p {
    text-align: left;
    margin: 16px 0 0 0;
    font-size: 15px;
  }

  ${props => props.theme.media.sm`

  `};
`;

export default StyledCoBorrower;
