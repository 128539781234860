import React from 'react';
import PropTypes from 'prop-types';

import StyledTooltip from './Tooltip.styled';

const Tooltip = ({ name, text }) => (
  <StyledTooltip className="marker-tooltip">
    <div className="content">
      <strong>{name}</strong>
      {!!text && <p>{text}</p>}
    </div>
  </StyledTooltip>
);

Tooltip.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default Tooltip;
