import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import InputFieldsWrapper from '../../../../../common/Form/InputFieldsWrapper/InputFieldsWrapper.styled';
import { formatMobilePhone } from '../../../../../../../shared/components/common/Form/Form.formatting';
import { required, minLength2, phone, email } from '../../../../../../config/validations';
import InputField from '../../../../../common/Form/InputField/InputField.container';
import ButtonLink from '../../../../../common/ButtonLink/ButtonLink.styled';
import Button from '../../../../../common/Button/Button.component';

import StyledAddCoBorrower from './AddCoBorrower.styled';

class AddCoBorrower extends React.Component {
  render() {
    const { fields, allFieldsDisabled, currentDate } = this.props;

    return (
      <StyledAddCoBorrower>
        {fields.map((coborrower, index) => (
          <div key={index} className="coborrower-fields">
            {!allFieldsDisabled && (
              <div className="remove-button-holder">
                <ButtonLink onClick={() => fields.remove(index)} $mb="8px">
                  <FormattedMessage id="forms.removeCoBorrowerCta" />
                </ButtonLink>
              </div>
            )}

            <InputFieldsWrapper>
              <Field
                component={InputField}
                name={`${coborrower}.firstName`}
                type="text"
                label={this.props.intl.formatMessage({ id: 'forms.firstName' })}
                required
                validate={[required, minLength2]}
                autoComplete="off"
                split
                splitLeft
                disabled={allFieldsDisabled}
              />

              <Field
                component={InputField}
                name={`${coborrower}.lastName`}
                type="text"
                label={this.props.intl.formatMessage({ id: 'forms.lastName' })}
                required
                validate={[required, minLength2]}
                autoComplete="off"
                split
                disabled={allFieldsDisabled}
              />

              <Field
                component={InputField}
                name={`${coborrower}.email`}
                type="email"
                label={this.props.intl.formatMessage({ id: 'forms.email' })}
                required
                validate={[required, email]}
                autoComplete="off"
                isBelow
                disabled={allFieldsDisabled}
              />

              <Field
                component={InputField}
                name={`${coborrower}.mobilePhone`}
                type="tel"
                label={this.props.intl.formatMessage({
                  id: 'forms.mobilePhone'
                })}
                required
                validate={[required, phone]}
                autoComplete="off"
                format={formatMobilePhone}
                parse={value => value && value.replace(/\s/g, '').slice(0, 8)}
                isBelow
                disabled={allFieldsDisabled}
              />

              <Field
                component={InputField}
                name={`${coborrower}.birthday`}
                type="date"
                placeholder={'dd.mm.yyyy'}
                label={this.props.intl.formatMessage({ id: 'forms.birthday' })}
                required
                validate={[required]}
                max={currentDate}
                autoComplete="off"
                isBelow
                withPortal
                disabled={allFieldsDisabled}
              />
            </InputFieldsWrapper>
          </div>
        ))}

        {!allFieldsDisabled && (
          <div>
            <Button
              className="add-coborrower"
              type="button"
              discrete
              onClick={() => fields.push({})}
            >
              <span className="plus-icon">+</span>
              <FormattedMessage id="forms.addCoBorrowerCta" />
            </Button>
          </div>
        )}
      </StyledAddCoBorrower>
    );
  }
}

AddCoBorrower.propTypes = {
  fields: PropTypes.object,
  intl: PropTypes.object,
  allFieldsDisabled: PropTypes.bool,
  currentDate: PropTypes.string
};

export default injectIntl(AddCoBorrower);
