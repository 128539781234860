import Index from '../shared/components/Index';

import middlewares from './store/initialMiddleware';
import history from '../shared/store/history';
import { locale as localeConfig } from './config/locale';
import theme from './styles/theme';
import GlobalStyles from './styles/global';
import App from './components/App/App.component';
import reducers from './store/initialReducers';
import clientReducers from '../shared/store/reducers';
import { url, login } from './config/api';
import { cookies } from './config/login';
import routes from './config/routes';
import routeComponents from './config/routeComponents';

Index.create(App, {
  localeData: localeConfig.localeData,
  locales: localeConfig.path,
  elementId: 'app',
  reducers: {
    ...reducers,
    ...clientReducers
  },
  middlewares,
  history,
  theme,
  GlobalStyles,
  routes,
  routeComponents,
  apiBaseUrl: url,
  apiRefreshUrl: login.refresh,
  cookieConfig: cookies
});