import { combineReducers } from 'redux';
import remove from 'lodash/remove';
import clone from 'lodash/clone';
import moment from 'moment';

import { LOGOUT } from '../../../../shared/components/Login/Login.actions';
import { DELETE_USER_SUCCESS } from '../../pages/User/User.actions';
import {
  TOGGLE_BASKET,
  RESET_BASKET,
  SET_BASKET_INFO,
  SAVE_BASKET_SUCCESS,
  SAVE_BASKET_ERROR,
  ADD_BASKET_ITEM,
  ADD_BASKET_ITEM_SUCCESS,
  ADD_BASKET_ITEM_ERROR,
  FETCH_BASKET,
  FETCH_BASKET_SUCCESS,
  FETCH_BASKET_ERROR,
  REMOVE_BASKET_ITEM_SUCCESS,
  CONVERT_BASKET,
  CONVERT_BASKET_SUCCESS,
  CONVERT_BASKET_ERROR
} from '../Basket/Basket.actions';

const open = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_BASKET:
      return typeof action.open === 'undefined' ? !state : !!action.open;
      
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case SAVE_BASKET_ERROR:
    case FETCH_BASKET_SUCCESS:
    case FETCH_BASKET_ERROR:
    case ADD_BASKET_ITEM_SUCCESS:
    case ADD_BASKET_ITEM_ERROR:
    case RESET_BASKET:
    case CONVERT_BASKET_SUCCESS:
    case CONVERT_BASKET_ERROR:
      return false;

    case FETCH_BASKET:
    case ADD_BASKET_ITEM:
    case CONVERT_BASKET:
      return true;

    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case FETCH_BASKET:
    case FETCH_BASKET_SUCCESS:
    case ADD_BASKET_ITEM_SUCCESS:
    case CONVERT_BASKET_SUCCESS:
      return false;

    case FETCH_BASKET_ERROR:
      return action.payload;

    default:
      return state;
  }
};

const item = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BASKET_SUCCESS:
    case SAVE_BASKET_SUCCESS:
    case ADD_BASKET_ITEM_SUCCESS:
      return {
        id: state.id,
        type: state.type,
        ...state.attributes
      };
    default:
      return state;
  }
};

const items = (state = [], action) => {
  switch (action.type) {
    case RESET_BASKET:
    case FETCH_BASKET_ERROR:
    case SAVE_BASKET_ERROR:
    case CONVERT_BASKET_SUCCESS:
      return [];

    case SAVE_BASKET_SUCCESS:
    case FETCH_BASKET_SUCCESS: {
      const items = action.payload.data.relationships.items.data.map(i => item(i, action));

      return items.map(item => {
        return stitchItemData(action.payload.data, item);
      });
    }
    case ADD_BASKET_ITEM_SUCCESS: {
      const current = state.find(l => String(l.id) === String(action.payload.data.id));

      if (current) {
        return state.map(l => String(action.payload.data.id) === String(l.id)
          ? stitchItemData(action.payload.data, l)
          : l
        );

      } else {
        return [
          ...state,
          stitchItemData(action.payload.data, item(action.payload.data, action))
        ];
      }
    }
    case REMOVE_BASKET_ITEM_SUCCESS: {
      const items = clone(state);
      const newItems = remove(items, item => String(item.id) === String(action.id) ? false : true);

      return newItems;
    }
    default:
      return state;
  }
};

const selected = (state = {}, action) => {
  switch (action.type) {
    case RESET_BASKET:
    case FETCH_BASKET_ERROR:
    case SAVE_BASKET_ERROR:
    case CONVERT_BASKET_SUCCESS:
      return {};

    case SET_BASKET_INFO:
      return action.payload;

    case SAVE_BASKET_SUCCESS:
    case FETCH_BASKET_SUCCESS: {
      const data = action.payload.data;

      return {
        id: action.payload.data.id,
        pickupAt: moment(data.attributes.pickupAt).format('DD-MM-YYYY'),
        returnAt: moment(data.attributes.returnAt).format('DD-MM-YYYY'),
        location: data.relationships && data.relationships.location
          ? data.relationships.location
          : null
      };
    }
    default:
      return state;
  }
};

const converted = (state = {}, action) => {
  switch (action.type) {
    case SAVE_BASKET_SUCCESS:
      return {};

    case CONVERT_BASKET:
      return { loading: true };

    case CONVERT_BASKET_SUCCESS: {
      const { items, selected } = action.payload;
      return {
        items,
        selected,
        loading: false
      };
    }
    case CONVERT_BASKET_ERROR:
      return { error: action.payload };

    case LOGOUT:
    case DELETE_USER_SUCCESS:
      return {};

    default:
      return state;
  }
};

const stitchItemData = (data, item) => {
  const variant = data.relationships.variants
    ? data.relationships.variants.data.find(v => String(v.id) === String(item.variant))
    : data.relationships.variant.data || null;

  const equipment = Array.isArray(data.relationships.equipment.data)
    ? data.relationships.equipment.data.find(e => String(e.id) === String(variant.attributes.equipment))
    : data.relationships.equipment.data || null;

  return {
    ...item,
    ...data.attributes,
    variant,
    equipment,
    image: data.relationships.images && equipment.attributes?.images?.length
      ? data.relationships.images.data.find(e => String(e.id) === String(equipment.attributes.images[0]))
      : null,
    brand: data.relationships.equipmentAttributeValues && equipment.attributes.brand
      ? data.relationships.equipmentAttributeValues.data.find(e => String(e.id) === String(equipment.attributes.brand))
      : null,
    variantAttributeValues: data.relationships.variantAttributeValues.data.filter(
      av => variant.attributes.attributeValues.indexOf(av.id) >= 0
    )
  };
};

const reducer = combineReducers({
  open,
  loading,
  error,
  converted,
  items,
  selected
});

export default reducer;
