import styled from 'styled-components';

export const StyledProfile = styled.div`
  margin-top: 32px;

  h1 {
    text-align: center;
  }

  hr {
    float: left;
    width: 100%;
    margin: 40px 0;
    border: none;
    border-top: 1px solid ${props => props.theme.colorGrayLight};
  }

  .additional-info {
    margin-bottom: 8px;

    > div {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 15px;
      margin-left: 8px;
    }
  }

  ${props => props.theme.media.sm`

  `};
`;

export default StyledProfile;
