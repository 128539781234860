import equipmentVariantSelectorReducer from '../components/pages/Equipment/EquipmentDetails/EquipmentVariantSelector/EquipmentVariantSelector.reducers';
import equipmentFiltersReducer from '../components/pages/Equipment/EquipmentBrowser/EquipmentFilters/EquipmentFilters.reducers';
import equipmentInfoBarReducer from '../components/pages/Equipment/EquipmentInfoBar/EquipmentInfoBar.reducers';
import locationSelectorReducer from '../components/common/LocationSelector/LocationSelector.reducers';
import sizeGuideBarReducer from '../components/pages/Equipment/SizeGuideBar/SizeGuideBar.reducers';
import loginModalReducer from '../components/common/Login/LoginModal/LoginModal.reducers';
import categoriesReducer from '../../shared/components/Categories/Categories.reducers';
import DialogsReducer from '../../shared/components/common/Dialogs/Dialogs.reducers';
import locationsReducer from '../../shared/components/Locations/Locations.reducers';
import routeReducer from '../../shared/components/common/Route/Route.reducers';
import loginReducer from '../../shared/components/Login/Login.reducers';
import basketReducer from '../components/common/Basket/Basket.reducers';
import headerReducer from '../components/common/Header/Header.reducers';
import vippsReducer from '../components/common/Vipps/Vipps.reducers';
import formReducer from '../components/common/Form/Form.reducers';
import userReducer from '../components/pages/User/User.reducers';
import mapReducer from '../components/common/Map/Map.reducers';

const initialReducers = {
  form: formReducer,
  login: loginReducer,
  user: userReducer,
  loginModal: loginModalReducer,
  locations: locationsReducer,
  categories: categoriesReducer,
  map: mapReducer,
  route: routeReducer,
  header: headerReducer,
  locationSelector: locationSelectorReducer,
  dialogs: DialogsReducer,
  sizeguidebar: sizeGuideBarReducer,
  basket: basketReducer,
  equipmentvariantselector: equipmentVariantSelectorReducer,
  equipmentfilters: equipmentFiltersReducer,
  [equipmentInfoBarReducer.reducer]: equipmentInfoBarReducer,
  vipps: vippsReducer
};

export default initialReducers;