import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import withConfirmDialog from '../../../../../shared/hoc/withConfirmDialog';
import Modal from '../../Modal/Modal.component';
import Button from '../../Button/Button.styled';

import StyledConfirmDialog from './ConfirmDialog.styled';

const ConfirmDialog = ({ action, clickConfirm, clickCancel, theme }) => (
  <Modal onClose={clickCancel} open={true}>
    <StyledConfirmDialog>
      {!action.content ? (
        <FormattedMessage tagName="h2" id="forms.confirm" />
      ) : (
        action.content
      )}
      
      <div className="buttons">
        <Button
          type="button"
          onClick={clickCancel}
          $color={theme.colorWhite}
          $borderLess={action.borderLess}
          $discrete
        >
          <FormattedMessage id="forms.cancel" />
        </Button>

        <Button type="button" onClick={() => clickConfirm(action)}>
          <FormattedMessage id={action.confirmPhraseId || 'forms.confirm'} />
        </Button>
      </div>
    </StyledConfirmDialog>
  </Modal>
);

ConfirmDialog.propTypes = {
  clickConfirm: PropTypes.func,
  clickCancel: PropTypes.func,
  action: PropTypes.object,
  theme: PropTypes.object
};

export default withTheme(withConfirmDialog(ConfirmDialog));
