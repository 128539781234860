export const TOGGLE_SIZEGUIDEBAR = '@@sizeguidebar/TOGGLE_SIZEGUIDEBAR';

export const SET_GUIDE_ENTRY = '@@sizeguidebar/SET_GUIDE_ENTRY';

export const toggleSizeGuideBar = () => ({
  type: TOGGLE_SIZEGUIDEBAR
});

export function setGuideEntry(entry = {}) {
  return {
    type: SET_GUIDE_ENTRY,
    entry
  };
}
