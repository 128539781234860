import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';

import { isInclusivelyAfterDay, isInclusivelyBeforeDay, START_DATE, END_DATE } from '../../../../shared/libs/react-dates';
import { HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION, DateRangePicker } from '../../../../shared/libs/react-dates';
import { checkClosedDate } from '../../../../shared/components/Locations/Locations.selectors.js';
import { addUrlProps, multiReplaceInUrlQuery } from '../../../../shared/libs/react-url-query';
import { showConfirm } from '../../../../shared/components/common/Dialogs/Dialogs.actions';
import withBreakpoints from '../../../../shared/hoc/withBreakpoints';
import { datepickerConfig } from '../../../config/datepicker';
import { resetBasketDates } from '../Basket/Basket.actions';
import { useStoreState } from '../../../../shared/hooks';

import StyledDatePicker from './DatePicker.styled';

const makeUniqueNumber = () => 
  Math.round(Math.random() * new Date().valueOf() / 1000);

const DatePickerComponent = ({
  readOnly,
  pickupAt,
  returnAt,
  breakpoints,
  enableOnClick,
  inHeader,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    basket, 
    locations,
    locations: { selected: location }
  } = useStoreState();

  const [ focusedInput, setFocusedInput ] = useState(null);
  const [ startDate, setStartDate ] = useState(null);
  const [ endDate, setEndDate ] = useState(null);

  const disabled = !!basket.items.length;

  const {
    minimumNights,
    maximumNightsDefault,
    maximumNightsExtended,
    forceEndDate,
    firstAvailableDay,
    lastAvailableDay,
  } = datepickerConfig;

  const startDateId = useMemo(
    () => `start-date-${makeUniqueNumber()}`, 
    []
  );

  const endDateId = useMemo(
    () => `end-date-${makeUniqueNumber()}`,
    []
  );

  const maximumNights = useMemo(() => (
    locations.selected && locations.selected.maxLoanDuration
      ? locations.selected.maxLoanDuration - 1
      : maximumNightsDefault
  ), [ locations, maximumNightsDefault ]);

  const clearDatePicker = useCallback(() => {
    dispatch(
      showConfirm({
        acceptAction: resetBasketDates(),
        content: intl.formatMessage({ id: `basket.confirm_reset_date` })
      })
    );
  }, [ intl, dispatch ]);
  
  const setDates = useCallback(() => {
    setStartDate(pickupAt
      ? moment(pickupAt, intl.formatMessage({ id: 'general.urlDateFormat' }))
      : null
    );
    setEndDate(returnAt
      ? moment(returnAt, intl.formatMessage({ id: 'general.urlDateFormat' }))
      : null
    );
  }, [ intl, pickupAt, returnAt ]);

  const checkPastDate = useCallback((date) => {
    const firstPossibleDate = startDate && focusedInput === END_DATE
      ? startDate
      : moment().add(firstAvailableDay, 'days');

    return !isInclusivelyAfterDay(date, firstPossibleDate);
  }, [ focusedInput, startDate, firstAvailableDay ]);

  const checkAvailableStartDate = useCallback((date) => {
    return isInclusivelyBeforeDay(date, moment()
      .add(lastAvailableDay, 'days'));
  }, [ lastAvailableDay ]);

  const checkAvailableEndDate = useCallback((date, nights) => {
    const lastPossibleEndDate = startDate?.clone().add(nights, 'days');

    return (
      !lastPossibleEndDate 
      || isInclusivelyBeforeDay(date, lastPossibleEndDate)
    );
  }, [ startDate ]);

  const getFirstAvailableDateInRange = useCallback((firstDay, nights) => {
    let availableDate = false;
    let checkNight = 0;

    while (!availableDate && checkNight <= nights - 1) {
      const checkDate = firstDay.clone().add(checkNight, 'days');

      availableDate = checkClosedDate(location, checkDate) 
        ? false 
        : checkDate;

      checkNight++;
    }
    return availableDate;
  }, [ location ]);

  // use DatePicker.isOutsideRange - return `false` if available and `true` if unavailable.
  const checkOpeningHours = useCallback((date) => {
    const isPast = checkPastDate(date);
    const closed = checkClosedDate(location, date);

    if (isPast || closed) {
      return true;

    } else if (focusedInput === END_DATE) {
      // check if inside initial date range
      if (checkAvailableEndDate(date, maximumNights)) {
        return false;
      }

      // check if inside extended date range, and same as first valid date inside extended range
      return !date.isSame(
        getFirstAvailableDateInRange(
          startDate.clone().add(maximumNights, 'days'),
          maximumNightsExtended - maximumNights + 1
        ),
        'day'
      );

    } else {
      return !checkAvailableStartDate(date);
    }
  }, [ 
    startDate, 
    focusedInput, 
    location, 
    maximumNights, 
    maximumNightsExtended, 
    checkPastDate, 
    checkAvailableEndDate,
    checkAvailableStartDate,
    getFirstAvailableDateInRange
  ]);

  const onChange = useCallback((date) => {
    if (!date.startDate || !date.endDate) {
      return;
    }

    multiReplaceInUrlQuery({
      pickupAt: moment(date.startDate).format(
        intl.formatMessage({ id: 'general.urlDateFormat' })
      ),
      returnAt: moment(date.endDate).format(
        intl.formatMessage({ id: 'general.urlDateFormat' })
      )
    });
  }, [ intl ]);
  
  const onFocusChange = useCallback((focusedInput) => {
    const newFocusedInput = focusedInput === END_DATE && !startDate 
      ? START_DATE 
      : focusedInput;

    setFocusedInput(newFocusedInput);

    if (forceEndDate && focusedInput === START_DATE) {
      setEndDate(null);
    }
  }, [ forceEndDate, startDate ]);

  const onClick = useCallback(() => {
    if (enableOnClick && disabled) {
      clearDatePicker();
    }
  }, [ disabled, enableOnClick, clearDatePicker ]);

  const onDatesChange = useCallback(({ startDate, endDate }) => {
    const date = {
      startDate,
      endDate: forceEndDate && startDate
        ? startDate.clone().add(maximumNights, 'days')
        : focusedInput === START_DATE
          ? null
          : endDate
    };
    const newFocusedInput = focusedInput === START_DATE && !date.endDate
      ? END_DATE
      : focusedInput === END_DATE
        ? null
        : focusedInput;

    setTimeout(() => {
      setFocusedInput(newFocusedInput);
    });

    setStartDate(date.startDate);
    setEndDate(date.endDate);
    
    onChange(date);
  }, [ maximumNights, forceEndDate, focusedInput, onChange ]);

  useEffect(() => {
    setDates();
  }, [ setDates ]);

  return (
    <StyledDatePicker $inHeader={inHeader} onClick={onClick}>
      { disabled && (
        <div className="disabled-overlay" />
      ) }

      <DateRangePicker
        readOnly={readOnly}
        withFullScreenPortal={breakpoints.xs}
        numberOfMonths={2}
        orientation={ breakpoints.xs ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION }
        onFocusChange={onFocusChange}
        onDatesChange={onDatesChange}
        minimumNights={minimumNights}
        startDate={startDate}
        startDateId={startDateId}
        startDatePlaceholderText={intl.formatMessage({ id: 'header.datePicker.startDate' })}
        endDate={endDate}
        endDateId={endDateId}
        endDatePlaceholderText={intl.formatMessage({ id: 'header.datePicker.endDate' })}
        isOutsideRange={checkOpeningHours}
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel
        customArrowIcon="-"
        displayFormat={intl.formatMessage({ id: 'general.dateFormat' })}
        renderCalendarInfo={() => (
          <p className="DateRangePicker__info">
            <FormattedMessage id="equipment.datepicker.info" />
          </p>
        )}
      />
    </StyledDatePicker>
  );
};

DatePickerComponent.propTypes = {
  pickupAt: PropTypes.string,
  returnAt: PropTypes.string,
  breakpoints: PropTypes.object.isRequired,
  enableOnClick: PropTypes.bool,
  inHeader: PropTypes.bool,
  readOnly: PropTypes.bool,
};

const mapUrlToProps = url => ({
  pickupAt: url.pickupAt,
  returnAt: url.returnAt
});

export default addUrlProps({ mapUrlToProps })(
  withBreakpoints(DatePickerComponent)
);
