import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

import HideIcon from '../../../../assets/icons/close-small';

import ButtonToggle from './ButtonToggle/ButtonToggle.component';
import Legend from './Legend/Legend.component';
import StyledLegends from './Legends.styled';

const Legends = ({ items, visible, theme, onToggle, inModal }) => (
  <StyledLegends $visible={visible} $inModal={inModal}>
    <div className="legends-container">
      <div className="legends-content">
        <div className="legends-header">
          <FormattedMessage id={`legends.title`}>
            {title => <div className="legends-title">{title}</div>}
          </FormattedMessage>

          <div className="legends-close" onClick={onToggle}>
            <HideIcon fill={theme.colorGrayDarker} width={15} height={15} />
          </div>
        </div>

        <ul className="legends-list">
          {items.map((item, index) => (
            <Legend data={item} key={index} />
          ))}
        </ul>
      </div>
    </div>

    <ButtonToggle onClick={onToggle} visible={visible} inModal={inModal} />
  </StyledLegends>
);

Legends.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  visible: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  onToggle: PropTypes.func,
  inModal: PropTypes.bool
};

export default withTheme(Legends);
