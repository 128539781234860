import ReactGA from 'react-ga';

import { UA, options, trackingRules } from '../../config/tracking/ga';

window.addEventListener(
  'CookieInformationConsentGiven',
  () => {
    if (
      window.CookieInformation &&
      window.CookieInformation.getConsentGivenFor &&
      window.CookieInformation.getConsentGivenFor('cookie_cat_statistic')
    ) {
      ReactGA.initialize(UA, options);
    }
  },
  false
);

export const googleAnalytics = store => next => action => {
  if (trackingRules[action.type]) {
    trackingRules[action.type]({ store, action, next });
  }

  return next(action);
};

export default googleAnalytics;
