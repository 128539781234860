import React from 'react';
import PropTypes from 'prop-types';

import StyledTextInputSearch from '../../Form/TextInputSearch/TextInputSearch.styled';
import ClearIcon from '../../../../assets/icons/close-small';

const TextInputClear = ({ width = 42, height = 42, ...props }) => (
  <StyledTextInputSearch {...props}>
    <ClearIcon width={width} height={height} />
  </StyledTextInputSearch>
);

TextInputClear.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default TextInputClear;
