import locationSelector from './track.locationSelector';
import pageviews from './track.pageviews';
import archive from './track.archive';
import header from './track.header';
import login from './track.login';
import user from './track.user';

/* config */
export const UA = 'UA-116476609-1';
export const options = {
  debug: true,
  titleCase: false
};

/* tracking rules */
export const trackingRules = {
  ...pageviews,
  ...login,
  ...user,
  ...archive,
  ...header,
  ...locationSelector
};
