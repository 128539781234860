import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import TextInputSearch from '../../Form/TextInputSearch/TextInputSearch.component';
import { google_places_country_restrictions } from '../../../../config/locations';
import TextInputClear from '../../Form/TextInputClear/TextInputClear.component';
import { useStoreState } from '../../../../../shared/hooks';

import StyledMapSearchWrapper from './MapSearchWrapper.styled';
import MapSearchInput from './MapSearchInput.styled';

const MapSearchComponent = ({ 
  markerLocation, 
  locations,
  isInModal,
  placeholder,
  onSuggestSelect,
}) => {
  const {
    map: { loaded: mapLoaded }
  } = useStoreState();

  const [ isInputFocused, setIsInputFocused ] = useState(false);
  const [ isInputEmpty, setIsInputEmpty ] = useState(true);
  const [ fixtures, setFixtures ] = useState([]);

  const inputSearchRef = useRef();

  const suggestSelectHandler = useCallback((l) => {
    if (l && l.location) {
      onSuggestSelect(l);
    }
  }, [ onSuggestSelect ]);

  const updateInputState = useCallback((valueArg = undefined) => {
    let hasValue;

    if (valueArg === undefined && inputSearchRef.current) {
      const { userInput, activeSuggest } = inputSearchRef.current.state || {};

      hasValue = (
        (userInput || '').trim() 
        || !!activeSuggest
      );

    } else {
      hasValue = typeof valueArg === 'string' 
        ? valueArg.trim() 
        : !!valueArg;
    }

    setIsInputEmpty(!hasValue);
  }, []);

  const onInputChange = useCallback((value) => {
    updateInputState(value);
  }, [ updateInputState ]);

  const onInputFocus = useCallback(() => {
    setIsInputFocused(true);
  }, []);

  const onInputBlur = useCallback(() => {
    setIsInputFocused(false);
  }, []);

  const onInputKeyPress = useCallback(($event) => {
    if ($event.which === 13) {
      $event.preventDefault();
    }
  }, []);

  const onSearchBtnClick = useCallback(() => {
    if (!inputSearchRef.current) {
      return;
    }

    const { activeSuggest } = inputSearchRef.current.state;

    if (!activeSuggest) {
      return;
    }

    inputSearchRef.current.selectSuggest(activeSuggest);
  }, []);

  const onClearBtnClick = useCallback(() => {
    if (!inputSearchRef.current) {
      return;
    }

    inputSearchRef.current.clear();
    inputSearchRef.current.setState({ activeSuggest: null });

    setTimeout(() => {
      updateInputState();
    });
  }, [ updateInputState ]);

  const onActivateSuggest = useCallback((suggest) => {
    updateInputState(suggest);
  }, [ updateInputState ]);

  const defineFixtures = useCallback(() => {
    const fixtures = [];

    for (const location of locations) {
      fixtures.push({
        label: location.name,
        location: {
          lat: location.lat,
          lng: location.lng
        }
      });
    }

    setFixtures(fixtures);
  }, [ locations ]);

  useLayoutEffect(() => {
    if (inputSearchRef.current) {
      inputSearchRef.current.focus();
    }
  }, []);

  useEffect(() => {
    defineFixtures();
  }, [ defineFixtures ]);

  return !mapLoaded ? null : (
    <StyledMapSearchWrapper
      $isInModal={isInModal}
      $markerLocation={markerLocation}
    >
      <div className={'map-search-content' + (isInputFocused ? ' focused' : '')}>
        <MapSearchInput
          tabIndex="0"
          autoComplete="off"
          autoActivateFirstSuggest
          placeholder={placeholder}
          country={google_places_country_restrictions}
          innerRef={inputSearchRef}
          fixtures={fixtures}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onKeyPress={onInputKeyPress}
          onActivateSuggest={onActivateSuggest}
          onSuggestSelect={suggestSelectHandler}
        />

        <div className="controls">
          <TextInputSearch
            className="search-button"
            tabIndex="0"
            submitSearch={onSearchBtnClick}
          />
          
          {!isInputEmpty && (
            <React.Fragment>
              <span className="controls-divider" />

              <TextInputClear
                className="clear-button"
                tabIndex="0"
                width={16}
                height={16}
                onClick={onClearBtnClick}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </StyledMapSearchWrapper>
  );
};

MapSearchComponent.propTypes = {
  type: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.any),
  placeholder: PropTypes.string,
  isInModal: PropTypes.bool,
  markerLocation: PropTypes.object,
  onSuggestSelect: PropTypes.func,
};

export default MapSearchComponent;
