import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';

import LoginFormComponent from '../LoginForm/LoginForm.component';
import { useStoreState } from '../../../../../shared/hooks';
import Modal from '../../Modal/Modal.component';

import { toggleLoginModal as toggleLoginModalAction } from './LoginModal.actions';
import StyledLoginModal from './LoginModal.styled';

const LoginModalComponent = (formProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    login = {}, 
    loginModal: { open: isOpened }, 
    form: { loginForm = {} }, 
    user
  } = useStoreState();

  const [ loginMode, setLoginMode ] = useState(true);

  const getTitleId = useCallback(() => (
    user.profile && user.profile.passwordreset
      ? 'login.details.resetSuccessTitle'
      : loginMode
        ? 'login.route.name'
        : 'login.details.forgotPasswordTitle'
  ), [ user.profile, loginMode ]);

  const closeOnClickHandler = useCallback(() => {
    dispatch(toggleLoginModalAction());
  }, [ dispatch ]);

  const titleId = useMemo(
    () => getTitleId(), 
    [ getTitleId ]
  );

  return (
    <Modal
      open={isOpened}
      clickClose={closeOnClickHandler}
      mobileTitle={intl.formatMessage({ id: titleId })}
    >
      <StyledLoginModal>
        <FormattedMessage tagName="h2" id={titleId} />
        <LoginFormComponent
          {...formProps}
          login={login}
          loginForm={loginForm}
          loginMode={loginMode}
          setLoginMode={setLoginMode}
        />
      </StyledLoginModal>
    </Modal>
  );
};

export default reduxForm({ form: 'loginForm' })(LoginModalComponent);
