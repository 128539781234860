import { createLogicWithApi } from '../../../../../shared/logicCreators';
import { user as apiConfig } from '../../../../config/api';

import {
  RESET_PASSWORD,
  CANCEL_RESET_PASSWORD,
  resetPasswordSuccess,
  resetPasswordError
} from './ResetPassword.actions';

const resetPasswordLogic = createLogicWithApi({
  type: RESET_PASSWORD,
  cancelType: CANCEL_RESET_PASSWORD,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'PATCH',
      path: `${apiConfig.path}${apiConfig.resetPasswordPath}`,
      requireLogin: false,
      handle: r => {
        return r.send({
          attributes: action.payload
        });
      }
    })
      .then(resp => {
        dispatch(resetPasswordSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(resetPasswordError(err.response.body));
        done();
      });
  }
});

const logics = [
  resetPasswordLogic
];

export default logics;
