import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';

const Eye = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 13">
    <path d="M8.775 13c-5.5 0-8.6-6-8.7-6.3-.1-.1-.1-.3 0-.4.1-.3 3.2-6.3 8.7-6.3 5.5 0 8.6 6 8.7 6.3.1.1.1.3 0 .4-.1.3-3.2 6.3-8.7 6.3zm-7.6-6.5c.6 1.1 3.4 5.5 7.7 5.5 4.3 0 7.1-4.4 7.7-5.5-.6-1.1-3.4-5.5-7.7-5.5-4.4 0-7.1 4.4-7.7 5.5zm7.6 2.8c-1.5 0-2.7-1.3-2.7-2.8 0-1.5 1.2-2.8 2.8-2.8 1.6 0 2.7 1.3 2.7 2.8 0 1.5-1.2 2.8-2.8 2.8zm0-4.6c-.9 0-1.7.8-1.7 1.8s.8 1.8 1.8 1.8 1.7-.8 1.7-1.8-.8-1.8-1.8-1.8z" />
  </svg>
);

Eye.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withSvg(Eye);
