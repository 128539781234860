import styled from 'styled-components';

export const StyledUpdatePasswordForm = styled.div`
  float: left;
  width: 100%;

  button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 90;
    margin: 9px;
    font-size: 13px;
    min-height: 36px;

    &.password-mode {
      border: 1px solid ${props => props.theme.colorGrayLight};
      color: ${props => props.theme.colorGrayDark};
    }
  }

  input[name='password_initial']:disabled {
    background: initial;
    color: initial;
    letter-spacing: 0.15em;

    &::placeholder {
      color: ${props => props.theme.colorBlack};
    }
  }
`;

export default StyledUpdatePasswordForm;
