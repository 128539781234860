import { css, createGlobalStyle } from 'styled-components';

import vars from '../variables';

const variables = css`
  /* stylelint-disable */
  $react-dates-width-input: 48%;
  $react-dates-width-tooltip-arrow: 20px;
  $react-dates-spacing-vertical-picker: 72px;

  $react-dates-color-white: #fff;
  $react-dates-color-gray: #363c4c;
  $react-dates-color-gray-dark: #798296;
  $react-dates-color-gray-light: #a3abbd;
  $react-dates-color-gray-lighter: #dfe3ea;
  $react-dates-color-gray-lightest: #f4f6f9;

  $react-dates-color-primary: #00a984;
  $react-dates-color-primary-shade-1: #23dfb7;
  $react-dates-color-primary-shade-2: $react-dates-color-primary-shade-1;
  $react-dates-color-primary-shade-3: $react-dates-color-primary-shade-1;
  $react-dates-color-primary-shade-4: $react-dates-color-gray-lightest;
  $react-dates-color-secondary: #262f3d;

  $react-dates-color-border: $react-dates-color-gray-dark;
  $react-dates-color-border-light: $react-dates-color-gray-lighter;
  $react-dates-color-border-medium: $react-dates-color-gray-lightest;
  $react-dates-color-placeholder-text: $react-dates-color-gray-light;
  $react-dates-color-text: inherit;
  $react-dates-color-text-focus: inherit;
  $react-dates-color-focus: $react-dates-color-white;

  $react-dates-z-index: 0;
  /* stylelint-enable */
`;

const DatePickerGlobalStyles = createGlobalStyle`
  ${variables}

  .DateRangePicker_picker__portal {
    z-index: 100;
  }

  .CalendarDay__blocked_out_of_range.CalendarDay__selected_span,
  .CalendarDay__after_hovered_start:not(.CalendarDay__selected_end) {
    color: ${vars.colorWhite};
    background: #66e2da;
    border: #33dacd double 1px;
    border-right: 0;
  }

  .DateRangePicker__info {
    font-size: 14px;
    line-height: 1.4;
    padding: 0 22px 18px;
  }
`;

export default DatePickerGlobalStyles;
