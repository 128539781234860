/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps } from '../airbnb-prop-types';
import directionPropType from './proptypes/direction';
import DirectionProvider from './DirectionProvider';
import withDirection from './withDirection';

const propTypes = forbidExtraProps({
  children: PropTypes.node.isRequired,
  direction: directionPropType.isRequired,
  inline: PropTypes.bool,
  text: PropTypes.string.isRequired,
  lang: PropTypes.string,
});

const defaultProps = {
  inline: false,
};

function AutoDirectionProviderComponent({
  children,
  direction,
  inline,
  text,
  lang,
}) {
  const textDirection = direction(text);
  const dir = textDirection === 'neutral' ? direction : textDirection;

  return (
    <DirectionProvider
      direction={dir}
      inline={inline}
      {...(lang && { lang })}
    >
      {React.Children.only(children)}
    </DirectionProvider>
  );
}

AutoDirectionProviderComponent.propTypes = propTypes;
AutoDirectionProviderComponent.defaultProps = defaultProps;

const AutoDirectionProvider = withDirection(AutoDirectionProviderComponent);

export default AutoDirectionProvider;
