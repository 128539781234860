import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form } from 'redux-form';
import PropTypes from 'prop-types';

import InputFieldsWrapper from '../../../Form/InputFieldsWrapper/InputFieldsWrapper.styled';
import SubmitErrors from '../../../SubmitErrors/SubmitErrors.component';
import InputField from '../../../Form/InputField/InputField.container';
import { required, email } from '../../../../../config/validations';
import { useStoreState } from '../../../../../../shared/hooks';
import Button from '../../../Button/Button.component';

const LoginFormResetPasswordPartComponent = ({
  loginForm,
  resetContinue,
  submitForgotPassword,
  showToggleForgot,
  ...formProps
}) => {
  const intl = useIntl();
  const { user: { profile, loading } } = useStoreState();

  const renderForm = useCallback(() => (
    <Form onSubmit={formProps.handleSubmit(submitForgotPassword)}>
      {profile.resetPasswordError && (
        <SubmitErrors errors={profile.resetPasswordError} />
      )}

      <InputFieldsWrapper>
        <Field
          component={InputField}
          name="username"
          type="email"
          label={intl.formatMessage({ id: 'forms.email' })}
          autoComplete="email"
          validate={[required, email]}
          autoFocus={true}
          disabled={loading}
        />
      </InputFieldsWrapper>

      <div className="form-controls">
        <Button
          type="submit"
          fullWidth
          loading={loading}
          shadow={ false }
          disabled={
            loading 
            || !loginForm.values?.username
            || (typeof loginForm.syncErrors == 'object' ? true : false) 
          }
        >
          <FormattedMessage id="forms.sendNewPasswordCta" />
        </Button>

        <div>
          <p>
            <FormattedMessage id="forms.rememberedPassword" />

            <a href="#" onClick={showToggleForgot}>
              <FormattedMessage id="forms.rememberedPasswordCta" />
            </a>
          </p>
        </div>
      </div>
    </Form>
  ), [ 
    intl,
    loading, 
    formProps, 
    loginForm.syncErrors, 
    loginForm.values, 
    profile.resetPasswordError, 
    showToggleForgot, 
    submitForgotPassword
  ]);

  const renderSuccess = useCallback(() => {
    return (
      <span>
        <div className="text-center">
          <FormattedMessage id="login.details.resetSuccessBody" />
        </div>

        <div className="form-controls">
          <Button onClick={resetContinue} shadow={false} fullWidth>
            <FormattedMessage id="forms.continue" />
          </Button>
        </div>
      </span>
    );
  }, [ resetContinue ]);

  return !profile.passwordreset
    ? renderForm()
    : renderSuccess();
}

LoginFormResetPasswordPartComponent.propTypes = {
  loginForm: PropTypes.object,
  submitForgotPassword: PropTypes.func,
  showToggleForgot: PropTypes.func,
  resetContinue: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default LoginFormResetPasswordPartComponent;
