import { fetchLocationSelectorSuccess } from '../../common/LocationSelector/LocationSelector.actions';
import { createLogicWithApi } from '../../../../shared/logicCreators';
import { locations as apiConfig } from '../../../config/api';
import {
  FETCH_LOCATIONS,
  CANCEL_FETCH_LOCATIONS,
  fetchLocationsSuccess,
  fetchLocationsError,
  FETCH_LOCATION,
  FETCH_LOCATION_BY_SLUG,
  CANCEL_FETCH_LOCATION,
  fetchLocationSuccess,
  fetchLocationError
} from '../../../../shared/components/Locations/Locations.actions';

const fetchLocationsLogic = createLogicWithApi({
  type: FETCH_LOCATIONS,
  cancelType: CANCEL_FETCH_LOCATIONS,
  latest: true,
  process: ({ Api, action }, dispatch, done) => {
    return Api({
      method: 'get',
      requireLogin: false,
      cache: true,
      path: apiConfig.path,
      handle: r => {
        const sort = typeof action.sort !== 'undefined' ? action.sort : 'name';
        const desc = typeof action.desc !== 'undefined' ? action.desc : false;
        const filters = typeof action.filters !== 'undefined' ? action.filters : '';
        const search = typeof action.search !== 'undefined' ? action.search : '';
        const limit = typeof action.limit !== 'undefined' ? action.limit : 0;

        if (typeof action.offset !== 'undefined') {
          r.query({ offset: action.offset });
        }

        if (action.excludeRelationships) {
          r.query({ relationships: '0' });
        }

        r.query({ limit: limit })
          .query({ filters: filters })
          .query({ query: search })
          .query({ 'order[field]': sort })
          .query({ 'order[dir]': desc ? 'desc' : 'asc' });
        return r;
      }
    })
      .then(resp => {
        dispatch(fetchLocationsSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchLocationsError(err));
        done();
      });
  }
});

const fetchLocationLogic = createLogicWithApi({
  type: FETCH_LOCATION,
  cancelType: CANCEL_FETCH_LOCATION,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'get',
      requireLogin: false,
      cache: true,
      path: `${apiConfig.path}/${action.payload.id}`
    })
      .then(resp => {
        if (action.payload.isForMarker) {
          dispatch(fetchLocationSelectorSuccess(resp));
        } else {
          dispatch(fetchLocationSuccess(resp));
        }
        done();
      })
      .catch(err => {
        dispatch(fetchLocationError(err));
        done();
      });
  }
});

const fetchLocationBySlugLogic = createLogicWithApi({
  type: FETCH_LOCATION_BY_SLUG,
  cancelType: CANCEL_FETCH_LOCATION,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'get',
      requireLogin: false,
      cache: true,
      path: `${apiConfig.path}${apiConfig.slugPath}/${action.slug}`
    })
      .then(resp => {
        dispatch(fetchLocationSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchLocationError(err));
        done();
      });
  }
});

const logics = [
  fetchLocationsLogic,
  fetchLocationLogic,
  fetchLocationBySlugLogic
];

export default logics;
