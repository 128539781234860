import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledPurpleLink = styled(Link)`
  color: ${props => props.theme.colorPurple};

  &:hover {
    opacity: 0.8;
    transition: 0.2s opacity ease-out;
  }
`;

export default StyledPurpleLink;
