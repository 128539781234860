import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Locations from '../../pages/Locations/Locations.component';
import Modal from '../Modal/Modal.component';

const LocationSelector = ({ clickClose, open, intl, options }) => (
  <Modal
    open={open}
    clickClose={clickClose}
    mobileTitle={intl.formatMessage({ id: 'locations.route.name' })}
  >
    <Locations hideMapEntryLocations isInModal={true} options={options} />
  </Modal>
);

LocationSelector.propTypes = {
  intl: PropTypes.object,
  setLocation: PropTypes.func,
  clickClose: PropTypes.func,
  open: PropTypes.bool,
  options: PropTypes.object
};

export default injectIntl(LocationSelector);
