import React from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '../../../../../shared/assets/icons/search';

import StyledTextInputSearch from './TextInputSearch.styled';

const TextInputSearch = ({ submitSearch, ...props }) => (
  <StyledTextInputSearch 
    type="submit" 
    onClick={submitSearch}
    {...props} 
  >
    <SearchIcon width={42} height={42} />
  </StyledTextInputSearch>
);

TextInputSearch.propTypes = {
  submitSearch: PropTypes.func,
};

export default TextInputSearch;
