import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import LoadingGfx from '../../../../shared/components/common/Loading/Loading.component';

import StyledButton from './Button.styled';

const Button = ({ 
  children, 
  loading, 
  theme, 
  shadow = true, 
  color, 
  fontColor, 
  discrete, 
  fullWidth, 
  green,
  ...props
}) => (
  <StyledButton 
    $shadow={shadow}
    $color={color}
    $fontColor={fontColor}
    $discrete={discrete}
    $fullWidth={fullWidth}
    $green={green}
    {...props}
  >
    {loading ? (
      <LoadingGfx fill={theme.colorWhite} />
      
    ) : (
      children 
    )}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  theme: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  shadow: PropTypes.bool,
  color: PropTypes.string,
  fontColor: PropTypes.string,
  discrete: PropTypes.bool,
  fullWidth: PropTypes.bool,
  green: PropTypes.bool
};

export default withTheme(Button);
