import React from 'react';
import PropTypes from 'prop-types';

import Legends from './Legends.component';

class LegendContainer extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    inModal: PropTypes.bool,
    onToggle: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    if (this.props.onToggle) {
      this.props.onToggle(!this.state.visible);
    }

    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }

  render() {
    return (
      <Legends
        {...this.props}
        onToggle={this.handleToggle}
        visible={this.state.visible}
      />
    );
  }
}

export default LegendContainer;
