import routes from '../../../config/routes';

export const createLinkProps = (cmsLinks, intl, loggedIn) => {
  return [
    ...cmsLinks.map(({ title, linkUrl }) => {
      const toPath = linkUrl || '/404';

      return {
        key: title,
        end: linkUrl === '/',
        to: toPath,
        name: title
      };
    }),
    ...routes
      .filter(({ inMenu, requireLogin, requireLogout }) => {
        if (
          (requireLogin && !loggedIn) 
          || (requireLogout && loggedIn)
        ) {
          return false;
        }

        return inMenu;
      })
      .map(({ key, end, mobileExclusive }) => {
        const toPath = intl.messages[`${key}.route.toPath`] 
          || intl.messages[`${key}.route.path`] 
          || '/404';

        return {
          key,
          end,
          to: toPath,
          mobileExclusive: (mobileExclusive || false).toString(),
          name: intl.formatMessage({ id: `${key}.route.name` })
        };
      })
  ];
};
