import styled, { css } from 'styled-components';

import reactSelectCSS from './Select.scss';

export const selectBase = css`
  ${reactSelectCSS.toString()}
  position: relative;
  font-size: 14px;

  .Select.is-focused .Select-control,
  .Select.is-open .Select-control {
    border-color: ${props => props.theme.colorGrayLight};
    background-color: ${props => props.theme.colorWhite};
  }

  .Select-arrow-zone {
    width: 40px;
    pointer-events: none;
    padding-left: 0;
    padding-right: 0;

    svg {
      vertical-align: middle;
    }
  }

  .Select-menu-outer {
    text-align: left;
  }

  .Select-input > input {
    line-height: 40px;
    padding: 0;
  }

  .Select--multi .Select-value {
    margin-top: 8px;
    margin-left: 8px;
  }

  .Select--multi .Select-value-icon {
    padding-left: 8px;
    padding-right: 8px;
    border-right: none;
  }

  .Select--multi .Select-value-label {
    padding-left: 0;
  }
`;

const StyledSelect = styled.div`
  ${selectBase}
`;

export default StyledSelect;
