import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { showConfirm } from '../../../../../../../shared/components/common/Dialogs/Dialogs.actions';
import { removeUserLink } from '../../../../../pages/User/User.actions';

import ListCoBorrowersComponent from './ListCoBorrowers.component';

const mapDispatchToProps = dispatch => ({
  removeUserLinkById: e => {
    const id = e.target.getAttribute('data-userlinkid');

    if (!id) return;

    dispatch(
      showConfirm({
        acceptAction: removeUserLink(id),
        content: (
          <FormattedMessage
            tagName="h3"
            id="profile.details.removeCoBorrowerConfirm"
          />
        )
      })
    );
  }
});

export default connect(
  null,
  mapDispatchToProps
)(ListCoBorrowersComponent);
