import React from 'react';

import BoxSizingGlobalStyles from '../../shared/styles/globals/box-sizing';
import NormalizeGlobalStyles from '../../shared/styles/globals/normalize';
import UtilitiesGlobalStyles from '../../shared/styles/globals/utilities';
import TextGlobalStyles from '../../shared/styles/globals/text';

import DatePickerGlobalStyles from './globals/date-picker';
import StickyFooterGlobalStyles from './globals/sticky-footer';
import TypographyGlobalStyles from './globals/typography';
import ImageGlobalStyles from './globals/image';
import HtmlGlobalStyles from './globals/html';

const GlobalStyles = () => (
  <>
    <NormalizeGlobalStyles />
    <BoxSizingGlobalStyles />
    <UtilitiesGlobalStyles />
    <TextGlobalStyles />
    <HtmlGlobalStyles />
    <TypographyGlobalStyles />
    <ImageGlobalStyles />
    <StickyFooterGlobalStyles />
    <DatePickerGlobalStyles />
  </>
);

export default GlobalStyles;
