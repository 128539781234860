import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import LoadingAnimation from '../../shared/components/common/LoadingAnimation/LoadingAnimation.component';
import { ApolloError, QueryResult } from '@apollo/client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TGQLResponse<TData = any> = (
  { status: 'loading' | 'error' | 'success'; } 
  & (
    { render: () => ReactElement | null; } 
    | { data: TData | null; }
  )
);

const useQueryResponse = (data: QueryResult): TGQLResponse => {
  const { loading, error, ...payload } = data || {};
  
  if (loading || !payload) {
    return {
      status: 'loading',
      render: () => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <LoadingAnimation info="general.loading" />
      ),
    };
  }

  if (error) {
    const errorMessage = extractGraphQlErrorStatus(error);

    return {
      status: 'error',
      render: () => (
        <GraphqlWrapperStyled>
          <FormattedMessage tagName="h2" id="error.gqlWrapperTitle" />
          <p>{errorMessage}</p>
        </GraphqlWrapperStyled>
      ),
    };
  }

  return {
    status: 'success',
    data: payload.data
  };
};

const GraphqlWrapperStyled = styled.div`
  text-align: center;
  padding-top: 15%;

  & + & {
    display: none;
  }

  h1,
  h2,
  h3,
  p {
    margin: 0 0 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

function extractGraphQlErrorStatus(errorObj: ApolloError): string {
  if (errorObj.graphQLErrors?.length) {
    const error = errorObj.graphQLErrors[0];

    return error?.extensions 
      ? error.extensions.status as string 
      : 'error';
  }

  return errorObj.toString();
}

export default useQueryResponse;
