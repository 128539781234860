import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import ReactSelect from '../../../../../shared/libs/react-select';
import DropDownIcon from '../../../../assets/icons/drop-down';

import StyledSelect from './Select.styled';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Select = ({ input, theme, tabIndex, meta, ...props }) => (
  <StyledSelect>
    <ReactSelect
      {...input}
      {...props}
      tabIndex={tabIndex}
      arrowRenderer={() => <DropDownIcon fill={theme.colorGrayDarker} />}
      onBlur={() => {
        input &&
          input.onBlur &&
          input.onBlur(
            Array.isArray(input.value)
              ? input.value.map(value => (value.value ? value.value : value))
              : input.value !== null
              ? input.value.value
              : null
          );
      }}
      onChange={value => {
        input &&
          input.onChange &&
          input.onChange(
            Array.isArray(value)
              ? value.map(val => (val.value ? val.value : val))
              : value !== null
              ? value.value
              : null
          );
      }}
    />
  </StyledSelect>
);

Select.defaultProps = {
  clearable: false,
  autoBlur: true,
  tabIndex: 1
};

Select.propTypes = {
  clearable: PropTypes.bool,
  autoBlur: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  input: PropTypes.shape({
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any
  }),
  meta: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  tabIndex: PropTypes.number
};

export default withTheme(Select);
