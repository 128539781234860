import styled from 'styled-components';

const StyledLoginBtn = styled.button`
  display: none;
  font-family: '${props => props.theme.fonts.medium}';
  color: ${props => !props.$transparent ? props.theme.colorGrayDarker : props.theme.colorWhite};
  font-size: 22px;
  line-height: 28px;
  padding: 0;
  cursor: pointer;
  transition: 0.2s opacity ease-out;
  vertical-align: middle;
  width: max-content;
  position: absolute;
  right: 45px;
  top: 7px;
  background: no-repeat;
  border: none;

  &:hover {
    opacity: 0.6;
  }

  ::selection {
    background: transparent;
  }

  ${props => props.theme.media.lg`
    top: 5px;
  `};

  ${props => props.theme.media.md`
    display: inline-block;
  `};
`;

export default StyledLoginBtn;
