import styled, { css } from 'styled-components';

const transition = css`
  transition: height 0.3s ease-in-out;

  .header__middle-content {
    ${props => props.theme.media.xs`
      transition: top 0.2s ease-in-out;
    `};
  }
`;

const shadow = css`
  box-shadow: 0 1px 4px 0 rgba(38, 38, 38, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.06);
`;

const StyledHeader = styled.div`
  ${props => (props.$animated ? transition : null)}
  ${props => (props.$shadow ? shadow : null)}
  position: ${props => (props.$fixed ? 'fixed' : 'absolute')};
  top: ${props => props.$showVerifyBanner ? '40px' : 0};
  z-index: 98;
  left: 0;
  width: 100%;
  color: ${props => props.$white ? props.theme.colorGrayDarker : props.theme.colorWhite };
  height: ${props => (props.$visible ? props.theme.headerMobileHeight : 0)}px;
  line-height: 40px;

  ${props => props.theme.media.lg`
    height: ${props => props.$visible
      ? ( props.$forseMobile
          ? props.theme.headerMobileHeight
          : props.theme.headerHeight
        )
      : 0}px;
    line-height: 32px;
  `};

  .header__container {
    background: ${props => props.$white ? props.theme.colorWhite : 'transparent'};
    transition: 0.3s background-color linear;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    height: ${props => props.theme.headerMobileHeight}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    ${props => props.theme.media.md`
      background: ${props => !props.$forseMobile ? 'transparent' : props.theme.colorWhite};
    `};

    ${props => props.theme.media.lg`
      height: ${props =>
        props.$forseMobile
          ? props.theme.headerMobileHeight
          : props.theme.headerHeight}px;
    `};

    & > * {
      position: relative;
    }
  }

  .logo {
    width: 54px;
    height: 30px;
    margin-top: 20px;
    ${props => props.theme.media.sm`
      display: inline;
      height: 32px;
    `};

    ${props => props.theme.media.lg`
      width:  ${props => (props.$forseMobile ? '54px' : '99px')};
      height: ${props => (props.$forseMobile ? '50px' : '62px')};   
      margin-top: ${props => (props.$forseMobile ? '15px' : '0')};     
    `};
  }

  .hamburger-forceMobile-container {
    display: flex;
    align-items: center;

    .logo-equipment {
      margin-left: 20px;
    }
    ${props => props.theme.media.lg`
      display: ${props => (props.$forseMobile ? 'flex' : 'none')};
    `};
  }

  svg {
    vertical-align: middle;

    path {
      transition: fill 0.2s linear;
    }
  }

  .header__middle-content {
    text-align: center;
    position: absolute;
    left: 0;
    top: ${props => (props.$visible ? 100 : 0)}%;
    width: 100%;
    height: 50px;
    clear: both;

    & > * {
      display: inline-block;
      vertical-align: top;
      background: ${props => props.theme.colorWhite};

      &:first-child:nth-last-child(1) {
        width: 100%;
      }

      &:first-child:nth-last-child(2),
      &:first-child:nth-last-child(2) ~ * {
        width: 50%;
      }

      &:first-child:nth-last-child(3),
      &:first-child:nth-last-child(3) ~ * {
        width: 33.333%;
      }

      ${props => props.theme.media.lg`
        width: 200px !important;
      `};
    }

    &:first-child:nth-last-child(1) {
      width: 100%;
    }

    ${props => props.theme.media.xs`
      &:empty {
        display: none;
      }
    `};

    ${props => props.theme.media.sm`
      &:empty {
        display: none;
      }
    `};

    ${props => props.theme.media.lg`
      position: static;
      width: auto;
      display: ${props => (props.$forseMobile ? 'inline-flex' : 'none')};
      height: 100%;
    `};
  }

  .header__login-content {
    ${props => props.theme.media.lg`
      position: relative;
      top: -5px;
    `}
  }

  .login-link-text__short {
    display: none;

    ${props => props.theme.media.lg`
      display: inline;
    `}
    ${props => props.theme.media.xl`
      display: none;
    `}
  }

  .login-link-text__long {
    display: none;

    ${props => props.theme.media.md`
      display: inline;
    `}
    ${props => props.theme.media.lg`
      display: none;
    `}
    ${props => props.theme.media.xl`
      display: inline;
    `}
  }
`;

export default StyledHeader;
