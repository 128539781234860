import { createGlobalStyle, css } from 'styled-components';

import { fonts } from '../../../shared/styles/fonts';
import vars from '../variables';

const TypographyGlobalStyles = createGlobalStyle`
  ${fonts(vars.fonts)};

  button,
  input,
  optgroup,
  select,
  textarea,
  html,
  body {
    font-family: '${vars.fonts.regular}', sans-serif;
  }

  html {
    font-size: 10px;
    color: ${vars.colorEggplant};
  }

  body {
    font-size: 17px;
    line-height: 1.47;
  }

  b,
  strong {
    font-weight: normal;
    font-family: '${vars.fonts.medium}';
  }

  h1,
  h2 {
    font-weight: normal;
    line-height: 1.2;
  }

  h1 {
    margin: 0 0 24px;
  }

  h2 {
    font-size: 34px;
    margin: 0 0 48px;

    & + p {
      margin-top: -40px;
    }
  }

  h3,
  h4,
  .subtitle {
    font-family: '${vars.fonts.medium}';
    font-weight: normal;
    font-size: inherit;
    margin: 0 0 24px;

    h1 + & {
      margin-top: -16px;
    }
  }

  p {
    margin: 0 0 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    transition: color 0.1s linear;
    color: inherit;
  }
`;

export const articleTypography = css`
  h1 {
    font-size: 32px;
    line-height: 42px;

    ${props => props.theme.media.sm`
      font-size: 56px;
      line-height: 64px;
    `}
  }

  h2 {
    font-size: 32px;
    line-height: 42px;

    ${props => props.theme.media.sm`
      font-size: 48px;
      line-height: 56px;
    `}
  }

  h3 {
    font-size: 28px;
    line-height: 38px;

    ${props => props.theme.media.sm`
      font-size: 34px;
      line-height: 44px;
    `}
  }

  h4 {
    font-size: 28px;
    line-height: 38px;
  }

  p {
    font-size: 22px;
    line-height: 32px;
  }
`;

export default TypographyGlobalStyles;