import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import StyledContainer from '../../../../shared/components/Grid/Container.styled';
import Button from '../Button/Button.styled';

import StyledBanner from './VerifiseringBanner.styled';

const VerifiseringBanner = ({ verifyClick, onlyContactInfo }) => (
  <StyledBanner>
    <StyledContainer>
      <div className="wrapper">
        <FormattedMessage id={`verifisering.details.${
            onlyContactInfo ? 'contactInfoNotVerified' : 'profileNotVerified'
        }`}/>

        <Button 
          $shadow={ false } 
          className="verify-button" 
          onClick={verifyClick}
        >
          <FormattedMessage id="verifisering.details.verifyHere" />
        </Button>
      </div>
    </StyledContainer>
  </StyledBanner>
);

VerifiseringBanner.propTypes = {
  verifyClick: PropTypes.func,
  onlyContactInfo: PropTypes.bool
};

export default withTheme(VerifiseringBanner);
