import React from 'react';
import PropTypes from 'prop-types';

import HeaderMenuLink from './HeaderMenuLink/HeaderMenuLink.component';
import StyledHeaderMenu from './HeaderMenu.styled';

const HeaderMenu = ({ links, transparent }) => (
  <StyledHeaderMenu>
    {links.map(link => (
      <HeaderMenuLink
        {...link}
        transparent={transparent}
        key={link.key}
      >
        {link.name}
      </HeaderMenuLink>
    ))}
  </StyledHeaderMenu>
);

HeaderMenu.propTypes = {
  transparent: PropTypes.bool,
  links: PropTypes.array.isRequired
};

export default HeaderMenu;
