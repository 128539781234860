import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import withSizes from '../libs/react-sizes';

const mapSizesToProps = ({ width }, { theme }) => ({
  breakpoints: {
    xs: width < theme.intBreakpoints[0],
    sm: width >= theme.intBreakpoints[0],
    md: width >= theme.intBreakpoints[1],
    lg: width >= theme.intBreakpoints[2],
    xl: width >= theme.intBreakpoints[3]
  }
});

const withBreakpoints = WrappedComponent => {
  class withBreakpoints extends React.Component {
    static propTypes = {
      theme: PropTypes.object.isRequired,
      breakpoints: PropTypes.shape({
        xs: PropTypes.bool,
        sm: PropTypes.bool,
        md: PropTypes.bool,
        lg: PropTypes.bool,
        xl: PropTypes.bool
      })
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return withTheme(withSizes(mapSizesToProps)(withBreakpoints));
};

export default withBreakpoints;
