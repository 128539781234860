import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import ShowIcon from '../../../../../../shared/assets/icons/info';
import HideIcon from '../../../../../assets/icons/close-small';

import StyledButtonToggle from './ButtonToggle.styled';

const ButtonToggle = ({ onClick, theme, visible, inModal }) => (
  <StyledButtonToggle onClick={onClick}>
    {visible && inModal ? (
      <React.Fragment>
        <HideIcon
          className="hide-button-regular"
          fill={theme.colorGrayDarker}
          width={40}
          height={40}
        />
        <ShowIcon
          className="hide-button-in-modal"
          fill={theme.colorGrayDarker}
          width={40}
          height={40}
        />
      </React.Fragment>
    ) : (
      <ShowIcon fill={theme.colorGrayDarker} width={40} height={40} />
    )}
  </StyledButtonToggle>
);

ButtonToggle.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  inModal: PropTypes.bool
};

export default withTheme(ButtonToggle);
