import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';

const CloseBig = ({ fill, theme }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
    <path
      fill={fill ? fill : theme.colorGrayDarker}
      d="M25 26c-.3 0-.5-.1-.7-.3L13 14.4 1.7 25.7c-.4.4-1 .4-1.4 0-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7L11.6 13 .3 1.7C.1 1.5 0 1.3 0 1 0 .7.1.5.3.3c.4-.4 1-.4 1.4 0L13 11.6 24.3.3c.4-.4 1-.4 1.4 0 .2.2.3.4.3.7 0 .3-.1.5-.3.7L14.4 13l11.3 11.3c.2.2.3.5.3.7 0 .2-.1.5-.3.7-.2.2-.4.3-.7.3z"
    />
  </svg>
);

CloseBig.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withSvg(CloseBig);
