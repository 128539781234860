import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withTheme } from 'styled-components';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import InputFieldsWrapper from '../../../../common/Form/InputFieldsWrapper/InputFieldsWrapper.styled';
import InputField from '../../../../common/Form/InputField/InputField.container';
import { required, password } from '../../../../../config/validations';
import Button from '../../../../common/Button/Button.styled';

import StyledUpdatePasswordForm from './UpdatePasswordForm.styled';

class UpdatePasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updatePasswordMode: false
    };

    this.toggleUpdatePasswordMode = this.toggleUpdatePasswordMode.bind(this);
  }

  toggleUpdatePasswordMode() {
    this.setState(
      {
        updatePasswordMode: !this.state.updatePasswordMode
      },
      () => {
        if (this.state.updatePasswordMode)
          document.querySelector('[name=password]').focus();
      }
    );
  }

  render() {
    const { intl, allFieldsDisabled, theme } = this.props;

    return (
      <StyledUpdatePasswordForm>
        <InputFieldsWrapper>
          <Field
            component={InputField}
            name="password_initial"
            type="password"
            label={intl.formatMessage({ id: 'forms.currentPassword' })}
            autoComplete="none"
            disabled={true}
            placeholder={intl.formatMessage({
              id: 'forms.passwordPlaceholder'
            })}
          />

          {this.state.updatePasswordMode && (
            <Field
              component={InputField}
              name="password"
              type="password"
              label={intl.formatMessage({ id: 'forms.newPassword' })}
              validate={[required, password]}
              autoComplete="none"
              isBelow
              disabled={allFieldsDisabled}
            />
          )}

          <Button
            type="button"
            onClick={this.toggleUpdatePasswordMode}
            $color={
              this.state.updatePasswordMode
                ? theme.colorWhite
                : theme.colorTurquoise
            }
            className={this.state.updatePasswordMode && 'password-mode'}
            disabled={allFieldsDisabled}
          >
            {this.state.updatePasswordMode ? (
              <FormattedMessage id="forms.cancel" />
            ) : (
              <FormattedMessage id="forms.changePassword" />
            )}
          </Button>
        </InputFieldsWrapper>
      </StyledUpdatePasswordForm>
    );
  }
}

UpdatePasswordForm.propTypes = {
  intl: PropTypes.object,
  handleSubmit: PropTypes.func,
  allFieldsDisabled: PropTypes.bool,
  updatePasswordMode: PropTypes.bool,
  passwordToConfirm: PropTypes.string,
  theme: PropTypes.object
};

export default withTheme(injectIntl(UpdatePasswordForm));
