import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Form } from 'redux-form';
import Helmet from 'react-helmet';

import { View, Container, Row, Col, offset } from '../../../../../shared/components/Grid';
import SubmitErrors from '../../../common/SubmitErrors/SubmitErrors.component';
import StyledPurpleLink from '../../../common/PurpleLink/PurpleLink.styled';
import withLazyLoad from '../../../../../shared/hoc/withLazyLoad';
import Approvals from '../shared/Approvals/Approvals.component';
import Button from '../../../common/Button/Button.component';
import { useStoreState } from '../../../../../shared/hooks';
import { saveGuestUser } from '../User.actions';

import RegisterGuestForm from './RegisterGuestForm/RegisterGuestForm.container';
import StyledRegisterGuest from './RegisterGuest.styled';
import { localeDefault } from '../../../../config/register';

export const REGISTER_GUEST_FORM_NAME = 'registerGuestForm';

const RegisterGuestComponent = ({ handleSubmit }) => {
  const intl = useIntl();
  const {
    form: { registerGuestForm: registerForm = {} },
    user: { loading, profile }  
  } = useStoreState();

  return (
    <View>
      <Helmet title={intl.formatMessage({ id: `registerGuest.route.name` })} />
      <StyledRegisterGuest>
        <Container>
          <Row>
            <Col>
              <FormattedMessage tagName="h1" id="registerGuest.route.name" />
            </Col>
          </Row>
          
          <Row className="intro">
            <Col w={[6 / 6, 8 / 12, 6 / 12]} ml={offset([0, 2 / 12, 3 / 12])}>
              <FormattedMessage
                tagName="div"
                id="registerGuest.details.intro"
                values={{
                  link: (
                    <StyledPurpleLink
                      to={intl.formatMessage({
                        id: 'registerGuest.details.introLinkPath'
                      })}
                    >
                      <FormattedMessage
                        id="registerGuest.details.introLinkText"
                        tagName="p"
                      />
                    </StyledPurpleLink>
                  )
                }}
              />
            </Col>
          </Row>
  
          <Row>
            <Col w={[6 / 6, 8 / 12, 6 / 12]} ml={offset([0, 2 / 12, 3 / 12])}>
              <Form onSubmit={handleSubmit}>
                <SubmitErrors errors={profile.error} />
  
                <RegisterGuestForm allFieldsDisabled={loading} />
  
                <hr />
  
                <Approvals allFieldsDisabled={loading} onlyTos={true} />
  
                <Button
                  type="submit"
                  fullWidth
                  shadow={ false }
                  loading={loading}
                  disabled={typeof registerForm?.syncErrors === 'object' || loading}
                >
                  <FormattedMessage id="forms.createAccountCta" />
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </StyledRegisterGuest>
    </View>
  );
};

RegisterGuestComponent.propTypes = {
  handleSubmit: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    if (!data.locale) {
      data.locale = localeDefault;
    }

    dispatch(saveGuestUser(data));
  }
});

export default withLazyLoad(
  connect(undefined, mapDispatchToProps)(
    reduxForm({
      form: REGISTER_GUEST_FORM_NAME
    })(RegisterGuestComponent)
  )
);
