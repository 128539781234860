import styled from 'styled-components';

const StyledInputField = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;

  label {
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s top ease-out;
    z-index: 3;
    font-weight: 400;
    font-size: 12px;
    pointer-events: none;
    padding: 12px 17px;
    white-space: nowrap;
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    color: ${props => props.theme.colorGrayDark};
  }

  input,
  textarea {
    padding: 28px 16px 10px 17px;
    border: none;
    font-size: 16px;
    position: relative;
    width: 100%;
    z-index: 1;
    -webkit-appearance: none;
    border-radius: 0;

    ${props => props.$isBelow && 'border-top: solid 1px ' + props.theme.colorGray};
    ${props => props.$split && props.$splitRight && 'border-top: solid 1px ' + props.theme.colorGray};

    &,
    &:focus {
      ${props => props.$topNoRadius && `
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      `};
    }
  
    &:focus {
      z-index: 2;
      outline: none;
      box-shadow: inset 0 0 0 1px ${props => props.theme.colorPurple};
      border-radius: ${props => (props.$isSingle ? 4 : 2)}px;
    }

    &::placeholder {
      opacity: 0.6;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #f6edfc inset;
    }

    ${props => props.$bordered && 
      `
      box-shadow: inset 0 0 0 1px ${props.theme.colorGray};
      ${props.$error && 'box-shadow: inset 0 0 0 2px ' + props.theme.colorRed};
      border-radius: ${props.$isSingle ? 4 : 2}px;

      &:focus {
        box-shadow: inset 0 0 0 2px ${props => props.theme.colorPurple};
      }
    `};

    &:disabled {
      background: ${props => props.theme.colorGrayLighter};
      color: ${props => props.theme.colorGray};
      cursor: default;
      pointer-events: none;

      label {
        color: ${props => props.theme.colorGray};
      }

      :-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px ${props => props.theme.colorGrayLighter} inset;
      }
    }

    ${({ $removeNativeUi }) => $removeNativeUi && `
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance: textfield;
      }
    `}
  }

  textarea {
    vertical-align: top;
    resize: none;
  }

  &.big-label input:not(:-webkit-autofill:focus) + label,
  &.big-label textarea:not(:-webkit-autofill:focus) + label {
    font-size: 14px;
    color: black;
    top: 7px;
  }

  label.error {
    color: ${props => props.theme.colorRed};
  }

  .icon-container {
    display: flex;
    position: absolute;
    z-index: 10;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 24px;
    }
  }

  ${props => props.theme.media.sm`
    ${props => props.$split && `width: 50%;`};
    ${props => props.$splitOneThird && `width: 33.333%;`};
    ${props => props.$splitTwoThirds && `width: 66.666%;`};

    input,
    textarea {
      font-size: 14px;
      ${props => props.$split 
        && props.$splitRight 
        && !props.$isBelow 
        && 'border-top: unset'
      };

      ${props =>
        props.$split &&
        props.$splitLeft &&
        'border-right: solid 1px ' + props.theme.colorGray};
    }
  `};
`;

export const StyledPasswordReveal = styled.div`
  position: absolute;
  top: 14px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
  z-index: 4;
  cursor: pointer;
  user-select: none;
  opacity: 0.5;
`;

export default StyledInputField;
