import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { showConfirm } from '../../../../../shared/components/common/Dialogs/Dialogs.actions';
import { toggleModal } from '../../LocationSelector/LocationSelector.actions';
import { useStoreState } from '../../../../../shared/hooks';
import PinIcon from '../../../../assets/icons/pin';

import StyledHeaderLocation from './HeaderLocation.styled';

const HeaderLocationComponent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    basket,
    categories: {
      selected: category,
    },
    locations: {
      selected: location,
    }
  } = useStoreState();

  const openLocationSelector = useCallback((newCategory, confirm = false) => {
    const toggleAction = toggleModal({
      appendPath: newCategory.id ? `/${newCategory.slug}` : ''
    });
    
    if (confirm) {
      dispatch(
        showConfirm({
          acceptAction: toggleAction,
          content: intl.formatMessage({ id: `basket.confirm_reset_location` })
        })
      );
    } else {
      dispatch(toggleAction);
    }
  }, [ intl, dispatch ]);

  const onClick = useCallback(() => {
    openLocationSelector(category, !!basket.items.length);
  }, [ category, basket, openLocationSelector ]);

  return (
    <StyledHeaderLocation
      onClick={onClick}
      title={location.id ? location.name : null}
    >
      <PinIcon />
      {location.id ? location.name : null}
    </StyledHeaderLocation>
  );
};

export default HeaderLocationComponent;
