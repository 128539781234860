import styled from 'styled-components';

const StyledFooter = styled.footer`
  background: ${props => props.theme.colorEggplant};
  padding: 40px 0;
  font-size: 18px;
  color: ${props => props.theme.colorGray};

  .text-center {
    text-align: center;
    ${props => props.theme.media.md`
      text-align: left;
    `};
  }

  ${props => props.theme.media.lg`
    padding: 80px 0;
  `};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .logo {
    vertical-align: top;
    color: ${props => props.theme.colorWhite};
    width: 59px;
    height: 24px;
    margin: 0 0 24px;
  }

  .links ul {
    ${props => props.theme.media.md`
      margin-bottom: 40px;
    `};
  }

  a {
    display: block;
    text-decoration: none;
    padding: 4px 0;
    word-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.colorWhite};

    &:hover,
    &:focus {
      color: ${props => props.theme.colorGray};
    }
  }

  .footer__hygene {
    margin-top: 80px;
    text-align: center;

    ${props => props.theme.media.md`
      text-align: left;
      margin-top: 40px;
    `};

    ul li {
      display: inline-block;
      margin-right: 16px;
      color: ${props => props.theme.colorWhite};
    }
  }

  .links .footer-title {
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colorWhite};
    opacity: 0.64;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    margin-top: 64px;
    margin-bottom: 24px;

    ${props => props.theme.media.md`
      margin-top: 0;
    `};
  }

  .links li {
    margin-bottom: 8px;
  }

  .links-col {
    word-wrap: break-word;
  }
`;

export default StyledFooter;
