import React from 'react';
import PropTypes from 'prop-types';

import { formatMobilePhone } from '../../../../../../../shared/components/common/Form/Form.formatting';
import Remove from '../../../../../../assets/icons/remove';

import StyledListCoBorrowers from './ListCoBorrowers.styled';

class CoBorrowers extends React.Component {

  renderChildren() {
    const { profile, allFieldsDisabled, removeUserLinkById } = this.props;

    return profile.children.map((child, index) => {
      const attr = child.attributes;

      return (
        <tr key={index}>
          <td>{`${attr.firstName} ${attr.lastName}`}</td>
          <td>{`${formatMobilePhone(attr.mobilePhone)}`}</td>
          <td>
            {!allFieldsDisabled && (
              <button
                onClick={removeUserLinkById}
                data-userlinkid={child.id}
                type="button"
              >
                <Remove />
              </button>
            )}
          </td>
        </tr>
      );
    });
  }

  render() {
    if (!this.props.profile.children || !this.props.profile.children.length)
      return null;

    return (
      <StyledListCoBorrowers>
        <table>
          <thead>
            <tr>
              <td>Navn</td>
              <td>Telefon</td>
              <td></td>
            </tr>
          </thead>
          <tbody>{this.renderChildren()}</tbody>
        </table>
      </StyledListCoBorrowers>
    );
  }
}

CoBorrowers.propTypes = {
  profile: PropTypes.object,
  removeUserLinkById: PropTypes.func,
  allFieldsDisabled: PropTypes.bool
};

export default CoBorrowers;
