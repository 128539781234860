import ReactGA from 'react-ga';

export let track = {
  currentPage: '',
  hasInit: false,
  page: nextPage => {
    ReactGA.pageview(nextPage);
  },
  modal: modalName => {
    ReactGA.modalview(modalName);
  },
  event: args => {
    ReactGA.event(args);
  }
};

export default track;
