import styled from 'styled-components';
import DatePickerStyle from 'react-datepicker/dist/react-datepicker.css';

const StyledDatePicker = styled.span`
  ${DatePickerStyle.toString()};

  .react-datepicker {
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
    border: 0;
    padding: 18px;
  }

  .react-datepicker__portal {
    background: rgba(38, 47, 67, 0.4);
  }
  
  .react-datepicker__portal .react-datepicker {
    padding: 18px;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
  
  .react-datepicker-popper {
    z-index: 5;

    &[data-placement^='bottom'] {
      margin-top: 5px;
      margin-left: 10px;
    }
  }

  .react-datepicker__year-select {
    font-size: 16px;
  }

  .react-datepicker__year-read-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;

    &--down-arrow {
      margin-left: 5px;
      top: 1px;
      display: inline-block;
      transform: scale(0.8, 0.75) rotate(135deg);
    }

    &--selected-year {
      font-size: 12px;
      font-family: ${props => props.theme.fonts.regular};
    }
  }

  .react-datepicker__year-dropdown {
    background: ${props => props.theme.colorWhite};
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    max-height: 200px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .react-datepicker__year-option {
      line-height: 25px;
      font-size: 12px;

      &:hover {
        background: ${props => props.theme.colorTurquoise};
        color: ${props => props.theme.colorWhite};
      }

      &--selected_year {
        border-radius: 0;
        background: ${props => props.theme.colorGreen};
        color: ${props => props.theme.colorWhite};
      }

      &--selected {
        display: none;
      }
    }
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle {
    border-bottom-color: ${props => props.theme.colorWhite};
    position: 1px;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: rgba(0, 0, 0, 0.07);
  }

  .react-datepicker__header {
    background: unset;
    border: unset;
    padding: 0;
    margin-bottom: 12px;

    .react-datepicker__current-month {
      color: ${props => props.theme.colorGrayDarker};
      font-size: 18px;
      text-align: center;
      margin-bottom: 2px;
      caption-side: top;
    }
  }

  .react-datepicker__portal .react-datepicker__navigation {
    top: 8px;
    margin: 18px;
    border-width: 1.41em;

    &.react-datepicker__navigation--previous {
      left: 0;
    }

    &.react-datepicker__navigation--next {
      right: 0;
    }
  }

  .react-datepicker__month {
    display: table;
    border-collapse: collapse;
  }

  .react-datepicker__week {
    display: table-row;
  }

  .react-datepicker__day--selected {
    background: #00a984;
    color: white;
    border: 1px double #00a984;
  }

  .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__day {
    display: table-cell;
    vertical-align: middle;
    width: 39px;
    height: 33px;
    font-size: 13px;
    color: ${props => props.theme.colorGrayDarker};
    background: ${props => props.theme.colorWhite};
    border: 1px solid ${props => props.theme.colorGrayLight};
    border-radius: 0 !important;
    cursor: default;
    margin: 0;

    &:hover {
      background: ${props => props.theme.colorGreenLight};
      color: ${props => props.theme.colorWhite};
    }

    &--selected {
      background: ${props => props.theme.colorGreen};
      color: ${props => props.theme.colorWhite};
    }

    &--disabled {
      color: ${props => props.theme.colorGrayLight};
      pointer-events: none;
    }
  }

  .react-datepicker__day--outside-month,
  .react-datepicker__portal .react-datepicker__day--outside-month {
    pointer-events: none;
    opacity: 0;
    border: 0;
  }

  .react-datepicker__day-names {
    margin-top: 20px;
  }

  .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day-name {
    color: #a3abbd;
    width: 39px;
    font-size: 10px;
    margin: 0;
    vertical-align: middle;
    line-height: unset;
  }

  ${props => props.theme.media.sm`
    .react-datepicker__year-select {
      font-size: 14px;
    }
  `};
`;

export default StyledDatePicker;
