import styled from 'styled-components';

const StyledMap = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.$fullscreen
    ? 'calc(100vh - ' + props.theme.headerMobileHeight + 'px)'
    : 'calc(100vh - ' + props.theme.modalTitleMobileHeight + 'px - 65px)'
  };

  .loading-locations {
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;

    &__backdrop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${props => props.theme.colorGrayDarker};
      opacity: 0.4;
    }

    div:nth-child(2) {
      background-color: ${props => props.theme.colorWhite};
      border-radius: 2px;
      padding: 30px;
      z-index: 1;
    }
  }

  ${props => props.theme.media.sm`
    height: ${props => props.$fullscreen
      ? `calc(100vh - ${props.theme.headerMobileHeight}px)`
      : '460px'};
    margin-left: ${props => props.$fullscreen && props.$sidebarActive
      ? `${props.theme.locationSidebarWidth}px`
      : '0'};
  `};

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export default StyledMap;
