import { reducer as formReducer } from 'redux-form';

import { LOGIN_SUCCESS } from '../../../../shared/components/Login/Login.actions';

const reducer = formReducer.plugin({
  loginForm: (state, action) => {
    switch (action.type) {
      case LOGIN_SUCCESS:
        return undefined;
        
      default:
        return state;
    }
  }
});

reducer.reducer = 'form';

export default reducer;
