import styled from 'styled-components';

const StyledMapMarker = styled.div`
  width: 260px;
  height: auto;
  display: inline-block;
  left: -130px;
  bottom: 24px;
  position: absolute;
  z-index: 2;
  text-align: center;
  pointer-events: none;

  .content {
    display: inline-block;
    margin: auto;
    background: ${props => props.theme.colorGrayDarker};
    color: ${props => props.theme.colorWhite};
    font-family: '${props => props.theme.fonts.regular}';
    font-size: 12px;
    padding: 8px;
    border-radius: 4px;

    p {
      margin-top: 5px;
    }

    &::after {
      position: absolute;
      bottom: -6px;
      left: 50%;
      margin-left: -8px;
      width: 0;
      border-top: 6px solid ${props => props.theme.colorGrayDarker};
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      content: " ";
      font-size: 0;
      line-height: 0;
    }
  }

`;

export default StyledMapMarker;
