import { connect } from 'react-redux';

import { addBasketItem, removeBasketItem } from '../../../common/Basket/Basket.actions';
import { getPreparedBasket } from '../../../common/Basket/Basket.selectors';

import EquipmentListComponent from './EquipmentList.component';

const mapStateToProps = state => ({
  basket: getPreparedBasket(state)
});

const mapDispatchToProps = dispatch => ({
  removeItem(id) {
    dispatch(removeBasketItem(id));
  },
  updateQuantity(variant, quantity) {
    dispatch(addBasketItem({ variant, quantity }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentListComponent);
