export const MAP_LOADED = '@@map/MAP_LOADED';
export const MAP_UPDATE_CENTER = '@@map/MAP_UPDATE_CENTER';
export const MAP_UPDATE_ZOOM = '@@map/MAP_UPDATE_ZOOM';
export const MAP_PROPS_UPDATED = '@@map/MAP_PROPS_UPDATED';
export const MAP_RESET = '@@map/MAP_RESET';

export function mapLoaded() {
  return {
    type: MAP_LOADED
  };
}

export function mapUpdateCenter(location, resetLocation = false) {
  return {
    type: MAP_UPDATE_CENTER,
    data: location,
    resetLocation: resetLocation
  };
}

export function mapUpdateZoom(level) {
  return {
    type: MAP_UPDATE_ZOOM,
    data: level
  };
}

export function mapPropsUpdated(props) {
  return {
    type: MAP_PROPS_UPDATED,
    data: props
  };
}

export function resetMap() {
  return {
    type: MAP_RESET
  };
}
