import React from 'react';
import PropTypes from 'prop-types';

import StyledImageBounds from './ImageBounds.styled';

const ImageBounds = ({ children, height, heightRatio, faded, ...props }) => (
  <StyledImageBounds 
    {...props} 
    $height={ height } 
    $heightRatio={ heightRatio } 
    $faded={ faded }
  >
    <span>{children}</span>
  </StyledImageBounds>
);

ImageBounds.propTypes = {
  height: PropTypes.string,
  heightRatio: PropTypes.number,
  faded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ])
};

export default ImageBounds;
