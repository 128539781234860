import { DELETE_USER_SUCCESS as DELETE_USER_SUCCESS_VALUE } from '../../../../shared/config/users';

export const FETCH_USER = '@@user/FETCH_USER';
export const FETCH_USER_SUCCESS = '@@user/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = '@@user/FETCH_USER_ERROR';
export const CANCEL_FETCH_USER = '@@user/CANCEL_FETCH_USER';

export const SAVE_USER = '@@user/SAVE_USER';
export const SAVE_USER_SUCCESS = '@@user/SAVE_USER_SUCCESS';
export const SAVE_USER_ERROR = '@@user/SAVE_USER_ERROR';
export const CANCEL_SAVE_USER = '@@user/CANCEL_SAVE_USER';

export const SAVE_USER_WITH_VERIFY = '@@user/SAVE_USER_WITH_VERIFY';
export const SAVE_USER_WITH_SEND_CODE = '@@user/SAVE_USER_WITH_SEND_CODE';

export const SAVE_GUEST_USER = '@@user/SAVE_GUEST_USER';
export const SAVE_GUEST_USER_SUCCESS = '@@user/SAVE_GUEST_USER_SUCCESS';
export const SAVE_GUEST_USER_ERROR = '@@user/SAVE_GUEST_USER_ERROR';
export const CANCEL_SAVE_GUEST_USER = '@@user/CANCEL_SAVE_GUEST_USER';

export const FORGOT_PASSWORD = '@@user/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = '@@user/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = '@@user/FORGOT_PASSWORD_ERROR';
export const CANCEL_FORGOT_PASSWORD = '@@user/CANCEL_FORGOT_PASSWORD';
export const RESET_FORGOT_PASSWORD = '@@user/RESET_FORGOT_PASSWORD';

export const REMOVE_USERLINK = '@@user/REMOVE_USERLINK';
export const REMOVE_USERLINK_SUCCESS = '@@user/REMOVE_USERLINK_SUCCESS';
export const REMOVE_USERLINK_ERROR = '@@user/REMOVE_USERLINK_ERROR';
export const CANCEL_REMOVE_USERLINK = '@@user/CANCEL_REMOVE_USERLINK';

export const DELETE_USER = '@@user/DELETE_USER';
export const DELETE_USER_SUCCESS = DELETE_USER_SUCCESS_VALUE;
export const DELETE_USER_ERROR = '@@user/DELETE_USER_ERROR';
export const CANCEL_DELETE_USER = '@@user/CANCEL_DELETE_USER';

export const saveUser = payload => ({
  type: SAVE_USER,
  payload
});

export const saveUserWithVerify = payload => ({
  type: SAVE_USER_WITH_VERIFY,
  payload
});

export const saveUserWithSendCode = (payload, codeType, skipNotification) => ({
  type: SAVE_USER_WITH_SEND_CODE,
  payload,
  codeType,
  skipNotification
});

export const saveUserSuccess = payload => ({
  type: SAVE_USER_SUCCESS,
  payload
});

export const saveUserError = err => ({
  type: SAVE_USER_ERROR,
  payload: err
});

export const saveGuestUser = payload => ({
  type: SAVE_GUEST_USER,
  payload
});

export const saveGuestUserSuccess = payload => ({
  type: SAVE_GUEST_USER_SUCCESS,
  payload
});

export const saveGuestUserError = err => ({
  type: SAVE_GUEST_USER_ERROR,
  payload: err
});

export const forgotPassword = payload => ({
  type: FORGOT_PASSWORD,
  payload
});

export const forgotPasswordSuccess = payload => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload
});

export const removeUserLink = userLinkId => ({
  type: REMOVE_USERLINK,
  userLinkId: userLinkId
});

export const removeUserLinkSuccess = id => ({
  type: REMOVE_USERLINK_SUCCESS,
  id: id
});

export const removeUserLinkError = err => ({
  type: REMOVE_USERLINK_ERROR,
  payload: err
});

export const deleteUser = () => ({
  type: DELETE_USER
});

export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS
});

export const deleteUserError = err => ({
  type: DELETE_USER_ERROR,
  payload: err
});

export const forgotPasswordError = err => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: err
});

export const resetForgotPassword = () => {
  return {
    type: RESET_FORGOT_PASSWORD
  };
};
