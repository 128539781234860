import styled from 'styled-components';

const StyledLegend = styled.li`
  position: relative;
  display: flex;
  font-size: 12px;
  align-items: center;
  transition: background-color 250ms ease;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    margin-right: 8px;
  }

  .switcher {
    padding: 8px 40px 8px 16px;
    width: 100%;
    cursor: pointer;

    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .expand-icon {
      position: absolute;
      right: 8px;
      top: 11px;

      svg {
        width: 12px;
        height: 8px;
        transition: transform 0.3s;
        transform: rotate(0deg);
      }
    }

    input {
      display: none;

      + span {
        display: none;
      }

      &:checked + span {
        display: block;
      }

      &:checked ~ .expand-icon svg {
        transform: rotate(180deg);
      }
    }

    .title {
      font-size: 15px;
      line-height: 1.33;
      color: ${props => props.theme.colorGrayDarker};
    }

    .text {
      font-size: 13px;
      line-height: 1.46;
      color: ${props => props.theme.colorEggplant};
      padding-left: 30px;
      margin-top: 4px;
    }
  }

  ${props => props.theme.media.xs`
    .header svg {
      width: 20px;
    }

    .switcher {
      padding: 10px 24px !important;
      cursor: default !important;
    }

    .expand-icon {
      display: none !important;
    }

    .title {
      cursor: default;
      font-size: 18px !important;
      font-family: ${props => props.theme.fonts.medium};
    }

    .text {
      display: block !important;
      font-size: 15px !important;
      line-height: 1.53 !important;
    }

    &:hover {
      background: none !important;
    }
  `}
`;

export default StyledLegend;
