import Promise from 'pinkie-promise';
import { toast } from 'react-toastify';

import { createLogicWithApi } from '../../../../../../shared/logicCreators';
import { loans as apiConfig } from '../../../../../config/api';
import {
  FETCH_LOAN,
  CANCEL_FETCH_LOAN,
  fetchLoan,
  fetchLoanError,
  fetchLoanSuccess,
  FETCH_LOANS,
  CANCEL_FETCH_LOANS,
  fetchLoansSuccess,
  fetchLoanItemsSuccess,
  fetchLoansError,
  PICK_UP_LOAN,
  CANCEL_PICK_UP_LOAN,
  pickupLoanError,
  RETURN_LOAN,
  CANCEL_RETURN_LOAN,
  returnLoanError
} from '../../../../../../shared/components/Loans/Loans.actions';

const fetchLoansLogic = createLogicWithApi({
  type: FETCH_LOANS,
  cancelType: CANCEL_FETCH_LOANS,
  latest: true,
  process: ({ Api }, dispatch, done) => {
    return Api({
      method: 'GET',
      path: `${apiConfig.path}`,
      handle: r =>
        r.query({
          limit: 0,
          order: {
            field: 'returnAt',
            dir: 'desc'
          }
        })
    })
      .then(resp => {
        dispatch(fetchLoansSuccess(resp));
        done();
      })
      .then(err => {
        dispatch(fetchLoansError(err));
        done();
      });
  }
});

const fetchLoanLogic = createLogicWithApi({
  type: FETCH_LOAN,
  cancelType: CANCEL_FETCH_LOAN,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    const loan = Api({
      method: 'GET',
      path: `${apiConfig.path}/${action.id}`
    });

    const items = Api({
      method: 'GET',
      path: `${apiConfig.path}/${action.id}${apiConfig.itemsPath}`,
      params: {
        limit: 0,
        filters: {
          state: 'published'
        }
      }
    });

    return Promise.all([loan, items])
      .then(resp => {
        dispatch(fetchLoanSuccess(resp[0]));
        dispatch(fetchLoanItemsSuccess(resp[1]));
        done();
      })
      .catch(err => {
        dispatch(fetchLoanError(err));
        done();
      });
  }
});

const pickupLoanLogic = createLogicWithApi({
  type: PICK_UP_LOAN,
  cancelType: CANCEL_PICK_UP_LOAN,
  latest: true,
  process: ({ Api, action: { id }, state: { locale } }, dispatch, done) => {
    return Api({
      method: 'POST',
      path: `${apiConfig.path}/${id}${apiConfig.pickupPath}`
    })
      .then(() => {
        dispatch(fetchLoan({ id }));
        toast.success(locale.archive.details.loanPickedUp);
        done();
      })
      .catch(err => {
        dispatch(pickupLoanError(err));
        done();
      });
  }
});

const returnLoanLogic = createLogicWithApi({
  type: RETURN_LOAN,
  cancelType: CANCEL_RETURN_LOAN,
  latest: true,
  process: ({ Api, action: { id }, state: { locale } }, dispatch, done) => {
    return Api({
      method: 'POST',
      path: `${apiConfig.path}/${id}${apiConfig.returnPath}`
    })
      .then(() => {
        dispatch(fetchLoan({ id }));
        toast.success(locale.archive.details.loanReturned);
        done();
      })
      .catch(err => {
        dispatch(returnLoanError(err));
        done();
      });
  }
});

const logics = [
  fetchLoansLogic,
  fetchLoanLogic,
  pickupLoanLogic,
  returnLoanLogic
];

export default logics;