import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from '@lagunovsky/redux-react-router';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import LocationsOpeningHours from '../../../../../shared/components/Locations/LocationsOpeningHours/LocationsOpeningHours.component';
import { unsetMarkerLocation as unsetMarkerLocationAction } from '../../../common/LocationSelector/LocationSelector.actions';
import { getMarkerLocationWithSortedImages } from '../../../../../shared/components/Locations/Locations.selectors';
import LoadingAnimation from '../../../../../shared/components/common/LoadingAnimation/LoadingAnimation.component';
import CloseBig from '../../../../assets/icons/close-big';

import DetailsGoToLocation from './DetailsGoToLocation/DetailsGoToLocation.component';
import DetailsContact from './DetailsContact/DetailsContact.component';
import DetailsAbout from './DetailsAbout/DetailsAbout.component';
import DetailsImage from './DetailsImage/DetailsImage.component';
import DetailsBlock from './DetailsBlock/DetailsBlock.styled';
import StyledSidebar from './Sidebar.styled';
import { useTheme } from 'styled-components';

const SidebarComponent = ({ loading }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const intl = useIntl();
  const selected = useSelector(getMarkerLocationWithSortedImages);

  const unsetMarkerLocation = useCallback(() => {
    dispatch(unsetMarkerLocationAction());
    dispatch(
      push(
        `${intl.formatMessage({ id: `locations.route.path` })}${
          window.location.search
        }`
      )
    );
  }, [ intl, dispatch ]);

  if (!selected?.id) {
    return null;
  }

  return (
    <StyledSidebar
      $loading={loading}
      className={loading ? 'content-loading' : 'content-loaded'}
    >
      {selected.loading || !selected.id ? (
        <LoadingAnimation info="locations.details.loading" />

      ) : (
        <div>
          <DetailsImage
            images={selected.images || null}
            title={selected.name}
          />

          {selected.type !== 'mapEntry' && (
            <DetailsGoToLocation slug={selected.slug} />
          )}
          <DetailsAbout shortDescription={selected.shortDescription} />

          <DetailsBlock>
            <LocationsOpeningHours item={selected} />
          </DetailsBlock>

          <DetailsContact selected={selected} />
        </div>
      )}

      {!loading && selected.id ? (
        <div className="unset-marker-location" onClick={unsetMarkerLocation}>
          <CloseBig fill={theme.colorWhite} />
        </div>

      ) : (
        null
      )}
    </StyledSidebar>
  );
};

SidebarComponent.propTypes = {
  items: PropTypes.array,
  getLocations: PropTypes.func,
  getLocation: PropTypes.func,
  loading: PropTypes.bool,
};

export default SidebarComponent;
