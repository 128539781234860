import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';

const Basket = ({ theme, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 29 29"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-246.000000, -53.000000) translate(247.000000, 54.000000)">
          <path
            stroke={fill ? 'none' : theme.colorGrayDarker}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12.08 23H2.947c-1.902 0-3.2-1.574-2.905-3.486L1.954 7.136c.077-.497.505-.863 1.008-.863h16.355L20 10.866M7.135 9.409V3.421C7.135 1.53 8.735 0 10.702 0c1.97 0 3.568 1.524 3.568 3.421V9.41"
          />
        </g>
      </g>
    </g>
  </svg>
);

Basket.propTypes = {
  theme: PropTypes.object.isRequired,
  fill: PropTypes.string
};

export default withSvg(Basket);
