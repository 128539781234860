import React, { useCallback, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import LoadingAnimation from '../../../../shared/components/common/LoadingAnimation/LoadingAnimation.component';
import { LOCATION_IN_CENTER, google_maps_defaults } from '../../../config/locations';
import { unsetMarkerLocation } from '../LocationSelector/LocationSelector.actions';
import { google_maps_config } from '../../../config/locations';
import { useStoreState } from '../../../../shared/hooks';

import { mapLoaded, mapUpdateCenter, mapUpdateZoom } from './Map.actions';
import { setZoomFitClosest } from './Map.helpers';
import { getMapBounds } from './Map.selectors';
import { resetMap } from './Map.actions';
import StyledMap from './Map.styled';

const MapComponent = ({ 
  mapCenter, 
  mapZoom, 
  fullscreen, 
  sidebarActive, 
  onChildClick, 
  onChange,
  children, 
  ...props 
}) => {
  const dispatch = useDispatch();
  const { 
    locations: { 
      items,
      meta: { 
        loading 
      } = {}
    } = {},
    map
  } = useStoreState();

  const [ bounds, setBounds ] = useState();
  const [ isMapLoaded, setIsMapLoaded ] = useState();
  const [ currentPosition, setCurrentPosition ] = useState();
  const [ currentPositionLoading, setCurrentPositionLoading ] = useState(false);

  const windowResizeHandler = useCallback(() => {
    if (fullscreen) {
      setBounds(getMapBounds({
        width: window.innerWidth,
        height: window.innerHeight
      }));

    } else {
      setBounds(getMapBounds({ 
        width: 500, 
        height: 500 
      }));
    }
  }, [ fullscreen ]);

  const googleApiLoadedHandler = useCallback(() => {
    dispatch(mapLoaded());
    setIsMapLoaded(true);
  }, [ dispatch ]);

  const resetLocationMarker = useCallback(() => {
    dispatch(unsetMarkerLocation());
    dispatch(resetMap());
  }, [ dispatch ]);

  const askCurrentPosition = useCallback(async () => {
    setCurrentPositionLoading(true);

    const result = await navigator.permissions.query({ name: 'geolocation' });

    if (result.state === 'prompt') {
      setCurrentPositionLoading(false);

      result.addEventListener('change', (evt) => {
        if (evt.target.state === 'granted') {
          setCurrentPositionLoading(true);
        }
      });
    }

    navigator.geolocation.getCurrentPosition(
      async position => {
        setCurrentPositionLoading(false);
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      },
      err => {
        setCurrentPositionLoading(false);

        console.warn(`ERROR(${err.code}): ${err.message}`);
      }
    )
}, []);

  useEffect(() => {
    if (navigator?.geolocation) {
      askCurrentPosition();
    }
  }, [ askCurrentPosition ]);

  useEffect(() => {
    if (!isMapLoaded || !items?.length || !currentPosition) {
      return;
    }

    (async () => {
      const { center, zoom } = await setZoomFitClosest(items, currentPosition);

      if (zoom === undefined || center === undefined) {
        return;
      }
  
      dispatch(mapUpdateCenter(LOCATION_IN_CENTER ? currentPosition : center));
      dispatch(mapUpdateZoom(zoom));
    })();
  }, [ items, isMapLoaded, currentPosition, dispatch ]);

  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler, false);
    windowResizeHandler();

    return () => {
      window.removeEventListener('resize', windowResizeHandler, false);
    }
  }, [ windowResizeHandler ]);

  useEffect(() => () => {
    resetLocationMarker();
  }, [ resetLocationMarker ]);

  if (!bounds) {
    return null;
  }

  return (
    <StyledMap
      {...props}
      $fullscreen={ fullscreen } 
      $sidebarActive={ sidebarActive } 
    >
      <div id="gmap">
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={google_maps_config}
          center={mapCenter ? mapCenter : map.mapCenter}
          zoom={mapZoom ? mapZoom : map.mapZoom}
          defaultCenter={bounds.center}
          defaultZoom={bounds.zoom}
          options={google_maps_defaults}
          onGoogleApiLoaded={googleApiLoadedHandler}
          onChildClick={onChildClick}
          onChange={onChange}
        >
          {children}
        </GoogleMapReact>
      </div>
  
      {(!!loading || !!currentPositionLoading) && (
        <div className="loading-locations">
          <div className="loading-locations__backdrop" />
          <LoadingAnimation info="locations.details.preLoaderInfo" />
        </div>
      )}
    </StyledMap>
  );
};

MapComponent.propTypes = {
  fullscreen: PropTypes.bool,
  sidebarActive: PropTypes.bool,
  onChildClick: PropTypes.func,
  onChange: PropTypes.func,
  mapZoom: PropTypes.number,
  mapCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
};

export default MapComponent;
