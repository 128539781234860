import React from 'react';
import PropTypes from 'prop-types';

const PhoneFormatter = ({ phoneNumber }) => (
  <span>
    {phoneNumber
      ? `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
          3,
          5
        )} ${phoneNumber.slice(5)}`
      : null}
  </span>
);

PhoneFormatter.propTypes = {
  phoneNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default PhoneFormatter;
