import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Translator = ({ children }) => <span>{children}</span>;

Translator.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
};

const mapStateToProps = ({ locale }, { path, children }) => {
  const pathObj =
      locale && path ? path.split('.').reduce((a, b) => a[b], locale) : null,
    childrenPath = children && children.split('.').filter(str => str !== ''),
    translation =
      Array.isArray(childrenPath) &&
      childrenPath.reduce((a, b) => a[b], pathObj);
  return {
    children:
      pathObj && translation
        ? translation
        : childrenPath && childrenPath.length > 1
        ? childrenPath[childrenPath.length - 1]
        : children
  };
};

export default connect(mapStateToProps)(Translator);
