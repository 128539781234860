export const TOGGLE_BASKET = '@@basket/TOGGLE_BASKET';

export const RESET_BASKET = '@@basket/RESET_BASKET';
export const RESET_BASKET_DATES = '@@basket/RESET_BASKET_DATES';

export const SET_BASKET_INFO = '@@basket/SET_BASKET_INFO';

export const CHECK_BASKET_INFO = '@@basket/CHECK_BASKET_INFO';

export const FETCH_BASKET = '@@basket/FETCH_BASKET';
export const CANCEL_FETCH_BASKET = '@@basket/CANCEL_FETCH_BASKET';
export const FETCH_BASKET_SUCCESS = '@@basket/FETCH_BASKET_SUCCESS';
export const FETCH_BASKET_ERROR = '@@basket/FETCH_BASKET_ERROR';

export const SAVE_BASKET_SUCCESS = '@@basket/SAVE_BASKET_SUCCESS';
export const SAVE_BASKET_ERROR = '@@basket/SAVE_BASKET_ERROR';

export const ADD_BASKET_ITEM = '@@basket/ADD_BASKET_ITEM';
export const CANCEL_ADD_BASKET_ITEM = '@@basket/CANCEL_ADD_BASKET_ITEM';
export const ADD_BASKET_ITEM_SUCCESS = '@@basket/ADD_BASKET_ITEM_SUCCESS';
export const ADD_BASKET_ITEM_ERROR = '@@basket/ADD_BASKET_ITEM_ERROR';

export const REMOVE_BASKET_ITEM = '@@basket/REMOVE_BASKET_ITEM';
export const CANCEL_REMOVE_BASKET_ITEM = '@@basket/CANCEL_REMOVE_BASKET_ITEM';
export const REMOVE_BASKET_ITEM_SUCCESS = '@@basket/REMOVE_BASKET_ITEM_SUCCESS';
export const REMOVE_BASKET_ITEM_ERROR = '@@basket/REMOVE_BASKET_ITEM_ERROR';

export const CONVERT_BASKET = '@@basket/CONVERT_BASKET';
export const CANCEL_CONVERT_BASKET = '@@basket/CANCEL_CONVERT_BASKET';
export const CONVERT_BASKET_SUCCESS = '@@basket/CONVERT_BASKET_SUCCESS';
export const CONVERT_BASKET_ERROR = '@@basket/CONVERT_BASKET_ERROR';

export const toggleBasket = open => ({
  type: TOGGLE_BASKET,
  open
});

export const resetBasket = payload => ({
  type: RESET_BASKET,
  payload
});

export const resetBasketDates = payload => ({
  type: RESET_BASKET_DATES,
  payload
});

export const checkBasketInfo = payload => ({
  type: CHECK_BASKET_INFO,
  payload
});

export const setBasketInfo = payload => ({
  type: SET_BASKET_INFO,
  payload
});

export const saveBasketSuccess = payload => ({
  type: SAVE_BASKET_SUCCESS,
  payload
});

export const saveBasketError = payload => ({
  type: SAVE_BASKET_ERROR,
  payload
});

export const fetchBasket = id => ({
  type: FETCH_BASKET,
  id
});

export const fetchBasketSuccess = payload => ({
  type: FETCH_BASKET_SUCCESS,
  payload
});

export const fetchBasketError = payload => ({
  type: FETCH_BASKET_ERROR,
  payload
});

export const addBasketItem = (payload, showBasket) => ({
  type: ADD_BASKET_ITEM,
  payload,
  showBasket
});

export const addBasketItemSuccess = payload => ({
  type: ADD_BASKET_ITEM_SUCCESS,
  payload
});

export const addBasketItemError = payload => ({
  type: ADD_BASKET_ITEM_ERROR,
  payload
});

export const removeBasketItem = id => ({
  type: REMOVE_BASKET_ITEM,
  id
});

export const removeBasketItemSuccess = id => ({
  type: REMOVE_BASKET_ITEM_SUCCESS,
  id
});

export const removeBasketItemError = payload => ({
  type: REMOVE_BASKET_ITEM_ERROR,
  payload
});

export const convertBasket = (id, message, pickup) => ({
  type: CONVERT_BASKET,
  id,
  message,
  pickup
});

export const convertBasketSuccess = payload => ({
  type: CONVERT_BASKET_SUCCESS,
  payload
});

export const convertBasketError = payload => ({
  type: CONVERT_BASKET_ERROR,
  payload
});
