import { push } from '@lagunovsky/redux-react-router';

import { showNotification } from '../../../../shared/components/common/Dialogs/Dialogs.actions';
import { fetchLoginInfo } from '../../../../shared/components/Login/Login.actions';
import { vipps } from '../../../../shared/config/api';
import {
  createLogicWithApi,
  createLogicWithRouteChecks
} from '../../../../shared/logicCreators';
import {
  VIPPS_VERIFY,
  CANCEL_VIPPS_VERIFY,
  VIPPS_SEND_CODE,
  CANCEL_VIPPS_SEND_CODE,
  VIPPS_VERIFY_CODE,
  CANCEL_VIPPS_VERIFY_CODE,
  VIPPS_CALLBACK,
  CANCEL_VIPPS_CALLBACK,
  VIPPS_CALLBACK_SUCCESS,
  VIPPS_CALLBACK_ERROR,
  VIPPS_GET_DATA,
  CANCEL_VIPPS_GET_DATA,
  vippsVerifySuccess,
  vippsVerifyError,
  vippsVerifySendCodeSuccess,
  vippsVerifySendCodeError,
  vippsVerifyCodeSuccess,
  vippsVerifyCodeError,
  vippsCallbackSuccess,
  vippsCallbackError,
  vippsGetDataSuccess,
  vippsGetDataError
} from '../../common/Vipps/Vipps.actions';
import {
  EMAIL_TYPE,
  PHONE_TYPE,
  VERIFY_FORM_NAME,
  VERIFY_FORM_EMAIL_CODE,
  VERIFY_FORM_PHONE_CODE
} from '../../../../shared/config/api';

const vippsGetDataLogic = createLogicWithApi({
  type: VIPPS_GET_DATA,
  cancelType: CANCEL_VIPPS_GET_DATA,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'GET',
      path: vipps.getDataR.replace('{verificationCode}', action.payload),
      requireLogin: false
    })
      .then(resp => {
        dispatch(vippsGetDataSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(vippsGetDataError(err));
        done();
      });
  }
});

const vippsVerifyLogic = createLogicWithApi({
  type: VIPPS_VERIFY,
  cancelType: CANCEL_VIPPS_VERIFY,
  process: (
    { action, Api, state: { locale, login, router } },
    dispatch,
    done
  ) => {
    const url = login.loggedIn ? vipps.verify : vipps.verifyAnon;

    return Api({
      method: 'GET',
      path: url,
      requireLogin: false,
      handle: r => {
        if (action.payload) {
          return r.query({ verificationCode: action.payload });
        }

        return r;
      }
    })
      .then(resp => {
        const delegatePage = router.location.pathname
          .includes(locale.verifisering.route.path);
          
        if (resp.sentVerificationNotification && !delegatePage) {
          dispatch(
            showNotification({
              notificationText:
                locale.verifisering.details.verificationMessageSent
            })
          );
        }

        dispatch(vippsVerifySuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(vippsVerifyError(err));
        done();
      });
  }
});

const vippsCallbackLogic = createLogicWithApi({
  type: VIPPS_CALLBACK,
  cancelType: CANCEL_VIPPS_CALLBACK,
  process: ({ action: { payload }, Api, state: { login } }, dispatch, done) => {
    const url = login.loggedIn 
      ? vipps.callback 
      : vipps.callbackAnon;

    return Api({
      method: 'GET',
      path: `${url}${payload}`,
      requireLogin: false
    })
      .then(resp => {
        dispatch(vippsCallbackSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(vippsCallbackError(err));
        
        if (err.message) {
          dispatch(showNotification(err.message));
        }

        done();
      });
  }
});

const vippsCallbackSuccessLogic = createLogicWithRouteChecks({
  type: [
    VIPPS_CALLBACK_SUCCESS, 
    VIPPS_CALLBACK_ERROR
  ],
  process: ({ action: { payload }, getState, getUrl }, dispatch, done) => {
    let { login, basket, locale } = getState();

    if (payload) {
      if (payload.err) {
        dispatch(showNotification(payload.err));

      } else if (payload.success) {
        dispatch(showNotification({ notificationText: payload.success }));
      }
    }

    if (payload.guardianVerification) {
      dispatch(
        showNotification({
          notificationText: `${locale.verifisering.details.verifiedWithVipps} Vipps`
        })
      );
      dispatch(push(`/`));

    } else {
      if (basket.selected.id) {
        dispatch(
          push(getUrl('checkout').replace(/:basketId/, basket.selected.id))
        );

      } else {
        dispatch(push(getUrl(login.loggedIn ? 'profile' : 'register')));
      }
    }

    done();
  }
});

const vippsVerifySendCodeLogic = createLogicWithApi({
  type: VIPPS_SEND_CODE,
  cancelType: CANCEL_VIPPS_SEND_CODE,
  process: ({ action: { payload }, Api }, dispatch, done) => {
    const isPhoneType = payload === PHONE_TYPE;

    return Api({
      method: 'POST',
      path: isPhoneType
        ? vipps.generatePhoneVerification
        : vipps.generateEmailVerification,
      requireLogin: true
    })
      .then(resp => {
        dispatch(vippsVerifySendCodeSuccess({ type: payload, resp }));
        done();
      })
      .catch(err => {
        dispatch(vippsVerifySendCodeError({ type: payload, err }));
        done();
      });
  }
});

const vippsVerifyCodeLogic = createLogicWithApi({
  type: VIPPS_VERIFY_CODE,
  cancelType: CANCEL_VIPPS_VERIFY_CODE,
  process: ({ action: { payload }, getState, Api }, dispatch, done) => {
    const matching = {
      [PHONE_TYPE]: VERIFY_FORM_PHONE_CODE,
      [EMAIL_TYPE]: VERIFY_FORM_EMAIL_CODE
    };

    const { form } = getState();
    let code = '';

    if (form[VERIFY_FORM_NAME] && form[VERIFY_FORM_NAME].values) {
      code = form[VERIFY_FORM_NAME].values[matching[payload]];
    }

    return Api({
      method: 'POST',
      path: payload === PHONE_TYPE ? vipps.verifyByPhone : vipps.verifyByEmail,
      requireLogin: true,
      handle: request => {
        return request.send({
          verificationCode: code
        });
      }
    })
      .then(resp => {
        dispatch(vippsVerifyCodeSuccess({ type: payload, resp }));
        dispatch(fetchLoginInfo()); // to update user data for whole store
        done();
      })
      .catch(err => {
        dispatch(vippsVerifyCodeError({ type: payload, err }));
        done();
      });
  }
});

const logics = [
  vippsGetDataLogic,
  vippsVerifyLogic,
  vippsCallbackLogic,
  vippsCallbackSuccessLogic,
  vippsVerifySendCodeLogic,
  vippsVerifyCodeLogic
];

export default logics;