import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { push } from '@lagunovsky/redux-react-router';
import { useLocation, Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { toggleHeaderForceMobile as toggleHeaderForceMobileAction } from '../../common/Header/Header.actions';
import LocationMarker from '../../common/Map/LocationMarker/LocationMarker.component';
import ClusterMarker from '../../common/Map/ClusterMarker/ClusterMarker.component';
import MapSearch from '../../common/Map/MapSearch/MapSearch.component';
import Legends from '../../common/Map/Legends/Legends.container';
import { LOCATION_IN_CENTER, map_settings } from '../../../config/locations';
import withLazyLoad from '../../../../shared/hoc/withLazyLoad';
import { useStoreState } from '../../../../shared/hooks';
import Button from '../../common/Button/Button.styled';
import { legends } from '../../../config/locations';
import Map from '../../common/Map/Map.component';
import {
  setMarkerLocation as setMarkerLocationAction,
  unsetMarkerLocation as unsetMarkerLocationAction
} from '../../common/LocationSelector/LocationSelector.actions';
import {
  getLocationsWithoutMapEntries,
  getLocations,
} from '../../../../shared/components/Locations/Locations.selectors';
import {
  fetchLocations as fetchLocationsAction,
  fetchLocation as fetchLocationAction
} from '../../../../shared/components/Locations/Locations.actions';
import {
  mapUpdateCenter as mapUpdateCenterAction,
  mapUpdateZoom as mapUpdateZoomAction,
  mapPropsUpdated as mapPropsUpdatedAction
} from '../../common/Map/Map.actions';
import {
  getClustersMarkers,
  getClusterPointsBounds,
  getClustersMarkersWithoutMapEntries
} from '../../common/Map/Map.selectors';

import Sidebar from './Sidebar/Sidebar.component';
import StyledLocations from './Locations.styled';
import { setZoomFitClosest } from '../../common/Map/Map.helpers';

const formName = 'locationsForm';

const selectItems = (hideMapEntryLocations) => state => (
  hideMapEntryLocations
    ? getLocationsWithoutMapEntries(state, true)
    : getLocations(state, true)
);

const selectMarkerClusters = (hideMapEntryLocations) => state => (
  hideMapEntryLocations
    ? getClustersMarkersWithoutMapEntries(state)
    : getClustersMarkers(state)
);

const LocationsComponent = ({ 
  isInModal = false, 
  options = {}, 
  hideMapEntryLocations = false
}) => {
  const routeLocation = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();
  const items = useSelector(selectItems(hideMapEntryLocations));
  const markerClusters = useSelector(selectMarkerClusters(hideMapEntryLocations));
  const {
    locationSelector: { markerLocation },
    map: { mapZoom }
  } = useStoreState();

  const [ isLegendsOpened, setIsLegendsOpened ] = useState(false);
  const [ isRouteLocationChanged, setIsRouteLocationChanged ] = useState(false);
  
  const fetchLocations = useCallback(mapProps => {
    dispatch(fetchLocationsAction({ mapProps: mapProps }));
  }, [ dispatch ]);

  const onMapChange = useCallback(mapProps => {
    dispatch(mapPropsUpdatedAction(mapProps));
    dispatch(mapUpdateCenterAction(mapProps.center));
    dispatch(mapUpdateZoomAction(mapProps.zoom));
  }, [ dispatch ]);

  const clickLocationMarker = useCallback((key, location) => {
    if (location.points && location.points.length) {
      try {
        const { center, zoom } = getClusterPointsBounds(location);

        if (!isInModal) {
          dispatch(push(`${routeLocation.pathname}${window.location.search}`));

        } else {
          dispatch(unsetMarkerLocationAction());
        }

        dispatch(mapUpdateCenterAction(center, true));
        dispatch(mapUpdateZoomAction(zoom - (!isInModal ? 1 : 0)));

      } catch (e) {
        return;
      }

    } else {
      dispatch(setMarkerLocationAction(location));
      dispatch(fetchLocationAction({
        ...location,
        isForMarker: true
      }));
      dispatch(mapUpdateCenterAction(location));

      if (!isInModal) {
        dispatch(
          push(`${intl.formatMessage({id: 'locations.route.path'})}/${location.slug}${window.location.search}`)
        );
      }
    }
  }, [ intl, isInModal, routeLocation, dispatch ]);

  const onChangeLocation = useCallback((key, location) => {
    dispatch(setMarkerLocationAction(location));
    dispatch(mapUpdateCenterAction(location));

    if (location.id) {
      dispatch(fetchLocationAction({
        ...location,
        isForMarker: true
      }));
    }
  }, [ dispatch ]);

  const onSuggestSelect = useCallback(async suggestion => {
    if (suggestion?.isFixture) {
      dispatch(mapUpdateCenterAction(suggestion.location, true));
      dispatch(mapUpdateZoomAction(map_settings.map_zoom_on_search_changed));

      return;
    }

    const { center, zoom } = await setZoomFitClosest(
      items,
      suggestion.location
    );

    dispatch(mapUpdateCenterAction(LOCATION_IN_CENTER ? suggestion.location : center, true));
    dispatch(mapUpdateZoomAction(zoom));
  }, [ items, dispatch ]);

  const toggleHeaderToMobile = useCallback((visible) => {
    dispatch(toggleHeaderForceMobileAction(visible));
  }, [ dispatch ]);

  const checkKeyDown = useCallback((e, pin) => {
    if (e.keyCode === 13) {
      if (pin.numPoints === 1) {
        clickLocationMarker(null, pin.points[0]);

      } else {
        clickLocationMarker(null, pin);
      }
    }
  }, [ clickLocationMarker ]);

  const handleLegendsToggle = useCallback((isOpened) => {
    setIsLegendsOpened(isOpened);
  }, []);

  useLayoutEffect(() => {
    toggleHeaderToMobile(true);

    return () => {
      toggleHeaderToMobile(false);
    };
  }, [ toggleHeaderToMobile ]);

  useEffect(() => {
    if (!items || !items.length) {
      fetchLocations();
    }
  }, [ fetchLocations, items ]);

  useEffect(() => {
    setIsRouteLocationChanged(true);
  }, [ routeLocation.pathname ]);

  useEffect(() => {
    if (!routeLocation || !isRouteLocationChanged) {
      return;
    }

    let slug = routeLocation.pathname.split('/')[2];

    setIsRouteLocationChanged(false);

    if (markerLocation.slug !== slug) {
      items.some((l, i) => {
        if (l.slug === slug) {
          onChangeLocation(i, l);

          return true;
        }

        return false;
      });
    }
  }, [ routeLocation, isRouteLocationChanged, markerLocation, items, onChangeLocation ]);

  useEffect(() => {
    let slug = routeLocation.pathname.split('/')[2];
    if (markerLocation.slug !== slug) {
      const locationFromUrl = items?.find(l => l.slug === slug);
      if (locationFromUrl) {
        onChangeLocation(locationFromUrl.id, locationFromUrl);
      }
    }        
  }, [ onChangeLocation, routeLocation.pathname, items, markerLocation.slug ]);

  return (
    <StyledLocations
      $isInModal={isInModal}
      $isLegendsOpened={isLegendsOpened}
    >
      <Helmet title={intl.formatMessage({ id: 'locations.route.name' })} />

      <div className="search-field">
        <Field
          name="geoAddress"
          component={MapSearch}
          locations={items}
          isInModal={isInModal}
          markerLocation={markerLocation}
          placeholder={intl.formatMessage({ id: 'locations.form.geoAddress_placeholder' })}
          onSuggestSelect={onSuggestSelect}
        />
      </div>

      <Legends
        items={legends}
        inModal={isInModal}
        onToggle={handleLegendsToggle}
      />

      <Map
        sidebarActive={!!markerLocation.id}
        fullscreen={!isInModal}
        onChildClick={clickLocationMarker}
        onChange={onMapChange}
      >
        { markerClusters?.map((location) => 
          location.lat && location.lng ? (
            location.numPoints > 1 ? (
              <ClusterMarker
                key={location.id}
                onKeyDown={e => checkKeyDown(e, location)}
                lat={location.lat}
                lng={location.lng}
                points={location.points}
                count={location.numPoints}
                scale={Math.min(1 + 0.2 * (mapZoom / 8), 1.2)}
              />

            ) : (
              <LocationMarker
                {...location.points[0]}
                key={location.points[0].id}
                onKeyDown={e => checkKeyDown(e, location)}
                lat={location.lat}
                lng={location.lng}
                isSelected={location.points[0].id === markerLocation.id}
                scale={Math.min(1 + 0.6 * (mapZoom / 8), 1.6)}
                isInModal={isInModal}
              />
            )

          ) : (
            null
          )
        )}
      </Map>

      {!isInModal ? (
        <Sidebar />

      ) : (
        <div className="continue-cta-holder">
          {markerLocation.id && markerLocation.slug ? (
            <Link
              to={
                `${intl
                  .formatMessage({ id: 'equipment.route.path' })
                  .replace(/:locationSlug/, markerLocation.slug)
                }${
                    options.appendPath ? options.appendPath : ''
                }`
              }
            >
              <Button $fullWidth>
                <FormattedMessage id="locations.details.continueCta" />
              </Button>
            </Link>

          ) : (
            <Button $fullWidth disabled>
              <FormattedMessage id="locations.details.continueCta" />
            </Button>
          )}
        </div>
      )}
    </StyledLocations>
  );
}

LocationsComponent.propTypes = {
  hideMapEntryLocations: PropTypes.bool,
  isInModal: PropTypes.bool,
  options: PropTypes.object,
};

export default withLazyLoad(reduxForm({ form: formName })(LocationsComponent));
