import { createGlobalStyle } from 'styled-components';

import vars from '../variables';

const HtmlGlobalStyles = createGlobalStyle`
  html {
    background-color: ${vars.colorWhite};
  }

  ::selection {
    background: ${vars.colorPurple};
    color: ${vars.colorWhite};
  }
`;

export default HtmlGlobalStyles;