import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { locationTypesColorHelper } from '../../../../config/locations';
import MarkerCluster from '../../../../assets/icons/marker-cluster';

import StyledClusterMarker from './ClusterMarker.styled';

const fillHelper = (points, theme) => {
  const helper = location => {
    const { type, reservationEnabled } = location;
    return locationTypesColorHelper(type, reservationEnabled, theme);
  };

  const color = helper(points[0]);
  const differentTypes = points.some(point => helper(point) !== color);

  return differentTypes ? theme.colorPurpeGray : color;
};

const ClusterMarker = ({ theme, scale, count, onKeyDown, points }) => (
  <StyledClusterMarker 
    $scale={scale} 
    tabIndex="0" 
    onKeyDown={onKeyDown}
  >
    <MarkerCluster 
      fill={fillHelper(points, theme)} 
      scale={scale} 
    />
    
    <div className="cluster-count">{count}</div>
  </StyledClusterMarker>
);

ClusterMarker.propTypes = {
  theme: PropTypes.object.isRequired,
  onKeyDown: PropTypes.func,
  scale: PropTypes.number,
  count: PropTypes.number,
  points: PropTypes.array
};

export default withTheme(ClusterMarker);
