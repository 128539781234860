import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';

const Pin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 15">
    <path d="M6 14.5c-.7 0-2.5-1.5-3.4-2.6-1.7-2-2.6-4-2.6-6C0 2.7 2.7 0 6 0s6 2.7 6 5.9c0 1.9-.9 4-2.6 6-.9 1.1-2.7 2.6-3.4 2.6zM6 1C3.2 1 1 3.2 1 5.9c0 1.7.8 3.6 2.4 5.3 1.1 1.3 2.4 2.2 2.7 2.2.2-.1 1.4-.9 2.6-2.2C10.2 9.5 11 7.6 11 5.9 11 3.2 8.8 1 6 1zm0 7.9C4.4 8.9 3.1 7.6 3.1 6c0-1.6 1.3-2.9 2.9-2.9 1.6 0 2.9 1.3 2.9 2.9 0 1.6-1.3 2.9-2.9 2.9zm0-4.8C5 4.1 4.1 5 4.1 6 4.1 7 5 7.9 6 7.9 7 7.9 7.9 7 7.9 6 7.9 5 7 4.1 6 4.1z" />
  </svg>
);

Pin.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withSvg(Pin);
