import styled from 'styled-components';

const StyledBanner = styled.div`
  padding: 7px 0;
  font-size: 15px;
  color: ${props => props.theme.colorWhite};
  background-color: ${props => props.theme.colorEggplant};

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .verify-button {
    margin-left: 8px;
    padding: 2px 8px;
    min-height: 26px;
  }
`;

export default StyledBanner;
