import { createSelector } from 'reselect';

import { getEquipmentImagesWithFallback } from '../../../../shared/components/Equipment/Equipment.selectors';
import { getCategoryById } from '../../../../shared/components/Categories/Categories.selectors';

const getBasket = state => state.basket;

export const getPreparedBasket = createSelector(
  [getBasket, state => state],
  (basket = {}, state) => ({
    ...basket,
    items: basket.items.map(item => ({
      ...item,
      equipment: !item.equipment
        ? {}
        : {
            ...item.equipment,
            attributes: {
              ...item.equipment.attributes,
              images: getEquipmentImagesWithFallback(state, {
                ...item.equipment.attributes,
                category: getCategoryById(
                  state,
                  item.equipment.attributes.category
                )
              })
            }
          }
    }))
  })
);
