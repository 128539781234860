import forbidExtraPropsOrigin from 'prop-types-exact';

import andOrigin from './and';
import betweenOrigin from './between';
import booleanSomeOrigin from './booleanSome';
import childrenHavePropXorChildrenOrigin from './childrenHavePropXorChildren';
import childrenOfOrigin from './childrenOf';
import childrenOfTypeOrigin from './childrenOfType';
import childrenSequenceOfOrigin from './childrenSequenceOf';
import componentWithNameOrigin from './componentWithName';
import disallowedIfOrigin from './disallowedIf';
import elementTypeOrigin from './elementType';
import emptyOrigin from './empty';
import explicitNullOrigin from './explicitNull';
import integerOrigin from './integer';
import keysOfOrigin from './keysOf';
import mutuallyExclusivePropsOrigin from './mutuallyExclusiveProps';
import mutuallyExclusiveTruePropsOrigin from './mutuallyExclusiveTrueProps';
import nChildrenOrigin from './nChildren';
import nonNegativeIntegerOrigin from './nonNegativeInteger';
import nonNegativeNumberOrigin from './nonNegativeNumber';
import numericStringOrigin from './numericString';
import objectOrigin from './object';
import { or as orOrigin } from './or';
import predicateOrigin from './predicate';
import rangeOrigin from './range';
import refOrigin from './ref';
import requiredByOrigin from './requiredBy';
import restrictedPropOrigin from './restrictedProp';
import sequenceOfOrigin from './sequenceOf';
import shapeOrigin from './shape';
import stringEndsWithOrigin from './stringEndsWith';
import stringStartsWithOrigin from './stringStartsWith';
import uniqueArrayOrigin from './uniqueArray';
import uniqueArrayOfOrigin from './uniqueArrayOf';
import valuesOfOrigin from './valuesOf';
import withShapeOrigin from './withShape';

export const and = andOrigin;
export const between = betweenOrigin;
export const booleanSome = booleanSomeOrigin;
export const childrenHavePropXorChildren = childrenHavePropXorChildrenOrigin;
export const childrenOf = childrenOfOrigin;
export const childrenOfType = childrenOfTypeOrigin;
export const childrenSequenceOf = childrenSequenceOfOrigin;
export const componentWithName = componentWithNameOrigin;
export const disallowedIf = disallowedIfOrigin;
export const elementType = elementTypeOrigin;
export const empty = emptyOrigin;
export const explicitNull = explicitNullOrigin;
export const forbidExtraProps = forbidExtraPropsOrigin;
export const integer = integerOrigin;
export const keysOf = keysOfOrigin;
export const mutuallyExclusiveProps = mutuallyExclusivePropsOrigin;
export const mutuallyExclusiveTrueProps = mutuallyExclusiveTruePropsOrigin;
export const nChildren = nChildrenOrigin;
export const nonNegativeInteger = nonNegativeIntegerOrigin;
export const nonNegativeNumber = nonNegativeNumberOrigin;
export const numericString = numericStringOrigin;
export const object = objectOrigin;
export const or = orOrigin;
export const predicate = predicateOrigin;
export const range = rangeOrigin;
export const ref = refOrigin;
export const requiredBy = requiredByOrigin;
export const restrictedProp = restrictedPropOrigin;
export const sequenceOf = sequenceOfOrigin;
export const shape = shapeOrigin;
export const stringEndsWith = stringEndsWithOrigin;
export const stringStartsWith = stringStartsWithOrigin;
export const uniqueArray = uniqueArrayOrigin;
export const uniqueArrayOf = uniqueArrayOfOrigin;
export const valuesOf = valuesOfOrigin;
export const withShape = withShapeOrigin;
