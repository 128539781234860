import styled, { css } from 'styled-components';

const color = css`
  background: ${props => props.$color};
  
  &:hover {
    filter: brightness(1.05);
  }
`;

const green = css`
  background: ${props => props.theme.colorGreen};
  
  &:hover {
    background: ${props => props.theme.colorGreenLight};
  }
`;

const purple = css`
  background: ${props => props.theme.colorPurple};
  
  &:hover {
    background: ${props => props.theme.colorPurpleLight};
  }
`;

const StyledButton = styled.button`
  transition: background-color 0.2s linear;
  color: ${props => props.$fontColor 
    ? props.$fontColor
    : props.$discrete 
      ? props.theme.colorGrayDarkest
      : props.theme.colorWhite
  };
  font-size: 15px;
  border-radius: 4px;
  font-family: ${props => props.theme.fonts.medium};
  font-weight: 500;
  border: ${props => props.$discrete ? '1px solid ' + props.theme.colorGrayLight : 0};
  ${props => props.$borderLess && 'border: none;'}
  padding: 0 20px;
  min-height: 43px;
  box-shadow: ${ props => props.$shadow
    ? '0 7px 30px 0 rgba(45, 68, 133, 0.16), 0 5px 15px 0 rgba(0, 0, 0, 0.08)'
    : 0
  };
  width: ${props => (props.$fullWidth ? '100%' : 'auto')};
  cursor: pointer;

  ${props => (props.$color ? color : props.$green ? green : purple)}

  &:disabled {
    background: ${props => props.theme.colorGrayLight};
    color: ${props => props.theme.colorGray};
    cursor: not-allowed;
  }
`;

export default StyledButton;
