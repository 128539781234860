import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import StyledCheckboxInput from './CheckboxInput.styled';

const CheckboxInput = ({ meta, input, label, id, block, ...props }) => (
  <div>
    <StyledCheckboxInput
      $block={block}
      $type={props.type || (input && input.type)}
    >
      <input id={id} {...input} {...props} />
      <label htmlFor={id}>{label}</label>

      {meta && meta.touched && meta.error && (
        <div className="error">
          <FormattedMessage id={meta.error} />
        </div>
      )}
    </StyledCheckboxInput>
  </div>
);

CheckboxInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  block: PropTypes.bool
};

export default CheckboxInput;
