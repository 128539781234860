import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { reduxForm, Form, touch } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import moment from 'moment';

import { showConfirm } from '../../../../../shared/components/common/Dialogs/Dialogs.actions';
import { fetchLoginInfo, logout } from '../../../../../shared/components/Login/Login.actions';
import LoadingAnimation from '../../../../../shared/components/common/LoadingAnimation/LoadingAnimation.component';
import { View, Container, Row, Col, offset } from '../../../../../shared/components/Grid';
import SubmitErrors from '../../../common/SubmitErrors/SubmitErrors.component';
import { saveUser, deleteUser, saveUserWithVerify } from '../User.actions';
import ButtonLink from '../../../common/ButtonLink/ButtonLink.styled';
import CoBorrower from '../shared/CoBorrower/CoBorrower.container';
import withLazyLoad from '../../../../../shared/hoc/withLazyLoad';
import Approvals from '../shared/Approvals/Approvals.component';
import CloseSmall from '../../../../assets/icons/close-small';
import Button from '../../../common/Button/Button.component';
import { useStoreState } from '../../../../../shared/hooks';
import Check from '../../../../assets/icons/check';
import t from '../../../../styles/variables';
import {
  setCoborrowerTermsToTrue,
  mergeCoborrowers
} from '../shared/CoBorrower/CoBorrower.selector';

import UpdatePasswordForm from './UpdatePasswordForm/UpdatePasswordForm.component';
import ProfileForm from './ProfileForm/ProfileForm.container';
import StyledProfile from './Profile.styled';

import { removeLockedKeys, formatDateForInput } from './Profile.selectors';

export const formName = 'profileForm';

const ProfileComponent = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const {
    form: { profileForm = {} },
    user: { loading, profile },
    login: { loggedIn }  
  } = useStoreState();

  const formBirthday = useMemo(() => (
    profile.birthday
      ? moment(profile.birthday)
      : moment('1970-01-01')
  ), [ profile ]);

  const checkIcon = useMemo(
    () => <Check width={16} fill={t.colorGreen} />, 
    []
  );

  const crossIcon = useMemo(
    () => <CloseSmall width={16} fill={t.colorRed} />, 
    []
  );

  const deleteUserAccount = useCallback(() => {
    dispatch(
      showConfirm({
        acceptAction: deleteUser(),
        content: (
          <FormattedMessage
            tagName="h3"
            id="profile.details.deleteUserAccountConfirm"
          />
        )
      })
    );
  }, [ dispatch ]);

  const getUserInfo = useCallback(() => {
    dispatch(fetchLoginInfo());
  }, [ dispatch ]);

  const loginCheck = useCallback(() => {
    if (loggedIn) {
      return;
    }

    navigate(intl.formatMessage({ id: 'login.route.path' }));
  }, [ navigate, intl, loggedIn ]);

  const validateForm = useCallback(() => {
    // TODO: trigger manually validation for special fields
    // as they aren't required in admin
    setTimeout(() => {
      dispatch(touch('profileForm', 'address', 'zipcode', 'city'));
    }, 1);
  }, [ dispatch ]);

  useEffect(() => {
    loginCheck();
  }, [ loginCheck ]);

  useEffect(() => {
    validateForm();
  }, [ validateForm ]);

  useEffect(() => {
    if (!loggedIn) {
      return;
    }

    getUserInfo();
  }, [ loggedIn, getUserInfo ]);

  return (
    <View>
      <StyledProfile>
        <Container>
          <FormattedMessage tagName="h1" id="profile.route.name" />

          {loggedIn ? (
            <Row>
              <Col w={[6 / 6, 8 / 12, 6 / 12]} ml={offset([0, 2 / 12, 3 / 12])}>
                <Form onSubmit={handleSubmit}>
                  <Helmet title={intl.formatMessage({ id: `profile.route.name` })} />
      
                  <div className="additional-info">
                    <div>
                      {profile.isPersonVerified ? checkIcon : crossIcon}
                      <FormattedMessage
                        tagName="span"
                        id={`verifisering.details.${
                          profile.isPersonVerified ? 'idVerified' : 'idNotVerified'
                        }`}
                      />
                    </div>
      
                    <div>
                      {profile.emailVerified ? checkIcon : crossIcon}
                      <FormattedMessage
                        tagName="span"
                        id={`verifisering.details.${
                          profile.emailVerified ? 'emailVerified' : 'emailNotVerified'
                        }`}
                      />
                    </div>
      
                    <div>
                      {profile.mobileVerified ? checkIcon : crossIcon}
                      <FormattedMessage
                        tagName="span"
                        id={`verifisering.details.${
                          profile.mobileVerified ? 'phoneVerified' : 'phoneNotVerified'
                        }`}
                      />
                    </div>
                  </div>
      
                  <SubmitErrors errors={profile.error} />
      
                  <ProfileForm allFieldsDisabled={loading} />
      
                  <hr />
      
                  <UpdatePasswordForm allFieldsDisabled={loading} />
      
                  {moment.duration(moment().diff(moment(formBirthday))).asYears() >=
                    18 && (
                    <span>
                      <hr />
                      <CoBorrower allFieldsDisabled={loading} intl={intl} />
                    </span>
                  )}
      
                  <hr />
      
                  <Approvals
                    restrictNotifications={true}
                    allFieldsDisabled={loading}
                  />
      
                  <Button
                    type="submit"
                    fullWidth
                    shadow={false}
                    loading={loading}
                    disabled={ (typeof profileForm.syncErrors == 'object' ? true : false) || loading }
                  >
                    <FormattedMessage id="forms.saveChanges" />
                  </Button>

                  <ButtonLink $mt={`24px`} onClick={deleteUserAccount}>
                    <FormattedMessage id="forms.deleteAccount" />
                  </ButtonLink>

                  {profile.deleteError && (
                    <SubmitErrors errors={profile.deleteError} />
                  )}
                </Form>
              </Col>
            </Row>          
          ) : (
            <LoadingAnimation margin="40px 0" />
          )}
        </Container>
      </StyledProfile>
    </View>
  );
};

ProfileComponent.propTypes = {
  handleSubmit: PropTypes.func,
}

const mapStateToProps = ({ user: { profile } }) => {
  return {
    profile,
    initialValues: formatDateForInput(profile),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: data => {
    const { dispatch } = dispatchProps;
    const { profile } = stateProps;
    const formData = setCoborrowerTermsToTrue(mergeCoborrowers(removeLockedKeys(data)));

    const verifiedDataChanged = () => {
      if (
        profile.email !== formData.email 
        && profile.emailVerified
      ) {
        return true;
      }

      return (
        profile.mobilePhone !== formData.mobilePhone 
        && profile.mobileVerified
      );
    };

    if (profile.email !== formData.email) {
      dispatch(
        showConfirm({
          acceptAction: [saveUser(formData), logout()],
          content: (
            <div>
              <FormattedMessage
                tagName="h3"
                id="profile.details.confirm_change_email"
              />
              <FormattedMessage
                tagName="h3"
                id="verifisering.details.reVerifyNeeded"
              />
            </div>
          )
        })
      );

    } else if (verifiedDataChanged()) {
      dispatch(
        showConfirm({
          acceptAction: saveUserWithVerify(formData),
          borderLess: true,
          confirmPhraseId: 'verifisering.details.reVerifyConfirmText',
          content: (
            <div className="confirm">
              <FormattedMessage tagName="h3" id="verifisering.route.name" />
              <FormattedMessage
                tagName="h3"
                id="verifisering.details.reVerifyNeeded"
              />
            </div>
          )
        })
      );

    } else {
      dispatch(saveUser(formData));
    }
  }
});

export default withLazyLoad(
  connect(
    mapStateToProps, 
    mapDispatchToProps, 
    mergeProps
  )(
    reduxForm({
      form: formName,
      enableReinitialize: true
    })(ProfileComponent)
  )
);
