import styled, { css } from 'styled-components';

const withIcon = css`
  padding-left: 48px;
  color: ${props => props.theme.colorGrayDark};
  min-height: 24px;
  position: relative;

  i {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
  }

  h4 {
    font-size: 14px;
    color: ${props => props.theme.colorGrayDarker};
  }
`;

const withTitle = css`
  padding-left: 116px;

  h4 {
    float: left;
    width: 104px;
    margin-left: -116px;
  }
`;

const withoutIcon = css`
  h4 {
    font-size: inherit;
    color: ${props => props.theme.colorGrayDark};
  }

  ${props => (props.$hasTitle ? withTitle : null)};
`;

const StyledDetailBlockLine = styled.div`
  font-size: 13px;
  line-height: 18px;
  float: left;
  width: 100%;
  margin-bottom: 16px;

  ${props => (props.$hasIcon ? withIcon : withoutIcon)};

  p,
  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  a {
    color: ${props => props.theme.colorGrayDarker};

    &:hover {
      text-decoration: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export default StyledDetailBlockLine;
