import { connect } from 'react-redux';
import moment from 'moment';

import RegisterGuestForm from './RegisterGuestForm.component';

const mapStateToProps = ({ form: { registerGuestForm } }) => {
  return {
    currentDate: moment().format('YYYY-MM-DD'),
    formValues:
      registerGuestForm && registerGuestForm.values
        ? registerGuestForm.values
        : {}
  };
};

export default connect(
  mapStateToProps,
  null
)(RegisterGuestForm);
