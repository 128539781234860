import track from './ga.tracker';

import { TOGGLE_MODAL } from '../../components/common/LocationSelector/LocationSelector.actions';

const locationSelectorTracking = {
  [TOGGLE_MODAL]: ({ store }) => {
    const {
      locationSelector: { open }
    } = store.getState();

    if (!open) {
      track.modal('utlansordninger');
    }
  }
};

export default locationSelectorTracking;
