import { createSelector } from 'reselect';
import { isArray, sumBy } from 'lodash';

export const getItems = items => (isArray(items) ? items : []);

export const getAccumulatedItemsQuantity = createSelector(
  [getItems],
  (items = []) => {
    return sumBy(items, i => {
      return i.quantity;
    });
  }
);
