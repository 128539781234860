import { combineReducers } from 'redux';

import {
  MAP_LOADED,
  MAP_UPDATE_CENTER,
  MAP_UPDATE_ZOOM,
  MAP_PROPS_UPDATED,
  MAP_RESET
} from './Map.actions';

// LOADED

const loaded = (state = false, action) => {
  switch (action.type) {
    case MAP_LOADED:
      return true;
    default:
      return state;
  }
};

const mapCenter = (state = null, action) => {
  switch (action.type) {
    case MAP_UPDATE_CENTER:
      if (!action.data.lat || !action.data.lat) return null;

      if (action.data.lat && action.data.lng) {
        return {
          lat: action.data.lat,
          lng: action.data.lng,
          resetLocation: action.resetLocation
        };
      }

      return null;
    case MAP_RESET:
      return null;
    default:
      return state;
  }
};

const mapZoom = (state = null, action) => {
  switch (action.type) {
    case MAP_UPDATE_ZOOM:
      return action.data;
    case MAP_RESET:
      return null;
    default:
      return state;
  }
};

const mapProps = (state = {}, action) => {
  switch (action.type) {
    case MAP_PROPS_UPDATED:
      return action.data;
    case MAP_RESET:
      return {};
    default:
      return state;
  }
};

// MAIN

const reducer = combineReducers({
  loaded,
  mapCenter,
  mapZoom,
  mapProps
});

export default reducer;
