import { media } from '../../shared/styles/media';

const t = {};

// GRID

t.containerWidth = { sm: 600, md: 1600 };
t.space = [0, 8, 16, 32, 64];
t.intBreakpoints = [768, 1024, 1280, 1760];
t.breakpoints = t.intBreakpoints.map(px => `${px}px`);
t.media = media(t.intBreakpoints);

// FONTS

t.fonts = {
  regular: 'GT-Walsheim-Pro-Regular',
  medium: 'GT-Walsheim-Pro-Medium',
  light: 'GT-Walsheim-Pro-Light',
  lightOblique: 'GT-Walsheim-Pro-Light-Oblique'
};

// COLORS

t.colorPurple = '#8e399a';
t.colorPurpleLight = '#b961f6';
t.colorPurpleDark = '#9345c8';
t.colorPurpleDarker = '#9a319f';
t.colorLightTransparentPurple = 'rgba(154, 49, 159, 0.16)';
t.colorPurpeGray = '#5b505d';
t.colorGreenLight = '#23dfb7';
t.colorGreen = '#00a984';
t.colorRed = '#f25c52';
t.colorBrown = '#997618';
t.colorYellow = '#f6dd96';
t.colorYellowLight = '#fffcf1';
t.colorTurquoise = '#24d8b2';
t.colorEggplant = '#2c0931';
t.colorVipps = '#ff5b24';
t.colorVippsGreen = '#01a77f';
t.colorBorder = '#e0d7e1';

t.colorBlack = '#000000';
t.colorGrayDarker = '#262f3d';
t.colorGrayDark = '#737f8e';
t.colorGray = '#96a1af';
t.colorGrayLight = '#d9dee3';
t.colorGrayLighter = '#f2f4f7';
t.colorWhite = '#ffffff';

t.colorAlertBg = '#FEF3F4';
t.colorAlertBorder = '#F5857F';

// GENERAL

t.headerMobileHeight = 72;
t.headerHeight = 120;

t.modalTitleMobileHeight = 52;

t.locationSidebarWidth = 410;

export default t;
