import styled, { css } from 'styled-components';

const top = css`
  top: 12px;
`;

const Basket = styled.section`
  position: fixed;
  z-index: 99;
  max-width: 100%;
  background: ${props => props.theme.colorWhite};
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
  padding: 52px 12px 67px;

  .basket-empty-msg {
    text-align: center;
    padding: 20px;
    opacity: 0.3;
  }

  ${props => props.theme.media.sm`
    box-shadow: 0 4px 32px 0 rgba(38, 38, 38, 0.12), 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    padding: 24px 24px 4px;
    top: ${props => 20 + props.theme.headerMobileHeight}px;
    transition: top 0.3s ease-in-out;
    ${props => (!props.$top ? top : null)};
    right: 12px;
    width: 385px;
    bottom: auto;
    max-height: calc(100% - ${props => 30 + props.theme.headerMobileHeight}px);
    overflow: auto;

    .basket-empty-msg {
      padding-top: unset;
    }
  `};

  .content {
    outline: 0;
  }

  .top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    ${props => props.theme.media.sm`
      display: none;
    `};

    h4 {
      display: block;
      margin: 0;
      background: ${props => props.theme.colorGrayDarker};
      padding: 18px 16px;
      text-align: center;
      font-size: 15px;
      line-height: 16px;
      color: ${props => props.theme.colorWhite};
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      appearance: none;
      border: 0;
      background: transparent;
      padding: 18px 16px;
      line-height: 14px;

      svg {
        width: 14px;
        height: 14px;
        vertical-align: middle;
      }
    }
  }

  .button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    background: ${props => props.theme.colorWhite};
    text-align: center;

    ${props => props.theme.media.sm`
      position: static;
      padding: 0;
    `};
  }

  .continue {
    font-size: 14px;
    display: block;
    padding: 8px 0;
    margin-top: 4px;

    ${props => props.theme.media.sm`
      padding-top: 4px;
    `};
  }
`;

export default Basket;
