import styled from 'styled-components';

const StyledDetailsImage = styled.div`
  width: 100%;
  height: 230px;
  position: relative;

  .location-image {
    width: 100%;
    height: 230px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .gradient-overlay {
    width: 100%;
    height: 30%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 3%, rgba(0, 0, 0, 0.4) 99%);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  h3 {
    position: absolute;
    margin: 20px 24px;
    bottom: 0;
    left: 0;
    font-size: 19px;
    font-weight: 500;
    text-align: left;
    color: ${props => props.theme.colorWhite};
    font-family: '${props => props.theme.fonts.medium}';
  }
`;

export default StyledDetailsImage;
