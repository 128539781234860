import React from 'react';
import PropTypes from 'prop-types';

import StyledBypassLink from './BypassLink.styled';

const BypassLink = ({ children, onClick }) => {
  const emitClickOnEnter = event => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <StyledBypassLink
      onClick={onClick}
      tabIndex={0}
      onKeyDown={emitClickOnEnter}
    >
      {children}
    </StyledBypassLink>
  );
};

BypassLink.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default BypassLink;
