import styled, { css } from 'styled-components';

const empty = css`
  line-height: ${props => (props.$light ? '18px' : '16px')};
  background: ${props => props.$light ? props.theme.colorWhite : props.theme.colorGrayDarker};
  color: ${props => props.$light ? props.theme.colorGrayDarker : props.theme.colorWhite};
  border: ${props => props.$light
    ? `${props.theme.colorWhite} solid 1px`
    : `${props.theme.colorGrayDarker} solid 1px`
  };
`;

const StyledHeaderBasket = styled.button`
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  position: relative;
  margin-left: 0;
  width: 30px;
  height: 40px;
  line-height: 20px;
  padding: 7px 0 11px 10px;

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  ${props => props.theme.media.sm`
    width: 20px;
    height: 22px;
    line-height: 22px;
    padding: 0;
    margin-top: -4px;
    margin-left: 8px;
    vertical-align: middle;
  `};

  ${props => props.$open && `pointer-events: none;`}

  svg {
    margin-bottom: 10px;
  }

  svg path {
    stroke: ${props => props.$light ? props.theme.colorWhite : props.theme.colorGrayDarker};
  }

  .counter {
    position: absolute;
    bottom: 4px;
    right: -9px;
    font-size: 12px;
    font-family: ${props => props.theme.fonts.medium};
    border-radius: 50%;
    line-height: 18px;
    height: 18px;
    width: 18px;
    text-align: center;
    color: ${props => props.theme.colorWhite};
    background: ${props => props.theme.colorGreen};

    ${props => props.theme.media.sm`
      bottom: -7px;
    `};

    ${props => props.$numItems > 0 ? null : empty}
  }
`;

export default StyledHeaderBasket;
