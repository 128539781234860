import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';
import withBounds from '../../../shared/hoc/withBounds';

const CloseSmall = ({ fill, theme }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
  >
    <path
      className="icon-path"
      fill={fill ? fill : theme.colorGrayDarker}
      d="M11.7 12.15c-.2 0-.3-.1-.4-.2l-5.1-5-5.1 5.1c-.2.2-.6.2-.9 0-.1-.1-.2-.3-.2-.4 0-.1.1-.3.2-.4l5.1-5.1-5-5.2C.1.85.1.75.1.55s.1-.3.2-.4c.2-.2.6-.2.9 0l5.1 5.1 5.1-5.1c.2-.2.6-.2.9 0 .1.1.2.3.2.4 0 .1-.1.3-.2.4l-5.2 5.1 5.1 5.1c.1.1.2.3.2.4 0 .1-.1.3-.2.4-.1.1-.3.2-.5.2z"
    />
  </svg>
);

CloseSmall.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string,
};

export default withBounds(withSvg(CloseSmall));
