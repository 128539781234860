import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from '@lagunovsky/redux-react-router';

import { toggleBasket, checkBasketInfo } from '../Basket/Basket.actions';

import { getPreparedBasket } from './Basket.selectors';
import Basket from './Basket.component';

const mapStateToProps = state => ({
  basket: getPreparedBasket(state),
  visibleHeader: state.header.visibleHeader
});

const mapDispatchToProps = (dispatch, { intl }) => ({
  closeBasket(e) {
    if (e) {
      e.preventDefault();
    }
    dispatch(toggleBasket(false));
  },
  gotoCheckout(id) {
    dispatch(toggleBasket(false));
    dispatch(
      push(
        `${intl
          .formatMessage({ id: 'checkout.route.path' })
          .replace(/:basketId/, id)}`
      )
    );
  },
  checkBasketInfo() {
    dispatch(checkBasketInfo());
  }
});

const mergeProps = (
  stateProps,
  { gotoCheckout, ...dispatchProps },
  ownProps
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  clickCheckout() {
    gotoCheckout(stateProps.basket.selected.id);
  }
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(Basket)
);
