import styled from 'styled-components';

const StyledHeaderMenu = styled.nav`
  display: none;

  ${props => props.theme.media.lg`
    display: flex;
    flex-direction: row;
  `};
`;

export default StyledHeaderMenu;
