import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import LocationSelector from '../common/LocationSelector/LocationSelector.container';
import ConfirmDialog from '../common/Dialogs/ConfirmDialog/ConfirmDialog.component';
import Dialogs from '../../../shared/components/common/Dialogs/Dialogs.container';
import LoginModal from '../common/Login/LoginModal/LoginModal.component';
import Header from '../common/Header/Header.component';
import Footer from '../common/Footer/Footer.container';
import Basket from '../common/Basket/Basket.container';
import StyledToast from '../common/Toast/Toast.styled';
import ToastSettings from '../../config/toast';
import { locale } from '../../config/locale';

const App = ({ routes }) => {
  const intl = useIntl();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ location.pathname ]);

  return (
    <div className="main-content">
      <StyledToast>
        <ToastContainer {...ToastSettings} />
      </StyledToast>

      <Helmet
        titleTemplate={intl.formatMessage({ id: `general.title_template` })}
        titleAttributes={{ itemprop: 'name', lang: locale.langCode }}
      />

      <Header />

      <Routes>
        {routes.map(({ key, path, ...route }) => (
          <Route key={key} path={`${path}/*`} {...route} />
        ))}
      </Routes>

      <Footer />

      <Basket />

      <LoginModal />

      <Dialogs>
        <ConfirmDialog />
      </Dialogs>

      <LocationSelector />
    </div>
  );
};

App.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired
    })
  ),
};

export default App;
