import React, { useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { parse, format } from 'date-fns';
import PropTypes from 'prop-types';

import StyledDatePicker from './DatePickerWrapper.styled';

const dateFormat = 'dd.MM.yyyy';

const DatePickerWrapperComponent = ({
  disabled,
  placeholder = '',
  openToDate,
  withPortal,
  input
}) => {
  const dateNowObj = useMemo(
    () => new Date(),
    []
  );

  const openToDateObj = useMemo(
    () => openToDate ? parse(openToDate, 'yyyy-MM-dd', new Date(openToDate)) : null, 
    [ openToDate ]
  );

  const selectedDateObj = useMemo(
    () => input.value ? parse(input.value, dateFormat, new Date()) : null, 
    [ input.value ]
  );

  const handleChange = useCallback((date) => {
    input.onChange(format(date, dateFormat));
  }, [ input ]);

  return (
    <StyledDatePicker>
      <DatePicker
        {...input}
        disabled={disabled}
        placeholderText={placeholder}
        maxDate={dateNowObj}
        openToDate={openToDateObj}
        startDate={selectedDateObj}
        selected={selectedDateObj}
        onChange={handleChange}
        dateFormat={dateFormat}
        dropdownMode={'scroll'}
        showYearDropdown
        yearDropdownItemNumber={80}
        popperPlacement={'bottom-start'}
        withPortal={withPortal}
        fixedHeight
        popperModifiers={{
          flip: {
            enabled: false
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: 'viewport'
          }
        }}
        tabIndex={0}
      />
    </StyledDatePicker>
  );
};

DatePickerWrapperComponent.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  openToDate: PropTypes.string,
  withPortal: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired
};

export default DatePickerWrapperComponent;
