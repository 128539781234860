import styled from 'styled-components';

const StyledTextInputSearch = styled.button`
  border: 0;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 10px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  z-index: 2;

  &[disabled] {
    opacity: 0.5;
  }
`;

export default StyledTextInputSearch;
