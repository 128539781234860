import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';

const ExpandMore = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="9"
    viewBox="0 0 16 9"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#2C0931" fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M23.582 26.172l-5.88-5.88c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0l-5.88 5.88z"
                  transform="translate(-951 -1418) translate(365 464) translate(0 728) translate(0 96) translate(0 89) translate(570 21)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ExpandMore.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withSvg(ExpandMore);
