import styled, { css } from 'styled-components';

const StyledLocations = styled.div`
  position: relative;
  display: ${props => (props.$isInModal ? 'inline-block' : 'block')};
  width: 100%;
  height: ${props => props.$isInModal
    ? 'calc(100vh - ' + props.theme.modalTitleMobileHeight + 'px)'
    : '100%'};
  margin-top: ${props => props.$isInModal 
    ? '0px' 
    : props.theme.headerMobileHeight + 'px'};

  ${props =>
    props.$isLegendsOpened
      ? css`
          z-index: 100;
        `
      : ''}

  .continue-cta-holder {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    text-align: center;
    pointer-events: none;
    background: white;

    a {
      pointer-events: visible;
    }
  }

  ${props => props.theme.media.sm`
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    margin-top: ${props =>
      props.$isInModal ? '0px' : props.theme.headerMobileHeight + 'px'};

    .continue-cta-holder {
      position: absolute;
      padding: 20px 20%;
      background: transparent;
    }
  `};

  ${props => props.theme.media.lg`
    margin-top: ${props =>
      props.$isInModal ? '0px' : props.theme.headerMobileHeight + 'px'};
  `};
`;

export default StyledLocations;
