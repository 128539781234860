import styled, { css } from 'styled-components';

const invalid = css`
  .SingleDatePickerInput,
  .DateRangePickerInput {
    border-color: ${props => props.theme.colorRed};
  }
`;

const enabled = css`
  color: inherit;
  background: transparent;
`;

const disabled = css`
  color: ${props => props.theme.colorGray};
  background: ${props => props.theme.colorGrayLighter};
`;

const StyledDatePicker = styled.div`
  display: ${props => (props.$inHeader ? 'inline-flex' : 'flex')};
  position: relative;

  ${props => props.$inHeader && css`
    height: 100%;
  `}

  .SingleDatePicker,
  .DateRangePicker {
    display: flex;
    flex-grow: 1;
    z-index: 1;

    ${props => props.$inHeader && css`
      height: 100%;
    `}

    & > div {
      width: 100%;
      
      ${props => props.$inHeader && css`
        height: 100%;
      `}
    }
  }

  .SingleDatePickerInput,
  .DateRangePickerInput {
    display: flex;
    align-items: center;
    height: 100%;
    border: 1px ${props => props.theme.colorGrayLight};
    border-style: ${props => props.$inHeader ? 'solid none solid solid' : 'solid'};
    ${enabled}

    ${props => props.theme.media.lg`
      border-style: ${props => (props.$inHeader ? 'none solid' : 'solid')};
    `};

    &__disabled,
    &__readOnly {
      ${props => (props.$inHeader ? enabled : disabled)}
    }
  }

  .DateInput {
    font-size: 14px;
    line-height: 1;
    padding: 0;
    width: 100%;
    height: 100%;
    background: transparent;

    ${props => props.theme.media.lg`
      line-height: 26px;
    `};

    &__display-text {
      padding: ${props => (props.$inHeader ? 17 : 15)}px 14px;

      ${props =>
        !props.$inHeader
          ? null
          : props.theme.media.lg`
        padding-top: 23px;
        padding-bottom: 23px;
      `};

      ${props => props.theme.media.xs`
        padding-left: 8px;
        padding-right: 8px;
      `};
    }

    &__display-text--disabled,
    &__display-text--readOnly {
      font-style: normal;
      color: ${props => props.theme.colorGray};
    }

    .DateInput_input {
      font-size: 14px;
      height: 100%;

      ${props => props.$inHeader && css`
        text-align: center;
      `}
    }
  }

  .DateRangePicker .DateInput {
    width: 50%;
  }

  .DateRangePickerInput__arrow {
    width: 4%;

    svg {
      width: 100%;
      height: auto;
      max-height: 2px;
    }
  }

  .DateInput__display-text--has-input {
    color: ${props => props.theme.colorGrayDarker};
  }

  .DateRangePicker__picker {
    margin-bottom: 24px;
    z-index: auto;
  }

  .DateInput__input.needsclick[readonly] {
    cursor: pointer;
  }

  .disabled-overlay {
    position: absolute;
    inset: 0;
    z-index: 10;
  }

  ${props => (props.$invalid ? invalid : null)};
`;

export default StyledDatePicker;
