import { fitBounds } from 'google-map-react';

import { NUMBER_OF_CLOSEST_VISIBLE } from '../../../config/locations';

const boundHelper = items => {
  const gmapEl = document.getElementById('gmap');

  if (!gmapEl) {
    return {};
  }
  
  let bounds = {
    nw: {
      lat: null,
      lng: null
    },
    se: {
      lat: null,
      lng: null
    }
  };

  for (let i = 0; i < items.length; i++) {
    const _lat = items[i].lat;
    const _lng = items[i].lng;

    if (i === 0) {
      bounds.nw = { lat: _lat, lng: _lng };
      bounds.se = { lat: _lat, lng: _lng };

    } else {
      bounds.nw.lat = _lat > bounds.nw.lat ? _lat : bounds.nw.lat;
      bounds.nw.lng = _lng < bounds.nw.lng ? _lng : bounds.nw.lng;

      bounds.se.lat = _lat < bounds.se.lat ? _lat : bounds.se.lat;
      bounds.se.lng = _lng > bounds.se.lng ? _lng : bounds.se.lng;
    }
  }

  const size = {
    width: gmapEl.offsetWidth, // Map width in pixels
    height: gmapEl.offsetHeight // Map height in pixels
  };

  return fitBounds(bounds, size);
};

const prepareClosestMarkers = async (locations, centerLocation, n) => {
  // eslint-disable-next-line no-undef
  if (!google) {
      return;
  }
  
  // eslint-disable-next-line no-undef
  const geometry = await google.maps.importLibrary('geometry');
  const markers_distances = [];

  for (let i = 0; i < locations.length; i++) {
    const { lat, lng } = locations[i];
    let distance = 999999;

    if (lat && lng) {
      distance = geometry.spherical.computeDistanceBetween(
        { lat, lng },
        centerLocation
      );
    }

    markers_distances[i] = {
      distance,
      marker: locations[i]
    };
  }

  const closestMarkers = markers_distances
    .sort((a, b) => a.distance - b.distance)
    .slice(0, n);

  const mirrorMarkers = closestMarkers.map((marker) => ({
    marker: {
      lat: Math.round((centerLocation.lat + centerLocation.lat - marker.marker.lat) * Math.pow(10, 8)) / Math.pow(10, 8),
      lng: Math.round((centerLocation.lng + centerLocation.lng - marker.marker.lng) * Math.pow(10, 8)) / Math.pow(10, 8),
    }
  }));

  return closestMarkers
    .concat(mirrorMarkers)
    .map(item => item.marker);
};

export const setZoomFitClosest = async (locations, centerLocation) => {
  const markers = await prepareClosestMarkers(
    locations,
    centerLocation,
    NUMBER_OF_CLOSEST_VISIBLE
  );  

  return boundHelper([...markers, centerLocation]);
};