import styled from 'styled-components';

const StyledConfirmDialog = styled.div`
  padding: 24px;

  .buttons {
    text-align: right;
    
    button:last-child {
      margin-left: 10px;
    }
  }

  .confirm {
    h3:first-child {
      font-size: 24px;
    }
  }

  ${props => props.theme.media.sm`

  `};
`;

export default StyledConfirmDialog;
