import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';

import InputFieldsWrapper from '../../../../common/Form/InputFieldsWrapper/InputFieldsWrapper.styled';
import { formatMobilePhone } from '../../../../../../shared/components/common/Form/Form.formatting';
import InputField from '../../../../common/Form/InputField/InputField.container';
import Alert from '../../../../common/Form/Notifications/Alert.styled';
import {
  required,
  minLength2,
  zip,
  email,
  phone
} from '../../../../../config/validations';

const ProfileForm = ({ intl, allFieldsDisabled, profile, values }) => {
  const verifyPhrase = intl.formatMessage({
    id: 'verifisering.details.verified'
  });
  const verifyWithVippsPhrase = `${intl.formatMessage({
    id: 'verifisering.details.verifiedWithVipps'
  })} Vipps`;

  const verifyHelper = profile.vipsVerified
    ? { showSuccessIcon: verifyWithVippsPhrase }
    : profile.credentialsVerified
    ? { showSuccessIcon: verifyPhrase }
    : '';

  const { email: profileEmail, mobilePhone: profileMobilePhone } = profile;
  const { email: formEmail, mobilePhone: formMobilePhone } = values;

  const emailIdentical = profileEmail === formEmail;
  const mobilePhoneIdentical = profileMobilePhone === formMobilePhone;
  const emailVerifyHelper =
    profile.emailVerified && emailIdentical
      ? { showSuccessIcon: verifyPhrase }
      : '';
  const mobileVerifyHelper =
    profile.mobileVerified && mobilePhoneIdentical
      ? { showSuccessIcon: verifyPhrase }
      : '';

  // TODO find out if this call is needed here
  // Tooltip.rebuild();

  return (
    <React.Fragment>
      {profile.isBanned ? (
        <Alert>
          <FormattedMessage
            id="profile.details.user_was_blocked"
            values={{
              date: profile.bannedUntilDateFormatted
            }}
          />
          <div></div>
        </Alert>
      ) : null}
      <InputFieldsWrapper>
        <Field
          component={InputField}
          name="firstName"
          type="text"
          label={intl.formatMessage({ id: 'forms.firstName' })}
          required
          validate={[required, minLength2]}
          autoComplete="given-name"
          split
          splitLeft
          disabled={profile.isPersonVerified || allFieldsDisabled}
          {...verifyHelper}
        />

        <Field
          component={InputField}
          name="lastName"
          type="text"
          label={intl.formatMessage({ id: 'forms.lastName' })}
          required
          validate={[required, minLength2]}
          autoComplete="family-name"
          split
          splitRight
          disabled={profile.isPersonVerified || allFieldsDisabled}
          {...verifyHelper}
        />

        <Field
          component={InputField}
          name="birthday"
          type="text"
          label={intl.formatMessage({ id: 'forms.birthday' })}
          isBelow
          disabled={true}
          {...verifyHelper}
        />

        {(profile.guardianName ||
          (profile.parent && profile.parent.attributes)) && (
          <span>
            <Field
              component={InputField}
              name="guardianName"
              type="text"
              label={intl.formatMessage({ id: 'forms.guardianName' })}
              required
              isBelow
              disabled={true}
            />
            <Field
              component={InputField}
              name="guardianPhone"
              type="tel"
              label={intl.formatMessage({ id: 'forms.guardianPhone' })}
              required
              format={formatMobilePhone}
              parse={value => value && value.replace(/\s/g, '').slice(0, 8)}
              isBelow
              disabled={true}
            />
            <Field
              component={InputField}
              name="guardianEmail"
              type="email"
              label={intl.formatMessage({ id: 'forms.guardianEmail' })}
              required
              isBelow
              disabled={true}
            />
          </span>
        )}

        <Field
          component={InputField}
          name="email"
          type="email"
          label={intl.formatMessage({ id: 'forms.email' })}
          required
          validate={[required, email]}
          autoComplete="email"
          isBelow
          disabled={allFieldsDisabled}
          {...emailVerifyHelper}
        />

        <Field
          component={InputField}
          name="address"
          type="text"
          label={intl.formatMessage({ id: 'forms.address' })}
          required
          validate={[required, minLength2]}
          autoComplete="street-address"
          isBelow
          disabled={allFieldsDisabled}
        />

        <Field
          component={InputField}
          name="zipcode"
          type="number"
          label={intl.formatMessage({ id: 'forms.zipCode' })}
          required
          validate={[required, zip]}
          autoComplete="postal-code"
          split
          splitOneThird
          splitLeft
          isBelow
          disabled={allFieldsDisabled}
          removeNativeUi
        />

        <Field
          component={InputField}
          name="city"
          type="text"
          label={intl.formatMessage({ id: 'forms.city' })}
          autoComplete="administrative-area"
          required
          validate={[required]}
          split
          splitRight
          splitTwoThirds
          isBelow
          disabled={allFieldsDisabled}
        />

        <Field
          component={InputField}
          name="mobilePhone"
          type="tel"
          label={intl.formatMessage({ id: 'forms.mobilePhone' })}
          required
          validate={[required, phone]}
          autoComplete="tel"
          format={formatMobilePhone}
          parse={value => value && value.replace(/\s/g, '').slice(0, 8)}
          isBelow
          disabled={allFieldsDisabled}
          {...mobileVerifyHelper}
        />

        <Field
          component={InputField}
          name="organization"
          type="text"
          label={intl.formatMessage({ id: 'forms.organization' })}
          autoComplete="organization"
          isBelow
          disabled={allFieldsDisabled}
        />

        <Field
          component={InputField}
          type="textarea"
          name="userNote"
          label={intl.formatMessage({ id: 'forms.note' })}
          autoComplete="userNote"
          isBelow
          disabled={allFieldsDisabled}
        />
      </InputFieldsWrapper>
      <Tooltip />
    </React.Fragment>
  );
};

ProfileForm.propTypes = {
  intl: PropTypes.object,
  formValues: PropTypes.object,
  allFieldsDisabled: PropTypes.bool,
  profile: PropTypes.object,
  values: PropTypes.object
};

export default injectIntl(ProfileForm);
