import React, { useCallback, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import CloseBig from '../../../assets/icons/close-big';

import StyledModal from './Modal.styled';

const ModalComponent = ({ open, mobileTitle, clickClose, children, ...restProps }) => {
  const theme = useTheme();
  const contentRef = useRef(null);
  const lastActiveElementRef = useRef(null);

  const closeByEscape = useCallback(event => {
    if (event.key === 'Escape') {
      clickClose();
    }
  }, [ clickClose ]);

  const onOpen = useCallback(() => {
    document.addEventListener('keydown', closeByEscape);

    lastActiveElementRef.current = document.activeElement;

    if (contentRef.current) {
      contentRef.current.focus();
    }
  }, [ closeByEscape ]);

  const onClose = useCallback(() => {
    document.removeEventListener('keydown', closeByEscape);

    if (lastActiveElementRef.current) {
      lastActiveElementRef.current.focus();
    }
  }, [ closeByEscape ]);

  useEffect(() => {
    open ? onOpen() : onClose();
  }, [ open, onOpen, onClose ]);

  if (!open) {
    return null;
  }

  return (
    <StyledModal $open={ open } {...restProps}>
      <div className="modal__background" onClick={clickClose} />

      <div className="modal__content" tabIndex={0} ref={contentRef}>
        <div className="modal__title-holder">
          <div className="modal__title">{mobileTitle}</div>
          
          <div className="modal__close" onClick={clickClose}>
            <CloseBig fill={theme.colorWhite} />
          </div>
        </div>

        {children}

        <div
          tabIndex={0}
          onFocus={() => contentRef.current.focus()}
        ></div>
      </div>
    </StyledModal>
  );
};

ModalComponent.propTypes = {
  open: PropTypes.bool,
  mobileTitle: PropTypes.string,
  clickClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ])
};

export default ModalComponent;
