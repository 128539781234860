import { Grid } from '../../libs/grid-styled';
import styled, { css } from 'styled-components';

const StyledCol = styled(Grid)`
  ${props =>
    props.theme.gutter
      ? css`
          padding-left: ${props.theme.gutter}px;
          padding-right: ${props.theme.gutter}px;
        `
      : css`
          padding-left: 8px;
          padding-right: 8px;

          ${props.theme.media.sm`
      padding-left: 12px;
      padding-right: 12px;
    `};

          ${props.theme.media.md`
      padding-left: 16px;
      padding-right: 16px;
    `};
  `}
`;

StyledCol.defaultProps = {
  w: 1
};

export default StyledCol;
