import { createGlobalStyle } from 'styled-components';

const StickyFooterGlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
  }

  .content-wrapper {
    height: 100%;
    min-height: 100%;
  }

  .main-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > footer {
      margin-top: auto;
    }
  }
`;

export default StickyFooterGlobalStyles;