import { createSelector } from 'reselect';
import moment from 'moment';

const formDataForTerms = formData => (formData ? formData : {});
const formDataForMerge = formData => (formData ? formData : {});

export const setCoborrowerTermsToTrue = createSelector(
  formDataForTerms,
  formDataForTerms => {
    let _formData = JSON.parse(JSON.stringify(formDataForTerms));

    if (_formData.userLinks) {
      _formData.userLinks.forEach(item => {
        item['tosVerified'] = true;
        item['birthday'] = moment(item['birthday'], 'DD.MM.YYYY').format(
          'YYYY-MM-DD'
        );
      });
    }

    return _formData;
  }
);

export const mergeCoborrowers = createSelector(
  formDataForMerge,
  formDataForMerge => {
    let _formData = JSON.parse(JSON.stringify(formDataForMerge));

    if (!_formData.userLinks) _formData.userLinks = [];

    _formData.children.forEach(item => {
      _formData.userLinks.push({
        identifier: item.id,
        firstName: item.attributes.firstName,
        lastName: item.attributes.lastName,
        email: item.attributes.email,
        mobilePhone: item.attributes.mobilePhone,
        tosVerified: item.attributes.tosVerified
      });
    });

    return _formData;
  }
);
