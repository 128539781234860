export const home = {
  key: 'home',
  index: true,
  transparentTop: true,
  reducer: null
};

export const locations = {
  key: 'locations',
  hiddenFooter: true,
  reducer: null
};

export const equipment = {
  key: 'equipment',
  reducer: () => import('../../shared/components/Equipment/Equipment.reducers')
};

export const pages = {
  key: 'pages',
  transparentTop: true,
  reducer: null
};

export const checkout = {
  key: 'checkout',
  reducer: null
};

export const receipt = {
  key: 'receipt',
  reducer: null
};

export const blogCategory = {
  key: 'blogCategory',
  reducer: null
};

export const blogCategoryPage = {
  key: 'blogCategoryPage',
  reducer: null
};

export const blogPage = {
  key: 'blogPage',
  reducer: null
};

export const blog = {
  key: 'blog',
  end: true,
  reducer: null
};

export const blogPost = {
  key: 'blogPost',
  inMenu: false,
  reducer: null
};

export const article = {
  key: 'article',
  reducer: null
};

export const profile = {
  key: 'profile',
  inMenu: true,
  mobileExclusive: true,
  requireLogin: true,
  reducer: () => import('../components/common/Subscribe/Subscribe.reducers')
};

export const register = {
  key: 'register',
  reducer: () => import('../components/common/Subscribe/Subscribe.reducers')
};

export const registerGuest = {
  key: 'registerGuest',
  reducer: () => import('../components/common/Subscribe/Subscribe.reducers')
};

export const login = {
  key: 'login',
  inMenu: true,
  mobileExclusive: true,
  requireLogout: true,
  reducer: null
};

export const resetPassword = {
  key: 'resetPassword',
  reducer: () => import('../components/pages/User/ResetPassword/ResetPassword.reducers')
};

export const archive = {
  key: 'archive',
  inMenu: true,
  mobileExclusive: true,
  requireLogin: true,
  reducer: () => import('../components/pages/User/Archive/Archive.reducers')
};

export const verifisering = {
  key: 'verifisering',
  reducer: () => import('../components/common/Vipps/Vipps.reducers')
};

const routes = [
  home,
  locations,
  equipment,
  receipt,
  checkout,
  article,
  blogCategoryPage,
  blogPage,
  blogCategory,
  blog,
  blogPost,
  pages,
  register,
  registerGuest,
  profile,
  login,
  resetPassword,
  archive,
  verifisering,
];

export default routes;