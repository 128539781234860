import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';

const Remove = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
    <path d="M7.6 8.15c-.2 0-.3-.1-.4-.2l-3.1-3-3.1 3c-.2.2-.6.2-.9 0-.1-.1-.1-.2-.1-.4s.1-.3.2-.4l3.1-3.1L.2.95C0 .85 0 .75 0 .55s.1-.3.2-.4c.2-.2.6-.2.9 0l3.1 3.1L7.3.15c.2-.2.6-.2.9 0v.4c0 .2 0 .3-.2.4l-3 3.1 3.1 3.1c.1.1.1.2.1.4s0 .3-.2.4c-.2.1-.2.2-.4.2z" />
  </svg>
);

Remove.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withSvg(Remove);
