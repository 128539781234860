export const google_maps_config = {
  key: 'AIzaSyATxhzr4o9OATBnpR8DvLuf-9l46V5jvH8',
  language: 'no',
  libraries: 'places'
};

export const google_maps_defaults = {
  fullscreenControl: false
};

export const google_maps_inital_bounds = {
  ne: {
    lat: 71.337978,
    lng: 31.698903
  },
  sw: {
    lat: 57.912763,
    lng: 4.432876
  }
};

export const google_maps_cluster_settings = {
  minZoom: 2, // min zoom to generate clusters on
  maxZoom: 10, // max zoom level to cluster the points on
  clusterRadius: 25 // cluster radius in pixels
};

// You can provide a single one (string), or an array of up to 5 country code strings.
export const google_places_country_restrictions = ['no'];

export const legends = [
  {
    type: 'reservationEnabled',
    color: 'red'
  },
  {
    type: 'reservationDisabled',
    color: 'purple'
  },
  {
    type: 'mapEntry',
    color: 'green'
  }
];

export const locationTypesColorHelper = (type, reservationEnabled, theme) => {
  return type === 'mapEntry'
    ? theme.colorGreen
    : reservationEnabled === 'enabled'
      ? theme.colorRed
      : theme.colorPurple;
};

export const map_settings = {
  map_zoom_on_search_changed: 13
};

// if LOCATION_IN_CENTER = false, we got shift in centering to fit all places
// otherwise we may see less location visible, but centered as usual
export const LOCATION_IN_CENTER = true;
export const NUMBER_OF_CLOSEST_VISIBLE = 4;