import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';
import withBounds from '../../../shared/hoc/withBounds';

const Check = ({ fill, theme }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 8 9"
  >
    <path
      fill={fill ? fill : theme.colorGrayDarker}
      d="M3.225 8.6c-.2 0-.4-.1-.5-.3l-2.5-2.5c-.3-.3-.3-.7 0-1 .3-.3.8-.3 1.1 0l1.8 1.8 3.5-6.2c.2-.4.7-.5 1-.3.4.2.5.7.3 1l-4 7c-.2.3-.4.4-.7.5.1 0 0 0 0 0z"
    />
  </svg>
);

Check.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withBounds(withSvg(Check));
