import track from './ga.tracker';

import { TOGGLE_MODAL } from '../../components/pages/User/Archive/Archive.actions';

const archiveTracking = {
  [TOGGLE_MODAL]: ({ action }) => {
    if (action.data.dataType) track.modal(action.data.dataType);
  }
};

export default archiveTracking;
