import styled from 'styled-components';

const StyledButtonLink = styled.div`
  font-size: 13px;
  color: ${props => props.theme.colorGrayDark};
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;

  ${props => props.$mt && 'margin-top: ' + props.$mt + ';'}
  ${props => props.$mb && 'margin-bottom: ' + props.$mb + ';'}

  &:hover {
    opacity: 0.8;
  }
`;

export default StyledButtonLink;
