import { connect } from 'react-redux';
import moment from 'moment';

import AddCoBorrowerComponent from './AddCoBorrower.component';

const mapStateToProps = () => {
  return {
    currentDate: moment().format('YYYY-MM-DD')
  };
};

export default connect(
  mapStateToProps,
  null
)(AddCoBorrowerComponent);
