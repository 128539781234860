import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';

const DropDown = ({ fill, theme }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 5"
    height="8"
    width="8"
  >
    <path
      fill={fill ? fill : theme.colorWhite}
      d="M3.55 4.29c-.2 0-.3-.1-.4-.2l-3-3c-.2-.2-.2-.6 0-.9.2-.3.6-.2.9 0l2.5 2.6 2.6-2.6c.2-.2.6-.2.9 0 .2.2.2.6 0 .9l-3 3c-.2.2-.3.2-.5.2z"
    />
  </svg>
);

DropDown.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withSvg(DropDown);
