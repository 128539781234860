import styled from 'styled-components';

const StyledModal = styled.div`
  display: ${props => (props.$open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  .modal {
    &__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${props => props.theme.colorGrayDarker};
      opacity: 0.4;
    }

    &__content {
      position: relative;
      background: ${props => props.theme.colorWhite};
      width: 100%;
      max-width: 480px;
      max-height: 100vh;
      height: 100%;
      overflow: hidden;
      outline: 0;
    }

    &__title-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background: ${props => props.theme.colorGrayDarker};
      height: ${props => props.theme.modalTitleMobileHeight}px;
      color: ${props => props.theme.colorWhite};
    }

    &__title {
      width: 100%;
      text-align: center;
      pointer-events: none;
      font-family: '${props => props.theme.fonts.medium}';
      font-size: 15px;
    }

    &__close {
      position: absolute;
      right: 0;
      top: 0;
      line-height: 14px;
      padding: 18px 16px;

      svg {
        width: 14px;
        height: 14px;
        vertical-align: middle;
      }
    }
  }

  ${props => props.theme.media.sm`

    .modal {
      &__content {
        border-radius: 3px;
        height: auto;
        overflow-y: auto;
      }

      &__title-holder {
        display: none;
      }
    }
  `};
`;

export default StyledModal;
