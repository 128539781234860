import gql from 'graphql-tag';

export const FETCH_FOOTER = gql`
  query FooterEntryQuery {
    entry(section: "footer") {
      id
      ... on footer_footer_Entry {
        footerText
        footerLogo {
          url
            @transform(
              width: 59
              height: 24
              quality: 65
              immediately: true
              format: "webp"
            )
          ... on uploads_Asset {
            altText
          }
        }
        footerLinks {
          ... on footerLinks_linkSection_BlockType {
            sectionTitle
            sectionLinks {
              ... on sectionLinks_BlockType {
                linkText
                linkUrl
              }
            }
          }
        }
      }
    }
  }
`;
