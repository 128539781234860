import styled from 'styled-components';

const StyledEquipmentList = styled.div`
  margin: 40px 0;

  ${props =>
    props.$isCheckout &&
    `
    margin: 24px 0;

    ${props => props.theme.media.sm`
      margin-bottom: 0;
    `};
  `};

  ${props =>
    props.$isBasket &&
    `
    width: 100%;
    margin: 0;
    padding: 0;

    > div {
      padding: 0;
      > div {
        margin: 0;
        > div {
          margin: 0;
          > div {
            width: 100%;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  `};

  hr {
    margin: 16px 0;
    border: 0;
    border-bottom: 1px solid ${props => props.theme.colorGrayLight};
  }

  .items-header {
    display: none;
  }

  ${props => props.theme.media.sm`
    .items-header {
      display: flex;
      justify-content: space-between;
      color: ${props.theme.colorGrayDarker};
      font-family: ${props.theme.fonts.medium};
      font-size: 14px;

      &__product {
        padding-left: 104px;
      }
      &__quantity {
        min-width: 60px;
      }
    }
  `};
`;

export default StyledEquipmentList;
