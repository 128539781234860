import track from './ga.tracker';

import { TOGGLE_HEADER_MENU } from '../../components/common/Header/Header.actions';

const headerTracking = {
  [TOGGLE_HEADER_MENU]: ({ store }) => {
    const {
      header: { open }
    } = store.getState();
    if (!open) track.modal('headermenu');
  }
};

export default headerTracking;
