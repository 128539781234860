import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import StyledDetailsAbout from './DetailsAbout.styled';

const DetailsAbout = ({ shortDescription }) => {
  if (!shortDescription) return null;

  return (
    <StyledDetailsAbout>
      <h3>
        <FormattedMessage id="locations.details.about" />
      </h3>

      {shortDescription ? (
        <div dangerouslySetInnerHTML={{ __html: shortDescription }} />
      ) : null}
    </StyledDetailsAbout>
  );
};

DetailsAbout.propTypes = {
  shortDescription: PropTypes.string
};

export default DetailsAbout;
