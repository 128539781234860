import styled from 'styled-components';

const StyledHeaderLocation = styled.button`
  background: transparent;
  border: 1px ${props => props.theme.colorGrayLight};
  border-style: solid none solid;
  appearance: none;
  padding: 16px;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  ${props => props.theme.media.sm`
    padding-left: 20px;
    padding-right: 20px;
  `};

  ${props => props.theme.media.lg`
    border-style: none none none solid;
  `};

  &:last-child {
    ${props => props.theme.media.lg`
      border-style: none solid;
    `};
  }

  svg {
    width: 12px;
    height: 15px;
    margin-right: 12px;
    vertical-align: bottom;

    path {
      fill: ${props => props.theme.colorGrayDark};
    }
  }
`;

export default StyledHeaderLocation;
