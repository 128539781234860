import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { formName } from '../Profile.component';

import ProfileForm from './ProfileForm.component';

const mapStateToProps = state => {
  const {
    user: { profile }
  } = state;
  const selector = formValueSelector(formName);

  return {
    profile: {
      ...profile,
      isBanned:
        profile.bannedUntilDate &&
        moment().isSameOrBefore(profile.bannedUntilDate, 'day'),
      bannedUntilDateFormatted: moment(profile.bannedUntilDate).format(
        'DD/MM/YYYY'
      )
    },
    values: {
      email: selector(state, 'email'),
      mobilePhone: selector(state, 'mobilePhone')
    }
  };
};

export default connect(
  mapStateToProps,
  null
)(ProfileForm);
