import { initialize, reset } from 'redux-form';
import { push } from '@lagunovsky/redux-react-router';
import { toast } from 'react-toastify';
import moment from 'moment';

import { createLogicWithApi, createLogic } from '../../../../shared/logicCreators';
import { vippsVerifySendCode } from '../../common/Vipps/Vipps.actions';
import { user as apiConfig } from '../../../config/api';
import {
  setLoginToken,
  setLoginInfo
} from '../../../../shared/components/Login/Login.actions';

import { REGISTER_GUEST_FORM_NAME } from './RegisterGuest/RegisterGuest.component';
import { formName as profileFormName } from './Profile/Profile.component';
import { formatDateForInput } from './Profile/Profile.selectors';
import {
  SAVE_USER,
  SAVE_USER_WITH_VERIFY,
  SAVE_USER_WITH_SEND_CODE,
  CANCEL_SAVE_USER,
  FORGOT_PASSWORD,
  CANCEL_FORGOT_PASSWORD,
  REMOVE_USERLINK,
  CANCEL_REMOVE_USERLINK,
  DELETE_USER,
  CANCEL_DELETE_USER,
  SAVE_USER_SUCCESS,
  REMOVE_USERLINK_SUCCESS,
  saveUserSuccess,
  saveUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  removeUserLinkSuccess,
  removeUserLinkError,
  deleteUserSuccess,
  deleteUserError,
  SAVE_GUEST_USER,
  CANCEL_SAVE_GUEST_USER,
  saveGuestUserSuccess,
  saveGuestUserError
} from './User.actions';

const saveUserLogic = createLogicWithApi({
  type: [SAVE_USER, SAVE_USER_WITH_VERIFY, SAVE_USER_WITH_SEND_CODE],
  cancelType: CANCEL_SAVE_USER,
  process: ({ action, Api, state: { locale } }, dispatch, done) => {
    return Api({
      method: action.payload.id ? 'PATCH' : 'POST',
      path: `${apiConfig.path}`,
      requireLogin: false,
      handle: r => {
        return r.send(action.payload.id
          ? {
              attributes: action.payload,
              type: 'user',
              id: action.payload.id ? action.payload.id : null
          } 
          : {
            attributes: {
              ...action.payload,
              birthday: moment(
                action.payload.birthday,
                'DD.MM.YYYY'
              ).format('YYYY-MM-DD')
            }
          }
        );
      }
    })
      .then(resp => {
        dispatch(saveUserSuccess(resp));

        if (resp.auth) {
          dispatch(setLoginToken(resp.auth));
          dispatch(setLoginInfo(resp, moment()));
        }

        if (!action.skipNotification) {
          if (!action.payload.id) {
            toast.success(locale.register.details.created);

          } else {
            toast.success(locale.profile.details.updated);
          }
        }

        if (action.type === SAVE_USER_WITH_VERIFY) {
          const {
            isPersonVerified,
            mobileVerified,
            emailVerified
          } = resp.data.attributes;

          const redirectToVerify = !isPersonVerified || !mobileVerified || !emailVerified;

          if (redirectToVerify) {
            dispatch(push(locale.verifisering.typeVerify.path));
          }

        } else if (action.type === SAVE_USER_WITH_SEND_CODE) {
          dispatch(vippsVerifySendCode(action.codeType));
        }

        done();
      })
      .catch(err => {
        dispatch(saveUserError(err));
        done();
      });
  }
});

const saveGuestUserLogic = createLogicWithApi({
  type: SAVE_GUEST_USER,
  cancelType: CANCEL_SAVE_GUEST_USER,
  process: ({ action, Api, state: { locale } }, dispatch, done) => {
    return Api({
      method: 'POST',
      path: `${apiConfig.path}`,
      requireLogin: false,
      handle: r => {
        return r.send({
          attributes: {
            ...action.payload,
            guest: true,
            ...(action.payload.birthday && {
              birthday: moment(action.payload.birthday, 'DD.MM.YYYY').format(
                'YYYY-MM-DD'
              )
            })
          }
        });
      }
    })
      .then(resp => {
        dispatch(saveGuestUserSuccess(resp));
        dispatch(reset(REGISTER_GUEST_FORM_NAME));

        if (!action.skipNotification) {
          if (!action.payload.id) {
            toast.success(locale.register.details.created);
          }
        }

        done();
      })
      .catch(err => {
        dispatch(saveGuestUserError(err));
        done();
      });
  }
});

const removeUserLinkLogic = createLogicWithApi({
  type: REMOVE_USERLINK,
  cancelType: CANCEL_REMOVE_USERLINK,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'DELETE',
      path: `${apiConfig.path}${apiConfig.userLinksPath}/${action.userLinkId}`,
      requireLogin: true
    })
      .then(() => {
        dispatch(removeUserLinkSuccess(action.userLinkId));
        done();
      })
      .catch(err => {
        dispatch(removeUserLinkError(err));
        done();
      });
  }
});

const reinitializeProfileFormLogic = createLogic({
  type: [SAVE_USER_SUCCESS, REMOVE_USERLINK_SUCCESS],
  process: ({ getState }, dispatch, done) => {
    const { user: { profile } } = getState();

    dispatch(initialize(profileFormName, formatDateForInput(profile), true));
    done();
  }
});

const deleteUserLogic = createLogicWithApi({
  type: DELETE_USER,
  cancelType: CANCEL_DELETE_USER,
  process: ({ Api }, dispatch, done) => {
    return Api({
      method: 'DELETE',
      path: `${apiConfig.path}`,
      requireLogin: true
    })
      .then(() => {
        dispatch(deleteUserSuccess());
        done();
      })
      .catch(err => {
        dispatch(deleteUserError(err));
        done();
      });
  }
});

const forgotPasswordLogic = createLogicWithApi({
  type: FORGOT_PASSWORD,
  cancelType: CANCEL_FORGOT_PASSWORD,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    const method = action.payload.username && action.payload.hash ? 'PATCH' : 'POST';

    return Api({
      method: method,
      requireLogin: false,
      path: `${apiConfig.path}${apiConfig.resetPasswordPath}`,
      handle: r => {
        return r.send({
          attributes: {
            username: action.payload
          }
        });
      }
    })
      .then(resp => {
        dispatch(forgotPasswordSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(forgotPasswordError(err));
        done();
      });
  }
});

const logics = [
  saveUserLogic,
  saveGuestUserLogic,
  forgotPasswordLogic,
  removeUserLinkLogic,
  reinitializeProfileFormLogic,
  deleteUserLogic
];

export default logics;
