import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../../../../common/Button/Button.styled';

import StyledDetailsGoToLocation from './DetailsGoToLocation.styled';

const DetailsGoToLocation = ({ slug, intl }) => (
  <StyledDetailsGoToLocation>
    <Link to={`${intl
      .formatMessage({ id: 'equipment.route.path' })
      .replace(/:locationSlug/, slug)}`
    }>
      <Button $fullWidth>
        <FormattedMessage id="locations.details.findEquipmentCta" />
      </Button>
    </Link>
  </StyledDetailsGoToLocation>
);

DetailsGoToLocation.propTypes = {
  slug: PropTypes.string,
  intl: PropTypes.object.isRequired
};

export default injectIntl(DetailsGoToLocation);
