import React from 'react';

const routeComponents = {
  home: React.lazy(() => import('../components/pages/Home/Home.component')),
  locations: React.lazy(() => import('../components/pages/Locations/Locations.component')),
  equipment: React.lazy(() => import('../components/pages/Equipment/Equipment.component')),
  pages: React.lazy(() => import('../components/pages/Pages/Pages.component')),
  checkout: React.lazy(() => import('../components/pages/Checkout/Checkout.component')),
  receipt: React.lazy(() => import('../components/pages/Checkout/Receipt/Receipt.container')),
  blogCategory: React.lazy(() => import('../components/pages/Blog/Blog.component')),
  blogCategoryPage: React.lazy(() => import('../components/pages/Blog/Blog.component')),
  blogPage: React.lazy(() => import('../components/pages/Blog/Blog.component')),
  blog: React.lazy(() => import('../components/pages/Blog/Blog.component')),
  blogPost: React.lazy(() => import('../components/pages/BlogPost/BlogPost.component')),
  article: React.lazy(() => import('../components/pages/Article/Article.component')),
  profile: React.lazy(() => import('../components/pages/User/Profile/Profile.component')),
  register: React.lazy(() => import('../components/pages/User/Register/Register.component')),
  registerGuest: React.lazy(() => import('../components/pages/User/RegisterGuest/RegisterGuest.component')),
  login: React.lazy(() => import('../components/common/Login/Login.component')),
  resetPassword: React.lazy(() => import('../components/pages/User/ResetPassword/ResetPassword.component')),
  archive: React.lazy(() => import('../components/pages/User/Archive/Archive.component')),
  verifisering: React.lazy(() => import('../components/pages/Verifisering/Verifisering.component')),
};

export default routeComponents;