import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Container, Row, Col, offset } from '../../../../shared/components/Grid';

import StyledFooter from './Footer.styled';

const Footer = ({ intl, hygene, routeConfig = {}, data }) => {
  if (routeConfig.hiddenFooter || data.loading) {
    return null;
  }

  const footerData = data.entry;
  
  return (
    <StyledFooter>
      <Container $fullContainer>
        <Row className="text-center">
          <Col w={[6 / 6, 6 / 6, 5 / 12]}>
            {!!footerData &&
              !!footerData.footerLogo &&
              !!footerData.footerLogo.length && (
                <img
                  src={footerData.footerLogo[0].url}
                  alt={footerData.footerLogo[0].altText}
                  className="logo"
                />
              )}
            {!!footerData && !!footerData.footerText && (
              <div
                dangerouslySetInnerHTML={{ __html: footerData.footerText }}
              />
            )}
          </Col>
          <Col
            w={[6 / 6, 6 / 6, 6 / 12]}
            ml={offset([0, 0, 1 / 12])}
            className="links"
          >
            <Row>
              {!!footerData &&
                !!footerData.footerLinks &&
                !!footerData.footerLinks.length &&
                footerData.footerLinks.map((linksCol, index) => (
                  <Col key={index} w={[6 / 6, 6 / 6, 6 / 12]}>
                    <div className="footer-title">{linksCol.sectionTitle}</div>
                    <ul>
                      {linksCol.sectionLinks.map(link => (
                        <li key={link.linkUrl}>
                          <a href={link.linkUrl} title={link.linkText}>
                            {link.linkText}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
        <Row className="footer__hygene">
          <Col w={[12 / 12]}>
            <ul>
              {hygene.map(link =>
                link === 'loggin' ? (
                  <li key={link} data-name={link}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={intl.formatMessage(
                        { id: `footer.links.${link}.path` },
                        { admin: window.BUA_FRONTEND_ADMIN_HOST }
                      )}
                    >
                      <FormattedMessage id={`footer.links.${link}.name`} />
                    </a>
                  </li>
                ) : (
                  <li key={link}>
                    <a
                      href={intl.formatMessage({
                        id: `footer.links.${link}.path`
                      })}
                    >
                      <FormattedMessage id={`footer.links.${link}.name`} />
                    </a>
                  </li>
                )
              )}
            </ul>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};

Footer.propTypes = {
  intl: PropTypes.object.isRequired,
  hygene: PropTypes.array,
  routeConfig: PropTypes.object,
  data: PropTypes.object
};

export default injectIntl(Footer);
