import styled from 'styled-components';

const StyledApprovals = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: 40px;

  & > h3 {
    text-align: center;
  }

  form:not(:first-child) {
    margin-top: 8px;
  }

  p {
    font-size: 12px;
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colorGray};
    margin-bottom: 20px;
  }

  .black-link {
    font-family: ${props => props.theme.fonts.medium};
  }

  ${props => props.theme.media.sm`

  `};
`;

export default StyledApprovals;
