import styled from 'styled-components';

const StyledHeaderMenuLink = styled.span`
  margin-right: 40px;
  display: inline-block;

  a {
    font-family: "${props => props.theme.fonts.medium}";
    color: ${props => props.$transparent ? props.theme.colorWhite : props.theme.colorGrayDarker};
    text-decoration: none;
    font-size: 22px;
    line-height: 28px;

    &.active,
    &:hover {
      color: ${props => props.theme.colorPurpleLight};
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${props => props.theme.media.xl`
    margin-right: 64px;
  `}

  ${props => props.$mobileExclusive === 'true' && props.theme.media.lg`
    display: none;
  `};
`;

export default StyledHeaderMenuLink;
