import styled from 'styled-components';

const StyledHeaderMenuLinkMobile = styled.span`
  a {
    position: relative;
    display: inline-block;
    color: ${props => props.theme.colorGrayDarker};
    transition: all 0.1s linear;
    padding: 0;
    margin-bottom: 20px;
    text-decoration: none;
    font-size: 28px;

    &.active:not(.logout-link),
    &:hover {
      color: ${props => props.theme.colorPurple};
    }

    &:not(.logout-link).active::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: -10px;
      right: -10px;
      margin-top: -1px;
      height: 2px;
      background: ${props => props.theme.colorPurple};
    }

    ${props => props.$mobileExclusive === 'true' && props.theme.media.lg`
      display: none;
    `};

    @media only screen and (max-width: 600px) and (orientation: landscape) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
`;

export default StyledHeaderMenuLinkMobile;
