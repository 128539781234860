import styled, { css } from 'styled-components';

const getMaxWidth = (props, width = 'sm') => {
  return props.$width
    ? props.$width
    : props.theme.containerWidth[width]
      ? props.theme.containerWidth[width]
      : 0;
};

const maxWidth = css`
  ${props => props.theme.gutter
    ? css`
        max-width: ${props => getMaxWidth(props) + props.theme.gutter * 2}px;
      `
    : css`
        max-width: ${props => getMaxWidth(props) + 16}px;

        ${props.theme.media.sm`
          max-width: ${props => getMaxWidth(props) + 24}px;
        `};

        ${props.theme.media.md`
          max-width: ${props => getMaxWidth(props, 'md') + 32}px;
        `};
      `}
`;

const StyledContainer = styled.div`
  margin: ${props => (props.$fullContainer ? 0 : 'auto')};

  ${props => !props.$fullContainer 
    && !props.$full 
    && (props.$width || props.theme.containerWidth)
      ? maxWidth
      : null
  }

  ${props =>
    props.theme.gutter
      ? css`
          padding-left: ${props.theme.gutter * 2}px;
          padding-right: ${props.theme.gutter * 2}px;
        `
      : css`
          padding-left: 24px;
          padding-right: 24px;

          ${props.theme.media.md`
            padding-left: 40px;
            padding-right: 40px;
          `};

          ${props.theme.media.lg`
            padding-left: 80px;
            padding-right: 80px;
          `};
        `}
`;

export default StyledContainer;
