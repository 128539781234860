import styled from 'styled-components';

const StyledHeaderBtn = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  float: ${props => props.$right ? 'right' : 'left'};
  width: 40px;
  height: 40px;
  line-height: 20px;
  padding: 10px;
  margin-left: -10px;

  ${props => props.theme.media.sm`
    width: 31px;
    height: 32px;
    line-height: 24px;
    margin-left: -3px;
    padding: 4px 3px;
  `};

  ${props => props.theme.media.lg`
    display: ${props => props.$visible ? 'inline' : 'none'};;
  `};
`;

export default StyledHeaderBtn;
