import styled from 'styled-components';

const StyledButton = styled.ul`
  background-color: ${props => props.theme.colorAlertBg};
  border: solid 1px ${props => props.theme.colorAlertBorder};
  padding: 10px;
  color: ${props => props.theme.colorRed};
  margin-bottom: 20px;
  font-size: 13px;
  border-radius: 4px;
  text-align: left;
  list-style: none;
  margin-top: 0;
`;

export default StyledButton;
