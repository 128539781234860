import { combineReducers } from 'redux';

import { URL_CHANGE } from '../../../../../shared/components/App/App.actions';

import { TOGGLE_SIZEGUIDEBAR, SET_GUIDE_ENTRY } from './SizeGuideBar.actions';

const open = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_SIZEGUIDEBAR:
      return !state;
    case URL_CHANGE:
      return false;
    default:
      return state;
  }
};

// selected
const selected = (state = {}, action) => {
  switch (action.type) {
    case SET_GUIDE_ENTRY:
      return action.entry;
    default:
      return state;
  }
};

const reducer = combineReducers({
  open,
  selected
});

reducer.reducer = 'sizeguidebar';

export default reducer;
