import { combineReducers } from 'redux';

import { URL_CHANGE } from '../../../../shared/components/App/App.actions';
import { EMAIL_TYPE, PHONE_TYPE } from '../../../../shared/config/api';

import {
  VIPPS_GET_DATA_SUCCESS,
  VIPPS_CALLBACK_SUCCESS,
  VIPPS_SEND_CODE,
  VIPPS_SEND_CODE_LOADING,
  VIPPS_SEND_CODE_ERROR,
  VIPPS_SEND_CODE_SUCCESS,
  VIPPS_VERIFY,
  VIPPS_VERIFY_CODE,
  VIPPS_VERIFY_CODE_ERROR,
  VIPPS_VERIFY_CODE_SUCCESS,
  VIPPS_VERIFY_ERROR,
  VIPPS_VERIFY_SUCCESS
} from './Vipps.actions';

const initialCodesState = {
  [EMAIL_TYPE]: {},
  [PHONE_TYPE]: {}
};

const codes = (state = initialCodesState, action) => {
  switch (action.type) {
    case VIPPS_SEND_CODE_LOADING:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: action.payload.value
        }
      };
    case VIPPS_SEND_CODE:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          loading: true,
          codeSend: false,
          verified: null,
          errorMessage: ''
        }
      };
    case VIPPS_SEND_CODE_SUCCESS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          codeSend: true,
          errorMessage: ''
        }
      };
    case VIPPS_SEND_CODE_ERROR: {
      let sendCodeErrorMessage = '';
      if (action.payload && action.payload.err && action.payload.err.response) {
        sendCodeErrorMessage = JSON.parse(action.payload.err.response.text)
          .message;
      }

      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          codeSend: false,
          verified: false,
          errorMessage: sendCodeErrorMessage
        }
      };
    }

    case VIPPS_VERIFY_CODE:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          loading: true,
          errorMessage: ''
        }
      };
    case VIPPS_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          verified: true,
          loading: false,
          errorMessage: ''
        }
      };
    case VIPPS_VERIFY_CODE_ERROR: {
      let verifyCodeErrorMessage = '';
      if (action.payload && action.payload.err && action.payload.err.response) {
        verifyCodeErrorMessage = JSON.parse(action.payload.err.response.text)
          .message;
      }

      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          verified: false,
          loading: false,
          errorMessage: verifyCodeErrorMessage
        }
      };
    }
    case URL_CHANGE:
      return initialCodesState;
    default:
      return state;
  }
};

const verificationInfo = (state = {}, action) => {
  switch (action.type) {
    case VIPPS_VERIFY:
      return {
        ...state,
        loading: true
      };
    case VIPPS_VERIFY_SUCCESS: {
      if (action.payload.redirectUrl) {
        window.location.href = action.payload.redirectUrl;
      }

      return {
        ...state,
        loading: false,
        sentVerificationNotification:
          action.payload.sentVerificationNotification
      };
    }
    case VIPPS_VERIFY_ERROR:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

const data = (state = {}, action) => {
  switch (action.type) {
    case VIPPS_GET_DATA_SUCCESS:
    case VIPPS_CALLBACK_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
};

const reducer = combineReducers({
  codes,
  verificationInfo,
  data
});

reducer.reducer = 'vipps';

export default reducer;
