import { toast, Flip } from 'react-toastify';

const toast_settings = {
  position: toast.POSITION.TOP_CENTER,
  transition: Flip,
  autoClose: 6000,
  newestOnTop: true,
  hideProgressBar: true,
  draggable: false
};

export default toast_settings;
