import Cookies from 'js-cookie';
import moment from 'moment';

import { createLogic, createLogicWithApi } from '../../../../shared/logicCreators';
import { cookies as cookieConfig, heartbeat } from '../../../config/login';
import { login as apiConfig } from '../../../config/api';
import {
  POST_LOGIN,
  CANCEL_POST_LOGIN,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  CHECK_LOGIN_TOKEN,
  SET_LOGIN_INFO,
  FETCH_LOGIN_INFO,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  loginSuccess,
  loginError,
  setLoginToken,
  setLoginInfo,
  noLoginToken,
  checkLoginToken,
  fetchLoginInfo,
  logoutSuccess,
  logoutError,
  refreshToken,
  refreshTokenSuccess
} from '../../../../shared/components/Login/Login.actions';
import {
  DELETE_USER_SUCCESS,
  SAVE_USER_SUCCESS
} from '../../pages/User/User.actions';

const postLoginLogic = createLogicWithApi({
  type: POST_LOGIN,
  cancelType: CANCEL_POST_LOGIN,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'post',
      path: apiConfig.path,
      requireLogin: false,
      jsonApi: false,
      handle: request => {
        return request.send({
          username: action.username,
          password: action.password
        });
      }
    })
      .then(resp => {
        dispatch(loginSuccess(resp, moment()));
        done();
      })
      .catch(err => {
        dispatch(loginError(err));
        done();
      });
  }
});

const checkLoginLogic = createLogic({
  type: CHECK_LOGIN_TOKEN,
  process: (props, dispatch, done) => {
    const token = Cookies.get(cookieConfig.token.name),
      refresh_token = Cookies.get(cookieConfig.refresh_token.name);
    if (token && refresh_token) {
      let parsedToken = JSON.parse(
          window.atob(
            token
              .split('.')[1]
              .replace('-', '+')
              .replace('_', '/')
          )
        ),
        expiredTime = parsedToken.exp * 1000;

      if (expiredTime <= +Date.now()) {
        dispatch(
          refreshToken({
            refreshToken: refresh_token
          })
        );
      } else {
        dispatch(
          setLoginToken({
            token: token,
            refresh_token: refresh_token
          })
        );
        dispatch(fetchLoginInfo());
      }
    } else {
      dispatch(noLoginToken());
    }
    done();
  }
});

const checkTokenLogic = createLogicWithApi({
  type: FETCH_LOGIN_INFO,
  process: ({ Api }, dispatch, done) => {
    return Api({
      method: 'get',
      path: apiConfig.check
    })
      .then(resp => {
        dispatch(setLoginInfo(resp, moment()));
        done();
      })
      .catch(err => {
        dispatch(loginError(err));
        done();
      });
  }
});

const saveLoginLogic = createLogic({
  type: [LOGIN_SUCCESS, REFRESH_TOKEN_SUCCESS, SAVE_USER_SUCCESS],
  process: ({ action }, dispatch, done) => {
    var token, refresh_token;

    if (action.type === SAVE_USER_SUCCESS && action.payload.auth) {
      token = action.payload.auth.token;
      refresh_token = action.payload.auth.refresh_token;
    }

    if (action.type !== SAVE_USER_SUCCESS) {
      token = action.payload.token;
      refresh_token = action.payload.refresh_token;
    }

    if (token && refresh_token) {
      Cookies.set(cookieConfig.token.name, token, {
        expires: cookieConfig.token.expires
      });
      Cookies.set(cookieConfig.refresh_token.name, refresh_token, {
        expires: cookieConfig.refresh_token.expires
      });
      dispatch(checkLoginToken());
    }

    done();
  }
});

let loginCheckTimer;
const handleLoginCheckTimerLogic = createLogic({
  type: [LOGIN_SUCCESS, SET_LOGIN_INFO, LOGOUT_SUCCESS, DELETE_USER_SUCCESS],
  warnTimeout: 0,
  process: ({ action, getState }, dispatch) => {
    if (loginCheckTimer) {
      clearInterval(loginCheckTimer);
    }
    if (action.type !== LOGOUT_SUCCESS && action.type !== DELETE_USER_SUCCESS) {
      loginCheckTimer = setInterval(() => {
        const {
          login: { token, refresh_token, time }
        } = getState();
        if (token) {
          const parsedToken = JSON.parse(
              window.atob(
                token
                  .split('.')[1]
                  .replace('-', '+')
                  .replace('_', '/')
              )
            ),
            expiredTime = parsedToken.exp * 1000;
          if (expiredTime <= +Date.now()) {
            dispatch(
              refreshToken({
                refreshToken: refresh_token
              })
            );
          } else if (time && moment().diff(time) > heartbeat.refetchTime) {
            dispatch(fetchLoginInfo());
          }
        } else if (loginCheckTimer) {
          clearInterval(loginCheckTimer);
        }
      }, heartbeat.intervalTime);
    }
  }
});

const logoutLogic = createLogicWithApi({
  type: LOGOUT,
  process: ({ Api, action }, dispatch, done) => {
    return Api({
      method: 'post',
      path: apiConfig.logout
    })
      .then(() => {
        dispatch(logoutSuccess(action.message));
        window.location.reload();
        done();
      })
      .catch(err => {
        dispatch(logoutError(err));
        done();
      });
  }
});

const logoutSuccessLogic = createLogic({
  type: [LOGOUT_SUCCESS, DELETE_USER_SUCCESS],
  process: (props, dispatch, done) => {
    Cookies.remove(cookieConfig.token.name);
    Cookies.remove(cookieConfig.refresh_token.name);
    done();
  }
});

const refreshTokenLogic = createLogicWithApi({
  type: REFRESH_TOKEN,
  process: ({ Api, action }, dispatch, done) => {
    var { refreshToken } = action.payload;
    return Api({
      method: 'post',
      path: apiConfig.refresh,
      jsonApi: false,
      handle: r => {
        return r.send({
          refresh_token: refreshToken
        });
      }
    })
      .then(({ token, refresh_token }) => {
        dispatch(
          refreshTokenSuccess({
            token,
            refresh_token
          })
        );
        done();
      })
      .catch(() => {
        dispatch(logoutSuccess());
        done();
      });
  }
});

const logics = [
  postLoginLogic,
  checkLoginLogic,
  checkTokenLogic,
  saveLoginLogic,
  handleLoginCheckTimerLogic,
  logoutLogic,
  logoutSuccessLogic,
  refreshTokenLogic
];

export default logics;