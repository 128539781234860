import React from 'react';
import PropTypes from 'prop-types';

import withBounds from '../../hoc/withBounds';
import withSvg from '../../hoc/withSvg';

const Info = ({ theme, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <path
        d="M7.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zm0-4V7.5"
        stroke={fill ? fill : theme.colorBlack}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        fill={fill ? fill : theme.colorBlack}
        cx="7.5"
        cy="4.25"
        r="1.25"
      />
    </g>
  </svg>
);

Info.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withBounds(withSvg(Info));
