import React from 'react';

import InputFieldComponent from './InputField.component';

class InputFieldContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      revealPassword: false
    };

    this.revealPassword = this.revealPassword.bind(this);
  }

  revealPassword() {
    this.setState({
      revealPassword: !this.state.revealPassword
    });
  }

  render() {
    return (
      <InputFieldComponent
        {...this.props}
        revealPassword={this.state.revealPassword}
        toggleRevealPassword={this.revealPassword}
      />
    );
  }
}

export default InputFieldContainer;
