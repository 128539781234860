import { connect } from 'react-redux';

import CoBorrower from './CoBorrower.component';

const mapStateToProps = ({ login, user: { profile } }) => ({
  loggedIn: login.loggedIn,
  profile: profile
});

export default connect(
  mapStateToProps,
  null
)(CoBorrower);
