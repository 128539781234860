import styled from 'styled-components';

const StyledLoginModal = styled.main`
  margin: 40px 32px;

  h2 {
    display: none;
  }

  .form-controls {
    margin-top: 40px;

    p {
      font-size: 13px;
      line-height: 1.38;
      margin: 12px 0;
    }

    div > p {
      text-align: center;
    }

    a {
      color: ${props => props.theme.colorPurple};
    }
  }

  ${props => props.theme.media.sm`
    h2 {
      text-align: center;
      display: block;
    }
  `};
`;

export default StyledLoginModal;
