import toastStyles from 'react-toastify/dist/ReactToastify.min.css';
import styled from 'styled-components';

const StyledToast = styled.div`
  ${toastStyles.toString()};

  .Toastify__toast--success {
    background: #24d8b2;
  }

  .Toastify__toast {
    padding: 12px;
    border-radius: 0 0 6px 6px;
  }

  .Toastify__toast-body {
    font-family: ${props => props.theme.fonts.regular};
    text-align: center;
  }

  .Toastify__toast-container {
    width: 100%;
    margin-left: 0;
    transform: translateX(0);
    left: 0;
  }

  .Toastify__toast-container--top-center {
    top: 0;
    padding: 0;
  }

  ${props => props.theme.media.xs`
    .Toastify__toast-container {
      width: 100%;
      margin-left: 0;
    }
  `};

  ${props => props.theme.media.sm`
    .Toastify__toast-container {
      width: 400px;
      margin-left: -200px;
      left: 50%;
    }
  `};
`;

export default StyledToast;
