import styled from 'styled-components';

const StyledHeaderMenuMobile = styled.nav`
  transition: opacity 0.3s linear, visibility 0.3s linear;
  position: fixed;
  z-index: 99;
  display: flex;
  visibility: ${props => (props.$open ? 'visible' : 'hidden')};
  flex-direction: column;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: ${props => (props.$open ? 1 : 0)};
  background: ${props => props.theme.colorWhite};

  .top {
    width: 100%;
    flex-grow: 0;
    padding-top: ${props => (props.$forseMobile ? 40 : props.theme.headerMobileHeight - 28) / 2}px;
    height: ${props => props.$forseMobile
      ? props.theme.headerMobileHeight
      : props.theme.headerMobileHeight}px;
    display: flex;

    .mobileHeader__container {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }

  ul,
  li {
    padding: 0;
    margin: 0;
  }

  ul {
    list-style: none;
  }

  .bottom {
    width: 100%;
    text-align: center;

    li {
      display: inline-block;
    }
  }

  .links {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  @media only screen and (max-width: 600px) and (orientation: landscape) {
    .links ul {
      overflow-y: scroll;
      width: 100%;
    }
  }
`;

export default StyledHeaderMenuMobile;
