import { push } from '@lagunovsky/redux-react-router';
import Promise from 'pinkie-promise';
import Cookies from 'js-cookie';

import { createLogic, createLogicWithApi } from '../../../../shared/logicCreators';
import { multiReplaceInUrlQuery } from '../../../../shared/libs/react-url-query';
import { cookies as cookieConfig } from '../../../config/basket';
import { basket as apiConfig } from '../../../config/api';
import {
  RESET_BASKET,
  RESET_BASKET_DATES,
  CHECK_BASKET_INFO,
  ADD_BASKET_ITEM,
  CANCEL_ADD_BASKET_ITEM,
  FETCH_BASKET,
  CANCEL_FETCH_BASKET,
  FETCH_BASKET_SUCCESS,
  FETCH_BASKET_ERROR,
  SAVE_BASKET_SUCCESS,
  SAVE_BASKET_ERROR,
  resetBasket,
  setBasketInfo,
  saveBasketSuccess,
  saveBasketError,
  fetchBasket,
  fetchBasketSuccess,
  fetchBasketError,
  addBasketItemSuccess,
  addBasketItemError,
  toggleBasket,
  REMOVE_BASKET_ITEM,
  CANCEL_REMOVE_BASKET_ITEM,
  removeBasketItemSuccess,
  removeBasketItemError,
  CONVERT_BASKET,
  CONVERT_BASKET_SUCCESS,
  CANCEL_CONVERT_BASKET,
  convertBasketSuccess,
  convertBasketError
} from '../Basket/Basket.actions';

const resetBasketLogic = createLogic({
  type: [RESET_BASKET, CONVERT_BASKET_SUCCESS],
  process: (props, dispatch, done) => {
    Cookies.remove(cookieConfig.current);
    done();
  }
});

const resetBasketDatesLogic = createLogic({
  type: RESET_BASKET_DATES,
  process: ({ action }, dispatch, done) => {
    multiReplaceInUrlQuery({
      pickupAt: null,
      returnAt: null
    });
    dispatch(resetBasket(action.payload));
    done();
  }
});

const checkBasketInfoLogic = createLogic({
  type: CHECK_BASKET_INFO,
  process: ({ action, getState }, dispatch, done) => {
    const current = Cookies.get(cookieConfig.current);
    const {
      locations: {
        selected: { id: location }
      },
      basket: { selected: basket }
    } = getState();

    if (action.payload) {
      const { pickupAt, returnAt } = action.payload;
      const id = Cookies.get(`${cookieConfig.prefix}${location}_${pickupAt}_${returnAt}`);

      dispatch(
        setBasketInfo({
          ...action.payload,
          id
        })
      );

      if (id && (!basket || String(basket.id) !== String(id))) {
        dispatch(fetchBasket(id));
      }

    } else if (current && (!basket || String(basket.id) !== String(current))) {
      dispatch(setBasketInfo({ id: current }));
      dispatch(fetchBasket(current));
    }

    done();
  }
});

const fetchBasketLogic = createLogicWithApi({
  type: FETCH_BASKET,
  cancelType: CANCEL_FETCH_BASKET,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'get',
      path: `${apiConfig.path}/${action.id}`,
      requireLogin: false
    })
      .then(resp => {
        dispatch(fetchBasketSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchBasketError(err));
        done();
      });
  }
});

const setBasketCookieLogic = createLogic({
  type: [FETCH_BASKET_SUCCESS, SAVE_BASKET_SUCCESS],
  process: ({ getState, action }, dispatch, done) => {
    const basket = action.payload.data;
    const location = basket.relationships.location.data.id;
    const {
      basket: {
        selected: { pickupAt, returnAt }
      }
    } = getState();

    Cookies.set(
      `${cookieConfig.prefix}${location}_${pickupAt}_${returnAt}`,
      basket.id,
      { expires: cookieConfig.expires }
    );
    Cookies.set(cookieConfig.current, basket.id, {
      expires: cookieConfig.expires
    });
    done();
  }
});

const removeBasketCookieLogic = createLogic({
  type: [FETCH_BASKET_ERROR, SAVE_BASKET_ERROR],
  transform: ({ action, getState }, next) => {
    const {
      locations: {
        selected: { id: location }
      },
      basket: { selected: basket }
    } = getState();

    Cookies.remove(`${cookieConfig.prefix}${location}_${basket.pickupAt}_${basket.returnAt}`);
    Cookies.remove(cookieConfig.current);

    next(action);
  }
});

const addToBasketLogic = createLogicWithApi({
  type: ADD_BASKET_ITEM,
  cancelType: CANCEL_ADD_BASKET_ITEM,
  latest: true,
  process: ({ action, Api, state: { basket, locations } }, dispatch, done) => {
    return new Promise(resolve => {
      const basketId = getBasketId(basket, locations);

      if (!basketId) {
        return Api({
          method: 'post',
          path: apiConfig.path,
          requireLogin: false,
          handle: r => {
            return r.send({
              attributes: {
                location: locations.selected.id,
                pickupAt: basket.selected.pickupAt,
                returnAt: basket.selected.returnAt
              }
            });
          }
        })
          .then(resp => {
            dispatch(saveBasketSuccess(resp));
            resolve(resp.data.id);
          })
          .catch(err => {
            dispatch(saveBasketError(err));
            done();
          });
      } else {
        resolve(basketId);
      }
    })
      .catch(err => {
        dispatch(saveBasketError(err));
        done();
      })
      .then(basketId => {
        const item = basket.items.find(i => String(i.variant.id) === String(action.payload.variant));
        const method = item ? 'patch' : 'post';
        const path = `${apiConfig.path}/${basketId}${apiConfig.itemsPath}${item ? `/${item.id}` : ''}`;

        return Api({
          method: method,
          path: path,
          requireLogin: false,
          handle: r => {
            return r.send({
              attributes: action.payload
            });
          }
        })
          .then(resp => {
            dispatch(addBasketItemSuccess(resp));

            if (action.showBasket) {
              dispatch(toggleBasket(true));
            }

            done();
          })
          .catch(err => {
            dispatch(addBasketItemError(err));
            done();
          });
      });
  }
});

const removeFromBasketLogic = createLogicWithApi({
  type: REMOVE_BASKET_ITEM,
  cancelType: CANCEL_REMOVE_BASKET_ITEM,
  latest: true,
  process: ({ action, Api, state: { basket, locations } }, dispatch, done) => {
    const basketId = getBasketId(basket, locations);
    return Api({
      method: 'DELETE',
      path: `${apiConfig.path}/${basketId}${apiConfig.itemsPath}/${action.id}`,
      requireLogin: false
    })
      .then(() => {
        dispatch(removeBasketItemSuccess(action.id));
        done();
      })
      .catch(err => {
        dispatch(removeBasketItemError(err));
        done();
      });
  }
});

const convertBasketLogic = createLogicWithApi({
  type: CONVERT_BASKET,
  cancelType: CANCEL_CONVERT_BASKET,
  latest: true,
  process: (
    {
      Api,
      action: { message, pickup },
      state: {
        locale,
        basket,
        locations,
        router: { location }
      }
    },
    dispatch,
    done
  ) => {
    const basketId = getBasketId(basket, locations);
    return Api({
      method: 'post',
      path: `${apiConfig.path}/${basketId}${apiConfig.convertPath}`,
      requireLogin: true,
      handle: r => r.send({ attributes: { message, pickup } })
    })
      .then(() => {
        dispatch(convertBasketSuccess(basket));
        dispatch(push(`${location.pathname}${locale.receipt.route.pathNub}`));
        done();
      })
      .catch(err => {
        dispatch(convertBasketError(err));
        done();
      });
  }
});

const getBasketId = (basket, locations) => {
  const locationSelectedId = locations.selected.id;
  const basketSelectedId =
    basket.selected.location && basket.selected.location.data.id;
  const locationId = basketSelectedId || locationSelectedId;

  return Cookies.get(
    `${cookieConfig.prefix}${locationId}_${basket.selected.pickupAt}_${basket.selected.returnAt}`
  );
};

const logics = [
  resetBasketLogic,
  resetBasketDatesLogic,
  checkBasketInfoLogic,
  fetchBasketLogic,
  setBasketCookieLogic,
  removeBasketCookieLogic,
  addToBasketLogic,
  removeFromBasketLogic,
  convertBasketLogic
];

export default logics;