import styled, { css } from 'styled-components';

const displayTooltip = css`
  visibility: visible;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  transition: 0.3s all ease-out;
  bottom: 24px;
`;

const StyledLocationMarker = styled.div`
  position: absolute;
  cursor: pointer;
  transform: scale(1);

  & > * {
    position: absolute;
    z-index: 1;
    width: ${props => 20 * props.$scale}px;
    height: ${props => 20 * props.$scale}px;
    left: ${props => (20 / 2) * props.$scale * -1}px;
    bottom: ${props => (20 / 2) * props.$scale * -1}px;
  }

  svg {
    opacity: 0.8;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
  }

  &:hover,
  &:focus {
    z-index: 1000;
    
    svg {
      opacity: 1;
      transform: scale(1.2);
    }
  }

  ${props => props.$isSelected 
    ? `
      z-index: 1000;
      svg {
        opacity: 1;
        transform: scale(1.2);
      }
    `
    : ``
  }

  .marker-tooltip {
    visibility: hidden;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    bottom: 14px;
    z-index: 2;
  }

  &:hover .marker-tooltip {
    ${displayTooltip};
  }

  ${props =>
    props.$isSelected
      ? `
    .marker-tooltip {
      ${displayTooltip};
    }
  `
      : ``}
`;

export default StyledLocationMarker;
