import { combineReducers } from 'redux';

import { PATH_CHANGE } from '../../../../../../shared/components/App/App.actions';

import { TOGGLE_EQUIPMENTFILTERS } from './EquipmentFilters.actions';

const open = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_EQUIPMENTFILTERS:
      return typeof action.visible !== 'undefined' ? action.visible : !state;

    case PATH_CHANGE:
      return false;

    default:
      return state;
  }
};

const reducer = combineReducers({
  open
});

reducer.reducer = 'equipmentfilters';

export default reducer;
