import { toast } from 'react-toastify';

import { createLogic } from '../../../../shared/logicCreators';
import {
  SHOW_NOTIFICATION,
  clearNotifications
} from '../../../../shared/components/common/Dialogs/Dialogs.actions';

const showNotificationLogic = createLogic({
  type: SHOW_NOTIFICATION,
  process: ({ action }, dispatch, done) => {
    const error = typeof action.payload === 'string'
      ? action.payload
      : action.payload.response && action.payload.response.body
        ? action.payload.response.body.message
        : '';
    const notification = action.payload.notificationText;

    if (error !== '') {
      toast.error(error);

    } else if (notification) {
      toast.success(notification);
    }

    dispatch(clearNotifications());
    done();
  }
});

const logics = [showNotificationLogic];

export default logics;