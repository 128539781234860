import track from './ga.tracker';

import { URL_CHANGE } from '../../../shared/components/App/App.actions.js';

const pageviewsTracking = {
  '@@router/LOCATION_CHANGE': ({ action }) => {
    if (!track.hasInit) {
      track.page(`${action.payload.pathname}${action.payload.search}`);
      track.hasInit = true;
    }
  },

  [URL_CHANGE]: ({ action }) => {
    const nextPage = `${action.location.pathname}${action.location.search}`;

    if (track.currentPage !== nextPage) {
      if (
        track.currentPage === '/logout' 
        && action.location.pathname === '/login'
      ) {
        return;
      }
      
      track.currentPage = nextPage;
      track.page(nextPage);
    }
  }
};

export default pageviewsTracking;
