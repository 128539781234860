import { fitBounds } from 'google-map-react';
import { createSelector } from 'reselect';
import supercluster from 'points-cluster';
import { getLocations } from '../../../../shared/components/Locations/Locations.selectors';

import {google_maps_cluster_settings, google_maps_inital_bounds } from '../../../config/locations';

const getItems = state => getLocations(state, true);

const getMapProps = state =>
  (state.map ? state.map.mapProps : {});

const getPoints = location => 
  (location.points ? location.points : null);

const getWidth = obj => 
  (obj.width ? obj.width : null);

const getHeight = obj => 
  (obj.height ? obj.height : null);

export const getMapBounds = createSelector(
  [getWidth, getHeight],
  (width, height) => {
    const { center, zoom } = fitBounds(google_maps_inital_bounds, {
      width,
      height
    });

    return {
      center,
      zoom
    };
  }
);

export const getClustersMarkers = createSelector(
  [getItems, getMapProps],
  (items, mapProps) => {
    if (!items.length) {
      return [];
    }

    const cluster = supercluster(
      items.filter(item => item.visibleMap), 
      {
        minZoom: google_maps_cluster_settings.minZoom,
        maxZoom: google_maps_cluster_settings.maxZoom,
        radius: google_maps_cluster_settings.clusterRadius
      });

    return mapProps.bounds
      ? cluster(mapProps).map(({ wx, wy, numPoints, points }) => ({
          type: 'cluster',
          points: points,
          lat: wy,
          lng: wx,
          text: numPoints,
          numPoints,
          id: `${numPoints}_${points[0].id}`
        }))
      : [];
  }
);

export const getClustersMarkersWithoutMapEntries = createSelector(
  [getClustersMarkers],
  items => {
    return items
      .map(item => ({
        ...item,
        numPoints:
          item.numPoints -
          item.points.filter(point => point.type === 'mapEntry').length,
        points: item.points.filter(point => point.type !== 'mapEntry')
      }))
      .filter(item => item.points.length);
  }
);

export const getClusterPointsBounds = createSelector(
  [getPoints],
  points => {
    if (!points.length) return null;

    let bounds = {
      nw: {
        lat: null,
        lng: null
      },
      se: {
        lat: null,
        lng: null
      }
    };

    for (var i = 0; i < points.length; i++) {
      const _lat = points[i].lat;
      const _lng = points[i].lng;

      if (i === 0) {
        bounds.nw = { lat: _lat, lng: _lng };
        bounds.se = { lat: _lat, lng: _lng };
      } else {
        bounds.nw.lat = _lat > bounds.nw.lat ? _lat : bounds.nw.lat;
        bounds.nw.lng = _lng < bounds.nw.lng ? _lng : bounds.nw.lng;

        bounds.se.lat = _lat < bounds.se.lat ? _lat : bounds.se.lat;
        bounds.se.lng = _lng > bounds.se.lng ? _lng : bounds.se.lng;
      }
    }

    const gmapEl = document.getElementById('gmap');
    const size = {
      width: gmapEl.offsetWidth, // Map width in pixels
      height: gmapEl.offsetHeight // Map height in pixels
    };

    return fitBounds(bounds, size);
  }
);
