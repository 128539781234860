import styled from 'styled-components';

const StyledMapSearchWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  padding: 8px;
  display: ${props => (props.$markerLocation.id ? 'none' : 'flex')};

  ${props => props.$isInModal
    ? `
      width: 100%;
      padding: 8px;
    `
    : ''
  }

  ${props => props.theme.media.sm`
    display: flex;
    ${!props.$isInModal ? 'max-width: 410px;' : ''}
  `};

  .map-search-content {
    display: flex;
    width: 100%;
    border-radius: 3px;
    border: ${props => props.theme.colorGrayLight} solid 1px;
    background: ${props => props.theme.colorWhite};

    &.focused {
      outline: 2px solid #0086bb;
      outline: -webkit-focus-ring-color auto 2px;
    }
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: 8px;
    right: 8px;
    height: 48px;
    width: 101px;
    min-width: 101px;

    .controls-divider {
      background-color: rgba(44, 9, 49, 0.12);
      height: 32px;
      width: 1px;
    }

    button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 48px;
      height: 48px;

      &.clear-button svg path {
        fill: ${props => props.theme.colorEggplant};
        opacity: 0.7;
      }
    }
  }
`;

export default StyledMapSearchWrapper;
