import { createSelector } from 'reselect';
import omit from 'lodash/omit';
import moment from 'moment';

const formData = formData => (formData.id ? formData : {});
const profileData = profileData => (profileData.id ? profileData : {});

export const removeLockedKeys = createSelector(
  formData,
  formData => {
    const omitKeys = [
      'birthday',
      'guardianName',
      'guardianPhone',
      'password_initial'
    ];
    return omit(formData, omitKeys);
  }
);

export const formatDateForInput = createSelector(
  profileData,
  profileData => {
    let _profileData = Object.assign({}, profileData);
    if (_profileData.birthday) {
      _profileData.birthday = moment(_profileData.birthday).format(
        'DD.MM.YYYY'
      );
    }

    // do not send every time, only in specific one
    // readonly, unless local verification in admin panel
    if (_profileData.verificationLocation)
      delete _profileData.verificationLocation;

    // use parent data as guardian information, IF there is no guarding but a parent exist
    const { parent } = _profileData;
    if (
      !_profileData.guardianName &&
      (parent && parent.attributes && parent.attributes.firstName)
    ) {
      _profileData.guardianName = `${parent.attributes.firstName} ${parent.attributes.lastName}`;
      _profileData.guardianPhone = `${parent.attributes.mobilePhone}`;
      _profileData.guardianEmail = `${parent.attributes.email}`;
    }

    return _profileData;
  }
);
