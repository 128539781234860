import Promise from 'pinkie-promise';

import { createLogicWithApi } from '../../../../../../shared/logicCreators';
import { reservations as apiConfig } from '../../../../../config/api';
import {
  FETCH_RESERVATIONS,
  CANCEL_FETCH_RESERVATIONS,
  FETCH_RESERVATION,
  CANCEL_FETCH_RESERVATION,
  REMOVE_RESERVATION,
  CANCEL_REMOVE_RESERVATION,
  fetchReservationsSuccess,
  fetchReservationsError,
  removeReservationSuccess,
  removeReservationError,
  fetchReservationSuccess,
  fetchReservationError
} from '../../../../../../shared/components/Reservations/Reservations.actions';

const fetchReservationsLogic = createLogicWithApi({
  type: FETCH_RESERVATIONS,
  cancelType: CANCEL_FETCH_RESERVATIONS,
  latest: true,
  process: ({ Api }, dispatch, done) => {
    return Api({
      method: 'GET',
      path: `${apiConfig.path}`,
      handle: r =>
        r.query({
          limit: 0,
          order: {
            field: 'returnAt',
            dir: 'desc'
          }
        })
    })
      .then(resp => {
        dispatch(fetchReservationsSuccess(resp));
        done();
      })
      .then(err => {
        dispatch(fetchReservationsError(err));
        done();
      });
  }
});

const fetchReservationLogic = createLogicWithApi({
  type: FETCH_RESERVATION,
  cancelType: CANCEL_FETCH_RESERVATION,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    const reservation = Api({
      method: 'GET',
      path: `${apiConfig.path}/${action.id}`
    });

    const items = Api({
      method: 'GET',
      path: `${apiConfig.path}/${action.id}${apiConfig.itemsPath}`,
      params: {
        limit: 0,
        filters: {
          state: 'published'
        }
      }
    });

    return Promise.all([reservation, items])
      .then(resp => {
        let payload = {
          ...resp[0]
        };

        if (payload.data.relationships && payload.data.relationships.items) {
          payload.data.relationships.items = {
            ...resp[1]
          };
        }

        dispatch(fetchReservationSuccess(payload));
        done();
      })
      .catch(err => {
        dispatch(fetchReservationError(err));
        done();
      });
  }
});

const removeReservationLogic = createLogicWithApi({
  type: REMOVE_RESERVATION,
  cancelType: CANCEL_REMOVE_RESERVATION,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'DELETE',
      path: `${apiConfig.path}/${action.id}`
    })
      .then(() => {
        dispatch(removeReservationSuccess({ id: action.id }));
        done();
      })
      .catch(err => {
        dispatch(removeReservationError(err));
        done();
      });
  }
});

const logics = [
  fetchReservationsLogic,
  fetchReservationLogic,
  removeReservationLogic
];

export default logics;
