import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import { hygene } from '../../../config/footer';

import { FETCH_FOOTER } from './Footer.queries';
import Footer from './Footer.component';

const mapStateToProps = ({ route }) => ({
  hygene,
  routeConfig: route.config
});

export default compose(
  connect(mapStateToProps),
  graphql(FETCH_FOOTER)
)(Footer);
