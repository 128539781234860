import React from 'react';
import PropTypes from 'prop-types';

import StyledHeaderBtn from './HeaderBtn.styled';

const HeaderBtn = ({ children, visible, right, ...props }) => (
  <StyledHeaderBtn 
    {...props}
    $visible={visible}
    $right={right} 
  >
    {children}
  </StyledHeaderBtn>
);

HeaderBtn.propTypes = {
  visible: PropTypes.bool.isRequired,
  right: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
};

export default HeaderBtn;
