import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Field, Form } from 'redux-form';
import PropTypes from 'prop-types';

import InputFieldsWrapper from '../../../Form/InputFieldsWrapper/InputFieldsWrapper.styled';
import SubmitErrors from '../../../SubmitErrors/SubmitErrors.component';
import InputField from '../../../Form/InputField/InputField.container';
import { required, email } from '../../../../../config/validations';
import { useStoreState } from '../../../../../../shared/hooks';
import Button from '../../../Button/Button.component';

const LoginFormLoginPartComponent = ({
  redirect,
  loginForm,
  submitLogin,
  showToggleForgot,
  ...formProps
}) => {
  const location = useLocation();
  const intl = useIntl();
  const { login: { error, loading } } = useStoreState();

  return (
    <Form onSubmit={formProps.handleSubmit(submitLogin)}>
      <SubmitErrors errors={error} />

      <InputFieldsWrapper>
        <Field
          component={InputField}
          name="email"
          type="email"
          label={intl.formatMessage({ id: 'forms.email' })}
          autoComplete="email"
          autoFocus={true}
          validate={[required, email]}
          disabled={loading}
        />
        <Field
          component={InputField}
          name="password"
          type="password"
          label={intl.formatMessage({ id: 'forms.password' })}
          autoComplete="current-password"
          validate={[required]}
          disabled={loading}
          isBelow
        />
      </InputFieldsWrapper>

      <div className="form-controls">
        <p>
          <a href="#" onClick={showToggleForgot}>
            <FormattedMessage id="forms.passwordForgotQuestion" />
          </a>
        </p>
        
        <Button
          type="submit"
          fullWidth
          loading={loading}
          shadow={ false }
          disabled={
            loading 
            || !loginForm.values?.email 
            || !loginForm.values?.password
            || (typeof loginForm.syncErrors == 'object' ? true : false) 
          }
        >
          <FormattedMessage id="forms.loginCta" />
        </Button>

        <div>
          <FormattedMessage
            tagName="p"
            id="forms.registerQuestion"
            values={{
              registerQuestionText: (
                <Link
                  to={`${intl.formatMessage({
                    id: 'register.route.path'
                  })}?redirect=${
                    redirect
                      ? encodeURIComponent(redirect)
                      : encodeURIComponent(location.pathname)
                  }`}
                >
                  <FormattedMessage id="forms.registerQuestionText" />
                </Link>
              )
            }}
          />
        </div>
      </div>
    </Form>
  );
};

LoginFormLoginPartComponent.propTypes = {
  redirect: PropTypes.string,
  submitLogin: PropTypes.func,
  loginForm: PropTypes.object,
  showToggleForgot: PropTypes.func,
};

export default LoginFormLoginPartComponent;
