import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';

const iconAsmall = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="23"
    viewBox="0 0 22 23"
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#FFF"
        d="M21.305 20.93L14.126 1.15l-.231-.805H8.453L2.432 17.02l-1.39 3.795-.579 1.61H22l-.695-1.495zm-19.22.345l1.273-3.45h11.81L11.116 6.9l-3.242 9.085H4.053l5.21-14.49h3.821l7.18 19.78H2.083zM8.8 16.79l2.316-6.44 2.316 6.44H8.8z"
      />
      <path
        fill={color}
        d="M20.147 21.275l-7.063-19.78h-3.82l-5.211 14.49h3.82L11.117 6.9l4.052 11.04H3.358l-1.274 3.335z"
      />
    </g>
  </svg>
);

iconAsmall.propTypes = {
  theme: PropTypes.object,
  color: PropTypes.string
};

export default withSvg(iconAsmall);
