import styled from 'styled-components';

const StyledButtonToggle = styled.button`
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  ${props => props.theme.media.sm`
    display: none;

    .hide-button-in-modal {
      display: none;
    }
  `};

  ${props => props.theme.media.xs`
    .hide-button-regular {
      display: none;
    }
  `};

  svg {
    width: 20px;
  }
`;

export default StyledButtonToggle;
