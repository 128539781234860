import styled from 'styled-components';
import { OutboundLink } from 'react-ga';

const HeaderBottomLink = styled(OutboundLink)`
  display: inline-block;
  color: ${props => props.theme.colorGrayDarker};
  padding: 8px 0 24px;
  margin: 0 12px;
  text-decoration: none;
  font-size: 15px;
  position: relative;

  &::after {
    content: '•';
    position: absolute;
    right: -14px;
  }

  li:last-child > &::after {
    display: none;
  }
`;

export default HeaderBottomLink;
