import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';

const EyeOff = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <path d="M17 17.55c-.1 0-.3 0-.4-.1l-3.5-3.5c-1.3.9-2.8 1.4-4.4 1.4-5.5 0-8.6-6-8.7-6.3 0-.2 0-.3.1-.5.8-1.6 2-3.1 3.4-4.2L.1.95C0 .85 0 .65 0 .55c0-.1 0-.3.1-.4.2-.2.5-.2.7 0l3.8 3.8 2.9 2.9 3.2 3.2 2.9 2.9 3.8 3.8c.1.1.1.2.1.4s0 .3-.1.4c-.1.1-.3 0-.4 0zM1.1 8.85c.6 1.1 3.4 5.5 7.7 5.5 1.3 0 2.6-.4 3.7-1.1l-2.1-2.1c-.7.4-1.6.6-2.4.4-1-.2-1.7-1-2-2-.2-.8 0-1.7.5-2.3l-2.2-2.2c-1.3 1-2.4 2.3-3.2 3.8zm6.1-.9c-.2.4-.3.9-.2 1.3.2.7.6 1.1 1.3 1.3.5.1.9 0 1.3-.2l-2.4-2.4zm8.2 3.7c-.1 0-.2 0-.3-.1-.2-.2-.2-.5-.1-.7.5-.6 1-1.3 1.4-2.1-.6-1.1-3.4-5.5-7.7-5.5-.5 0-1 .1-1.5.2-.2.1-.4-.1-.5-.4-.1-.2.1-.5.4-.6.6-.1 1.1-.2 1.7-.2 5.5 0 8.6 6 8.7 6.3.1.1.1.3 0 .5-.5.9-1 1.7-1.7 2.5-.1.1-.3.1-.4.1z" />
  </svg>
);

EyeOff.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withSvg(EyeOff);
