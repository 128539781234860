import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../../shared/hoc/withSvg';

const HamburgerSmall = ({ fill, theme, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 16" {...props}>
    <path
      fill={fill ? fill : theme.colorWhite}
      d="M21 16H1c-.6 0-1-.4-1-1s.4-1 1-1h20c.6 0 1 .4 1 1s-.4 1-1 1zm0-7H1c-.6 0-1-.4-1-1s.4-1 1-1h20c.6 0 1 .4 1 1s-.4 1-1 1zm0-7H1c-.6 0-1-.4-1-1s.4-1 1-1h20c.6 0 1 .4 1 1s-.4 1-1 1z"
    />
  </svg>
);

HamburgerSmall.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withSvg(HamburgerSmall);
