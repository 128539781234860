import { combineReducers } from 'redux';

import { URL_CHANGE } from '../../../../shared/components/App/App.actions';

import {
  TOGGLE_HEADER_MENU,
  TOGGLE_DATE_PICKER,
  TOGGLE_LOCATION_SELECTOR,
  TOGGLE_BASKET,
  TOGGLE_HEADER_VISIBILITY,
  TOGGLE_HEADER_FORCE_MOBILE
} from './Header.actions';

const open = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_HEADER_MENU:
      return typeof action.open === 'undefined' ? !state : !!action.open;

    case URL_CHANGE:
      return false;

    default:
      return state;
  }
};

const visibleDatePicker = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_DATE_PICKER:
      return typeof action.visible === 'undefined' ? !state : !!action.visible;

    default:
      return state;
  }
};

const visibleLocationSelector = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_LOCATION_SELECTOR:
      return typeof action.visible === 'undefined' ? !state : !!action.visible;

    default:
      return state;
  }
};

const visibleBasket = (state = true, action) => {
  switch (action.type) {
    case TOGGLE_BASKET:
      return typeof action.visible === 'undefined' ? !state : !!action.visible;

    default:
      return state;
  }
};

const visibleHeader = (state = true, action) => {
  switch (action.type) {
    case TOGGLE_HEADER_VISIBILITY:
      return typeof action.visible === 'undefined' ? !state : !!action.visible;

    default:
      return state;
  }
};

const forceMobileView = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_HEADER_FORCE_MOBILE:
      return typeof action.visible === 'undefined' ? !state : !!action.visible;
      
    default:
      return state;
  }
};

const reducer = combineReducers({
  open,
  visibleDatePicker,
  visibleLocationSelector,
  visibleBasket,
  visibleHeader,
  forceMobileView
});

export default reducer;
