export const TOGGLE_MODAL = '@@locationSelector/TOGGLE_MODAL';
export const SET_LOCATION = '@@locationSelector/SET_LOCATION';
export const UNSET_LOCATION = '@@locationSelector/UNSET_LOCATION';
export const FETCH_LOCATION_SUCCESS = '@@locationSelector/FETCH_LOCATION_SUCCESS';

export const toggleModal = (options = {}) => ({
  type: TOGGLE_MODAL,
  options
});

export const setMarkerLocation = (data = {}) => ({
  type: SET_LOCATION,
  data
});

export const unsetMarkerLocation = () => ({
  type: UNSET_LOCATION
});

export function fetchLocationSelectorSuccess(resp) {
  return {
    type: FETCH_LOCATION_SUCCESS,
    payload: resp
  };
}
