/* eslint-disable import/no-anonymous-default-export */

export default [
  'width',
  'w',
  'm',
  'mt',
  'mr',
  'mb',
  'ml',
  'mx',
  'my',
  'p',
  'pt',
  'pr',
  'pb',
  'pl',
  'px',
  'py',
  'flex',
  'order',
  'wrap',
  'direction',
  'align',
  'justify',
  'column',
]
