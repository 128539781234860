import styled from 'styled-components';

const StyledBypassLink = styled.a`
  position: absolute;
  left: -100%;
  top: 0;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.2;
  transform: translate(-50%, 0);
  z-index: 99;
  background: #fff;
  color: #2c0931;
  padding: 3px 6px;

  &:focus {
    left: 50%;
  }
`;

export default StyledBypassLink;
