import { combineReducers } from 'redux';

import { LOGIN_SUCCESS } from '../../../../../shared/components/Login/Login.actions';
import { URL_CHANGE } from '../../../../../shared/components/App/App.actions';

import { TOGGLE_LOGIN_MODAL } from './LoginModal.actions';

const open = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_LOGIN_MODAL:
      return !state;
    case URL_CHANGE:
    case LOGIN_SUCCESS:
      return false;
    default:
      return state;
  }
};

const reducer = combineReducers({
  open
});

export default reducer;
