import styled from 'styled-components';

export const StyledAddCoBorrower = styled.div`
  .add-coborrower {
    font-size: 14px;
    padding: 5px 12px;
    outline: none;
    background: none;
    border-radius: 6px;
    height: 36px;
    min-height: 36px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #afa1b1;
    transition: background 200ms linear, color 200ms linear;

    .plus-icon {
      margin-right: 8px;
      color: ${props => props.theme.colorPurple};
      font-family: Tahoma, Arial, sans-serif;
      font-size: 20px;
    }

    &:hover {
      background: ${props => props.theme.colorPurpleLight};
      color: ${props => props.theme.colorWhite};
      border-color: ${props => props.theme.colorPurpleLight};
      cursor: pointer;

      .plus-icon {
        color: ${props => props.theme.colorWhite};
      }
    }
  }

  .remove-button-holder {
    text-align: right;
  }

  .coborrower-fields {
    margin-bottom: 24px;
  }

  ${props => props.theme.media.sm`

  `};
`;

export default StyledAddCoBorrower;
