import React from 'react';

import StyledUserSelectOption from './UserSelect/UserSelectOption.styled';

export const formatMobilePhone = value => {
  if (!value || String(value[0]) === '0') {
    return '';
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3)}`;
  }

  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 5)} ${onlyNums.slice(5)}`;
  }

  return onlyNums;
};

export const formatIntMobilePhone = value => {
  if (!value) {
    return '';
  }

  const validChars = value.replace(/[^\d]/g, '');
  const shiftSpace = validChars.startsWith('00') ? 1 : 0;

  if (validChars.length <= 3 + shiftSpace) {
    return validChars;
  }

  if (validChars.length <= 6 + shiftSpace) {
    return `${validChars.slice(0, 3 + shiftSpace)} ${validChars.slice(
      3 + shiftSpace
    )}`;
  }

  if (validChars.length <= 9 + shiftSpace) {
    return `${validChars.slice(0, 3 + shiftSpace)} ${validChars.slice(
      3 + shiftSpace,
      6 + shiftSpace
    )} ${validChars.slice(6 + shiftSpace)}`;
  }

  return `${validChars.slice(0, 3 + shiftSpace)} ${validChars.slice(
    3 + shiftSpace,
    6 + shiftSpace
  )} ${validChars.slice(6 + shiftSpace, 9 + shiftSpace)} ${validChars.slice(
    9 + shiftSpace
  )}`;
};

export const formattedUserWithMobilePhone = value => {
  if (!value || !value.name) {
    return '';
  }

  return {
    ...value,
    name: `${value.firstName} ${value.lastName} (${
      (value.mobilePhone || '').replace(/(\d{3})(\d{2})(\d{3})/g, '$1 $2 $3')
    })`
  };
};

export const forceInteger = value =>
  !value ? '' : typeof value === 'number' ? value : value.replace(/[^0-9]/, '');

export const formattedUserOption = user =>
  `${user.firstName} ${user.lastName} (${(user.mobilePhone || '').replace(
    /(\d{3})(\d{2})(\d{3})/g,
    '$1 $2 $3'
  )})`;

export const formattedUserAndOrgOption = user => {
  if (!user) {
    return null;
  }

  return (
    <StyledUserSelectOption>
      <div className="user-option-row user-info">
        <span className="user-option-name">{ user.name }</span>
        <span className="user-option-phone">Tel.: { user.mobilePhone }</span>
      </div>

      <div className="user-option-row user-organization">
        { user.relationships?.organization?.data.attributes.name }
      </div>
    </StyledUserSelectOption>
  );
};

export const normalizeBool = val => String(val) === 'true' ? true : String(val) === 'false' ? false : val;

export const normalizeInt = val => (isNaN(parseInt(val)) ? val : parseInt(val));
