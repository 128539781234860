import { combineReducers } from 'redux';
import { remove } from 'lodash';

import {
  SET_LOGIN_INFO,
  CHECK_LOGIN_TOKEN,
  NO_LOGIN_TOKEN,
  LOGOUT
} from '../../../../shared/components/Login/Login.actions';

import {
  SAVE_USER,
  SAVE_USER_WITH_VERIFY,
  SAVE_USER_ERROR,
  SAVE_USER_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  CANCEL_FORGOT_PASSWORD,
  RESET_FORGOT_PASSWORD,
  REMOVE_USERLINK,
  REMOVE_USERLINK_SUCCESS,
  REMOVE_USERLINK_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR
} from './User.actions';

const loading = (state = false, action) => {
  switch (action.type) {
    case CHECK_LOGIN_TOKEN:
    case SAVE_USER:
    case SAVE_USER_WITH_VERIFY:
    case FORGOT_PASSWORD:
    case REMOVE_USERLINK:
    case DELETE_USER:
      return true;
    case SET_LOGIN_INFO:
    case NO_LOGIN_TOKEN:
    case SAVE_USER_ERROR:
    case SAVE_USER_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_ERROR:
    case CANCEL_FORGOT_PASSWORD:
    case REMOVE_USERLINK_SUCCESS:
    case REMOVE_USERLINK_ERROR:
    case DELETE_USER_SUCCESS:
    case DELETE_USER_ERROR:
      return false;
    default:
      return state;
  }
};

const profile = (state = {}, action) => {
  switch (action.type) {
    case SET_LOGIN_INFO:
    case SAVE_USER_SUCCESS: {
      const data = action.payload.data;

      return {
        id: state.id || data.id,
        ...data.attributes,
        children:
          data.relationships && data.relationships.children
            ? data.relationships.children.data
            : [],
        parent:
          data.relationships && data.relationships.parent
            ? data.relationships.parent.data
            : []
      };
    }
    case REMOVE_USERLINK_SUCCESS: {
      const _state = Object.assign({}, state);

      remove(_state.children, i => {
        return String(i.id) === String(action.id);
      });

      return _state;
    }
    case SAVE_USER_ERROR:
      return Object.assign(state, { error: action.payload.response.body });

    case DELETE_USER_ERROR:
      return Object.assign(state, {
        deleteError: action.payload.response.body
      });

    case LOGOUT:
    case RESET_FORGOT_PASSWORD:
      return {};

    case FORGOT_PASSWORD_SUCCESS:
      return {
        passwordreset: true
      };

    case DELETE_USER_SUCCESS:
      return {
        userDeleted: true
      };

    case FORGOT_PASSWORD_ERROR:
      return {
        resetPasswordError: action.payload
      };
      
    default:
      return state;
  }
};

// MAIN
const reducer = combineReducers({
  profile,
  loading
});

reducer.reducer = 'user';

export default reducer;
