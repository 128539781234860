import React from 'react';
import PropTypes from 'prop-types';

import PlaceHolderImage from '../../../../../assets/bua-sidebar-placeholder.svg';
import { url, images as imagesPath } from '../../../../../config/api';

import StyledDetailsImage from './DetailsImage.styled';

const DetailsImage = ({ images, title }) => {
  const image =
    images && images[0]
      ? `${url}${imagesPath.path}/${images[0].attributes.image.id}/${images[0].attributes.image.originalFilename}`
      : `${PlaceHolderImage}`;

  return (
    <StyledDetailsImage>
      <div
        className="location-image"
        style={{ backgroundImage: `url('${image}')` }}
      />
      <div className="gradient-overlay"></div>
      <h3>{title}</h3>
    </StyledDetailsImage>
  );
};

DetailsImage.propTypes = {
  images: PropTypes.array,
  title: PropTypes.string
};

export default DetailsImage;
