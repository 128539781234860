import { combineReducers } from 'redux';

import { ADD_BASKET_ITEM_SUCCESS } from '../../../../common/Basket/Basket.actions';
import { PATH_CHANGE } from '../../../../../../shared/components/App/App.actions';

import { TOGGLE_EQUIPMENTVARIANTSELECTOR } from './EquipmentVariantSelector.actions';

const open = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_EQUIPMENTVARIANTSELECTOR:
      return typeof action.visible !== 'undefined' ? action.visible : !state;
      
    case PATH_CHANGE:
    case ADD_BASKET_ITEM_SUCCESS:
      return false;

    default:
      return state;
  }
};

const reducer = combineReducers({
  open
});

reducer.reducer = 'equipmentvariantselector';

export default reducer;
