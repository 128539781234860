import styled from 'styled-components';

const StyledDetailsGoToLocation = styled.div`
  padding: 16px 24px;

  .missing-info {
    text-align: center;

    button {
      margin-bottom: 16px;
    }
  }
`;

export default StyledDetailsGoToLocation;
